export const actions = {
  async fetchApplicationList() {
    const { data } = await this.$axios.get("/site/application/list/");
    return data;
  },
  async fetchAplicationDetail(_, payload) {
    try {
      let res = await this.$axios.get(`/site/application/detail/${payload}/`);
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async createApplication(_, payload) {
    try {
      const res = await this.$axios.post("/site/application/create/", payload);
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async updateApplication(_, { form, id }) {
    try {
      const res = await this.$axios.patch(
        `/site/application/update/${id}/`,
        form
      );
      return res;
    } catch (e) {
      console.log(e);
    }
  },
};
