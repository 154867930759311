<template >
  <div class="pages-main">
    <div class="pages-title">
      <h3>{{ $t("Notifications") }}</h3>
    </div>
    <ul class="notifications">
      <router-link
        tag="li"
        :to="getRoute(item)"
        class="notifications__item"
        v-for="item in notifications"
        :key="item.id"
        ><div v-html="item.text"></div
      ></router-link>
    </ul>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      notifications: [],
    };
  },
  async created() {
    this.getNotifications();
  },
  methods: {
    ...mapActions("app", ["fetchNotifications"]),
    async getNotifications() {
      this.notifications = await this.fetchNotifications();
    },
    getRoute(val) {
      if (
        val &&
        val.content_object &&
        val.content_object.status == "received_application"
      ) {
        return `/cabinet/staff-reviewer/new-applications/${val.content_object.id}`;
      } else if (
        val &&
        val.content_object &&
        val.content_object.class_name == "FeedbackAnswer"
      ) {
        return `/cabinet/staff-reviewer/feedback/${val.content_object.feedback}`;
      } else {
        return `/cabinet/staff-reviewer/verified-applications/${val.content_object.id}`;
      }
    },
  },
};
</script>
<style >
</style>