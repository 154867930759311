<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t(["Approve"]) }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form-group">
          <label
            >{{ $t("TableMessageText")
            }}<span style="color: red">*</span></label
          >
          <b-form-textarea
            v-model="form.comment"
            id="textarea-rows"
            :placeholder="$t('EnterText')"
            rows="8"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <label for="">{{ $t("AttachDocument") }}</label>
          <fileUploader
            name="reviewer_new_application_send_form"
            @getFiles="getFiles"
          />
        </div>
        <div class="form_footer_custom">
          <b-button @click="rejectForm" class="mybtn mybtn-green">{{
            $t("Approve")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        comment: "",
        attachment: null,
      },
    };
  },
  methods: {
    ...mapActions("reviewerNewApplicationsId", ["action"]),
    getFiles(arg) {
      this.form.attachment = arg && arg.file;
    },
    async rejectForm() {
      try {
        this.isLoading = true;
        let f = new FormData();
        f.append("application", this.$route.params && this.$route.params.id);
        f.append(
          "type",
          this.step == 1
            ? "approved_by_staff_reviewer"
            : "approved_by_staff_reviewer_at_second_step"
        );
        Object.keys(this.form).forEach((key) => {
          key != "attachment" &&
            this.form[key] &&
            f.append(key, this.form[key]);
          key == "attachment" &&
            this.form[key] &&
            this.form[key].name &&
            f.append(
              key,
              this.form[key],
              this.form[key] && this.form[key].name
            );
        });
        let res = await this.action(f);
        this.isLoading = false;
        if (res && res.status == 201) {
          this.$toast.success(this.$t("successText"));
          this.close();
          this.$router.push("/cabinet/staff-reviewer/verified-applications");
        }
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.form_footer_custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>