<template>
  <div class="password">
    <p v-if="hasTitle">{{ $t("Password") }}<span>*</span></p>
    <div class="password-main">
      <input
        ref="password_input"
        v-model="password"
        class="password-input"
        type="password"
      />
      <b-icon
        @click="eyeFunction"
        class="password-eye"
        :icon="type == 'password' ? 'eye' : 'eye-slash'"
      ></b-icon>
    </div>
    <p class="error" v-if="!isEnough">
      {{ $t("PasswordInclude8") }}
    </p>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number, Object, Boolean, Array],
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    hasTitle: {
      type: Boolean,
      default: true,
    },
    checker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: "password",
      password: "",
      isEnough: true,
    };
  },
  watch: {
    password(newVal) {
      let splitedVal = newVal.split("");
      splitedVal[splitedVal.length - 1] == " " &&
        (this.password = splitedVal.slice(0, splitedVal.length - 1).join(""));
      // let simbols = splitedVal.filter(el =>
      //     (el.codePointAt(0) < 48) && (el.codePointAt(0) > 32) ||
      //     (el.codePointAt(0) < 65) && (el.codePointAt(0) > 57) ||
      //     (el.codePointAt(0) < 127) && (el.codePointAt(0) > 122) ||
      //     (el.codePointAt(0) < 97) && (el.codePointAt(0) > 90))

      let numbers = splitedVal.filter(
        (el) => el.codePointAt(0) < 58 && el.codePointAt(0) > 47
      );

      // let upperCases = splitedVal.filter(el => (el.codePointAt(0) < 91) && (el.codePointAt(0) > 64))

      // let lowerCases = splitedVal.filter(el => (el.codePointAt(0) < 123) && (el.codePointAt(0) > 96))
      let cases = splitedVal.filter(
        (el) =>
          (el.codePointAt(0) < 123 && el.codePointAt(0) > 96) ||
          (el.codePointAt(0) < 91 && el.codePointAt(0) > 64)
      );

      this.checker
        ? (this.isEnough =
            numbers.length > 0 && cases.length > 0 && newVal.length > 7)
        : (this.isEnough = true);
      this.isEnough ? this.$emit("change", newVal) : this.$emit("change", "");
    },
  },
  methods: {
    eyeFunction() {
      this.$refs.password_input.type =
        this.$refs.password_input.type == "text" ? "password" : "text";
      this.type = this.$refs.password_input.type;
    },
  },
};
</script>

<style scoped>
</style>