var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"requests"},[_c('transition',{attrs:{"name":"loading-fade"}},[(_vm.isLoading)?_c('appLoader'):_vm._e()],1),(!_vm.loading)?_c('div',{staticClass:"pages-main"},[_c('div',{staticClass:"pages-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("ApplicationForm2")))])]),_c('div',{staticClass:"request-form"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('div',{staticClass:"form-col"},[(
                _vm.form.status == 'first_step_completed' ||
                _vm.form.status == 'received_application_at_second_step'
              )?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("RequestInfoProject"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('div',{staticClass:"form-example"},[_c('fileUploader',{key:"1",attrs:{"name":"project","files":_vm.second_step_information_file},on:{"getFiles":_vm.setProjectFile}}),_c('a',{staticClass:"form-example__item",attrs:{"href":_vm.infoTemplateFile,"download":"download","target":"_blank"}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1","stroke":"#313131","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("RequestDownload")))])])],1),(
                  _vm.$v.form.second_step_information_file.$dirty &&
                  !_vm.$v.form.second_step_information_file.required
                )?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("uploadTheFile")))]):_vm._e()]):_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("modifiedFile"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('div',{staticClass:"form-example"},[_c('fileUploader',{key:"1",attrs:{"name":"project","files":_vm.revisioned_second_step_information_file},on:{"getFiles":_vm.setModifiedFile}}),_c('a',{staticClass:"form-example__item",attrs:{"href":_vm.infoTemplateFile,"download":"download","target":"_blank"}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1","stroke":"#313131","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("RequestDownload")))])])],1),(
                  _vm.$v.form.second_step_information_file.$dirty &&
                  !_vm.$v.form.second_step_information_file.required
                )?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("uploadTheFile")))]):_vm._e()])])]),_c('b-col',{attrs:{"xl":"6"}},[_c('div',{staticClass:"form-col"},[_c('div',{staticClass:"form-group",staticStyle:{"display":"none"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("Other")))]),_c('fileUploader',{key:"2",attrs:{"multiple":"","name":"other","files":_vm.second_step_files,"deleteapi":"/site/application/secondstep/attachment/delete"},on:{"getFiles":_vm.setOtherFiles}})],1)])])],1),_c('div',{staticClass:"form-col"},[(_vm.form.is_editable)?_c('div',{staticClass:"form-btns"},[_c('b-button',{staticClass:"mybtn mybtn-green",on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("Save")))]),(
              _vm.form &&
              _vm.form.status &&
              (_vm.form.status == 'first_step_completed' ||
                _vm.form.status == 'returned_in_consideration_at_second_step')
            )?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrect || _vm.submitStatus == 'PENDING'},on:{"click":_vm.sendRequest}},[_vm._v(_vm._s(_vm.$t("RequestApply")))]):_vm._e()],1):_vm._e()])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }