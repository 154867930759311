<template>
  <div class="reports">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="row">
      <div class="col-xl-9">
        <div class="pages-main">
          <div class="pages-title">
            <h3>{{ $t("ListApplications") }}</h3>
            <div class="pages-title__filter d-flex justify-contend-end">
              <div class="form-group">
                <b-input
                  v-model="reg_number"
                  :placeholder="$t('SearchByID')"
                  class="staff-search"
                ></b-input>
              </div>
              <div class="form-group">
                <b-form-select
                  class="staff-select"
                  v-model="program"
                  :options="programs"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>{{
                      $t("selectProgramm")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <b-button
                class="mybtn mybtn-blue"
                v-if="!isFilter"
                @click="getApplicationListWithFilter"
                >{{ $t("Apply") }}</b-button
              >
              <b-button class="mybtn mybtn-gray" v-else @click="clearFilter">{{
                $t("Clear")
              }}</b-button>
            </div>
          </div>
          <div
            class="send-first-step-completed"
            v-if="
              isDirector &&
              (filterTab == 'approved_in_international_expert' ||
                filterTab ==
                  'approved_in_international_expert_at_second_step') &&
              applicationList &&
              applicationList.length
            "
          >
            <span>{{ $t("Complete") }}:</span>
            <b-button
              @click="getShowCompleted(filterTab)"
              class="mybtn mybtn-green"
            >
              {{
                filterTab == "approved_in_international_expert"
                  ? $t("CompleteStage1")
                  : $t("CompleteStage2")
              }}</b-button
            >
          </div>
          <div class="table">
            <table>
              <thead>
                <tr>
                  <th width="1%">№</th>
                  <th width="1%">ID</th>
                  <th width="100px">{{ $t("TableTitle") }}</th>
                  <th width="200px">{{ $t("RequestProgramm") }}</th>
                  <th width="1%">{{ $t("Date") }}</th>
                  <th width="1%">{{ $t("RequestStep") }}</th>
                  <th width="1%">{{ $t("TableStatus") }}</th>
                </tr>
              </thead>
              <tbody>
                <router-link
                  tag="tr"
                  :to="`/cabinet/staff-reviewer/verified-applications/${item.id}`"
                  class="link"
                  v-for="item of applicationList"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>
                    <span class="nowrap">{{ item.reg_number }}</span>
                  </td>
                  <td>{{ item.title }}</td>
                  <td>{{ item.program || "" }}</td>
                  <td>{{ item.created_at | dateDay }}</td>
                  <td>
                    <span class="nowrap">
                      {{ getStep(item.status) }} - {{ $t("Stage") }}
                    </span>
                  </td>
                  <td>
                    <span class="status-text" :class="item.status">{{
                      getStatus(item.status)
                    }}</span>
                  </td>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
        <utilsPagination
          :limit="limit"
          :page="page"
          :count="count"
          @paginate="paginate"
          @onChangeLimit="onChangeLimit"
        />
      </div>
      <div class="col-xl-3">
        <div class="catalog-side__steps">
          <div class="catalog-side__navs">
            <button
              @click="stepTabActive = 'first'"
              class="catalog-side__nav"
              :class="{ active: stepTabActive == 'first' }"
            >
              1 {{ $t("RequestStep") }}
            </button>
            <button
              @click="stepTabActive = 'second'"
              class="catalog-side__nav"
              :class="{ active: stepTabActive == 'second' }"
            >
              2 {{ $t("RequestStep") }}
            </button>
          </div>
          <div class="catalog-side__tabs" v-if="stepTabActive == 'first'">
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == null }"
              @click="activeFilter(null)"
            >
              {{ $t("allApplications") }}
            </div>
            <template v-for="(item, i) in statusList">
              <div
                class="catalog-side__tab"
                :key="i"
                v-if="item.value > 1 && item.value < 13"
                :class="{ active: filterTab == item.key }"
                @click="activeFilter(item.key)"
              >
                {{ item.title[$i18n.locale] }}
              </div>
            </template>
          </div>
          <div class="catalog-side__tabs" v-if="stepTabActive == 'second'">
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == null }"
              @click="activeFilter(null)"
            >
              {{ $t("allApplications") }}
            </div>
            <template v-for="(item, i) in statusList">
              <div
                class="catalog-side__tab"
                :key="i"
                v-if="item.value >= 13"
                :class="{ active: filterTab == item.key }"
                @click="activeFilter(item.key)"
              >
                {{ item.title[$i18n.locale] }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <reviewerCompleted
      v-if="showCompleted"
      :step="getStepCompleted"
      :close="close"
    />
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      stepTabActive: "first",
      filterTab: null,
      statusList: [],
      applicationList: [],
      count: 0,
      limit: 10,
      page: 1,
      reg_number: null,
      program: null,
      programs: [],
      isFilter: false,
      showCompleted: false,
      getStepCompleted: 1,
      isDirector: false,
    };
  },
  computed: {
    params() {
      let params = {
        reg_number: this.reg_number,
        program: this.program,
        limit: this.limit,
        offset: this.offset,
        exclude_by_status: "received_application",
        status: this.filterTab,
      };
      // this.filterTab
      //   ? (params.status = this.filterTab)
      //   : (params.status = [
      //       "rejected_in_consideration",
      //       "returned_in_consideration",
      //       "approved_in_consideration",
      //     ]);
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
  watch: {
    filterTab() {
      this.page = 1;
      this.getApplicationList();
    },
    page() {
      this.getApplicationList();
    },
    reg_number() {
      this.isFilter = false;
    },
    program() {
      this.isFilter = false;
    },
  },

  async mounted() {
    this.isLoading = true;
    this.statusList = statusList;
    await this.getProgramsList();
    this.filterTab = this.$route.query && this.$route.query.status;
    await this.getApplicationList();
    this.stepTabActive = this.getStep(this.filterTab) == 2 ? "second" : "first";

    const token = localStorage.getItem("access");
    const user = this.JwtParser(token);
    this.isDirector = user.user_role == "director";
    this.isLoading = false;
  },
  methods: {
    ...mapActions("reviewer", ["fetchPrograms", "fetchApplicationList"]),
    async getApplicationList() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async getApplicationListWithFilter() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isFilter = true;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async getProgramsList() {
      try {
        let res = await this.fetchPrograms();
        this.programs = res;
      } catch (err) {
        console.error(err);
      }
    },
    getStep(val) {
      let status = statusList.find((el) => el.key == val);
      // console.log(status)
      return status && status.value > 12 ? 2 : 1;
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    paginate(arg) {
      this.page = arg;
    },
    activeFilter(arg) {
      this.filterTab = arg;
      this.$router.push({ query: { status: arg } });
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.getApplicationList();
    },
    clearFilter() {
      this.reg_number = null;
      this.program = null;
      this.getApplicationList();
      this.isFilter = false;
    },
    onChange(val) {
      console.log(val);
      this.isFilter = true;
    },
    getShowCompleted(val) {
      if (val == "approved_in_international_expert") {
        this.getStepCompleted = 1;
      } else {
        this.getStepCompleted = 2;
      }
      this.showCompleted = true;
    },
    close() {
      this.showCompleted = false;
      this.getApplicationList();
    },
  },
};
</script>

<style>
</style>