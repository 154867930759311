<template>
  <div class="profile">
    <div class="profile-form">
      <b-row>
        <b-col md-6>
          <div class="form-col">
            <div
              v-for="(el, index) in firstRow"
              :key="index"
              class="form-group"
            >
              <label
                >{{ el && el.title && el.title[$i18n.locale] }}
                <span
                  v-if="el && el.field && el.field.required"
                  style="color: red"
                  >*</span
                >
              </label>
              <utilsComponentFinder
                v-model="form[el && el.field && el.field.name]"
                :componentData="el && el.field && el.field.component"
              />
            </div>
          </div>
        </b-col>
        <b-col md-6>
          <div class="form-col">
            <div
              v-for="(el, index) in secondRow"
              :key="index"
              class="form-group"
            >
              <label
                >{{ el && el.title && el.title[$i18n.locale] }}
                <span
                  v-if="el && el.field && el.field.required"
                  style="color: red"
                  >*</span
                >
              </label>
              <utilsComponentFinder
                v-model="form[el && el.field && el.field.name]"
                :componentData="el && el.field && el.field.component"
              />
              {{ form[el && el.field && el.field.name] }}
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="form-col">
        <div class="form-btns">
          <!-- <b-button class="mybtn mybtn-grey">Закрыть</b-button> -->
          <b-button class="mybtn mybtn-green" @click="onSave">{{
            $t("Save")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// accountData: [
// {
//     title: {
//         ru: '',
//         uz: '',
//         en: ''
//     },
//     field: {
//         name: 'first_name',
//         required: false,
//         component: {
//              name: 'input',
//              type: 'text'
//         }
//
//     }
// }
// ]
export default {
  props: {
    accountData: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      firstRow: [],
      secondRow: [],
      form: {},
    };
  },
  mounted() {
    console.log(this.accountData);
    let length = this.accountData.length;
    let firstRowLength = Math.ceil(length / 2);
    this.firstRow = this.accountData.slice(0, firstRowLength);
    this.secondRow = this.accountData.slice(firstRowLength, length);
    console.log(this.firstRow, this.secondRow);
  },
  methods: {
    onSave() {
      this.$emit("submit", this.form);
    },
  },
};
</script>

<style>
</style>