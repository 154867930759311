export const actions = {
  async fetchListSelect() {
    let { data } = await this.$axios.get("/site/program/list/");
    data = data.map((i) => ({
      ...i,
      value: i.id,
      text: i.title,
      disabled: !i.is_active || i.has_user_application,
    }));
    return data;
  },
  async fetchList() {
    let { data } = await this.$axios.get("/site/program/list/");
    return data;
  },
};
