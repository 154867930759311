<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("SidebarMenu5") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="popup-reports-form">
          <utilsLangTab @changeLang="onChangeLang" />
          <div class="form-group">
            <label for=""
              >{{ $t("Title") }} (<span class="text-capitalize">{{
                activeLang
              }}</span
              >)</label
            >
            <b-form-input
              id=""
              v-model="form['title_' + activeLang]"
              placeholder=""
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("Content") }} (<span class="text-capitalize">{{
                activeLang
              }}</span
              >)</label
            >
            <b-form-textarea
              id=""
              v-model="form['content_' + activeLang]"
              placeholder=""
            ></b-form-textarea>
          </div>
          <div class="form-group">
            <b-button @click="submit" class="mybtn mybtn-green">{{
              $t("Save")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activeLang: "ru",
      isLoading: false,
      form: {
        title_ru: "",
        title_en: "",
        title_uz: "",
        content_ru: "",
        content_en: "",
        content_uz: "",
      },
    };
  },
  mounted() {
    this.detail && this.detail.id && (this.form = this.detail);
  },
  methods: {
    ...mapActions("adminHelp", ["createHelp", "updateHelp"]),
    onChangeLang(value) {
      this.activeLang = value;
    },
    async submit() {
      let form = this.form;
      try {
        this.isLoading = true;
        this.form && this.form.id
          ? await this.updateHelp({ id: this.form.id, form: form })
          : await this.createHelp(form);
        this.close();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    fullFilled() {
      let notFilled;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          notFilled = true;
          return;
        }
      });

      return !notFilled;
    },
  },
};
</script>

<style>
</style>