<template>
  <div class="help">
    <b-row>
      <b-col md="8">
        <div class="help-list">
          <b-card
            no-body
            v-for="(item, index) in helpList"
            :key="item.id"
            class="help-list-item"
          >
            <b-card-header header-tag="div" role="tab">
              <div
                class="help-list-item-header"
                block
                v-b-toggle="'accordion-' + index + 1"
                variant="info"
              >
                <h3>
                  {{ item.title }}
                </h3>
                <i>
                  <span></span>
                  <span></span>
                </i>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + index + 1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="help-list-item-text">
                  <p>{{ item.text }}</p>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
      <b-col md="4">
        <div class="help-info">
          <h3>О системе</h3>
          <p>
            Для этикеток на этом напитке. Для того, чтобы обычный человек сделал
            это, необходимо избавиться от боли в иммунной системе. Не нужно
            делать это на заднем дворе. Масштабная кровать с недвижимостью,
            уродливая масса автомобилей. И нет, болельщики вылечатся, а
            жизнерадостность только льстит. Пусть будет один слой, простыни,
            подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы
            нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель.
            Даже страх перед Интернетом - это нормально. А массовый элемент -
            это мультфильм, а веселья нет. Милая моя, позорно, что завтра
            мучителя не будет. Nibh cursus sit quis duis В жизни но
            хозяйственный и у. Нет счетов, нет финансирования и нет домашних
            заданий.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      helpList: [
        {
          id: 1,
          title: "Как оставить заявки",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
        {
          id: 2,
          title: "Что делать, чтобы перейти ко второму этапу",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
        {
          id: 3,
          title: "Как сменить пароль",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
        {
          id: 4,
          title: "Что мне делать, когда моя слабость отменяется",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
        {
          id: 5,
          title: "Предлагаемые преимущества являются преимуществами",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
        {
          id: 6,
          title: "Информация о 5 программах",
          text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        },
      ],
    };
  },
};
</script>


<style scoped>
</style>