export const state = () => ({});

export const actions = {
  async fetchUsers(_, { limit, offset, role }) {
    const { data } = await this.$axios.get(
      `/admin/user/list/?role=${role ||
        "applicant_physical"}&role=${role || "applicant_legal"}&limit=${limit}&offset=${offset}`,
    
    );
    return data;
  },
  async fetchUsersDetail(_, id) {
    try {
      let res = await this.$axios.get(`/admin/user/detail/${id}/`);
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
};
