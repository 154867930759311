<template>
  <div>
    <v-select
      :options="options"
      :label="label"
      v-model="select"
      @search="(query) => (search = query)"
      :placeholder="placeholder"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number, Object],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    isId: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "label",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      select: this.value,
      search: "",
    };
  },
  mounted() {},
  methods: {
    onSearch(query, $event) {
      console.log(query);
      console.log($event);
    },
  },
  watch: {
    select(val) {
      if (this.isId) {
        this.$emit("change", (val && val.id) || "");
      } else {
        this.$emit("change", val);
      }
    },
    search(val) {
      console.log(val);
    },
  },
};
</script>

<style>
</style>