<template>
  <div class="popup staff-review">
    <div class="popup-main">
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("PopupStaffReviewTitle") }}</h3>
        <i class="icon-close" @click="close"></i>
      </div>
      <div class="popup-body">
        <!-- <div class="staff-reviewer" v-if="author">
          <span class="staff-reviewer-mini-title">{{
            $t("PopupStaffReviewTitle1")
          }}</span>
          <div class="staff-reviewer-card">
            <h3 class="staff-reviewer-title">
              {{
                author &&
                author.last_name +
                  " " +
                  author.first_name +
                  " " +
                  author.middle_name
              }}
            </h3>
            <div class="staff-reviewer-card-bottom">
              <span>ID: {{ regNumber || "" }}</span>
              <span
                >{{ $t("time") }}: {{ time | dateTimeFilter }} /
                {{ time | dateFilter }}</span
              >
            </div>
          </div>
        </div> -->
        <div class="staff-review-main" v-if="experts && experts.length">
          <span class="staff-reviewer-mini-title">{{
            $t("PopupStaffReviewTitle2")
          }}</span>
          <div class="row">
            <div
              class="col-lg-12"
              v-for="(item, index) in experts"
              :key="index"
            >
              <div class="staff-review-item">
                <h3 class="staff-reviewer-title">
                  {{
                    item &&
                    item.last_name +
                      " " +
                      item.first_name +
                      " " +
                      item.middle_name
                  }}
                </h3>
                <small v-if="item.speciality">{{
                  item && item.speciality
                }}</small>
                <span v-if="item && item.phone"
                  >{{ $t("phone") }}: {{ item && item.phone }}
                </span>
                <span v-if="item && item.email"
                  >{{ $t("FormEmail") }}: {{ item && item.email }}
                </span>
                <div
                  class="staff-reviewer-app"
                  v-if="item && item.application_consideration"
                >
                  <span
                    @click="toggleCollapse(item.id)"
                    :style="
                      item.application_consideration &&
                      item.application_consideration.type.includes('approved')
                        ? 'color: green'
                        : 'color: red'
                    "
                  >
                    {{
                      item.application_consideration &&
                      item.application_consideration.type.includes("approved")
                        ? $t("Approved")
                        : $t("Rejected")
                    }}
                    <i class="icon-angle-down"></i>
                  </span>

                  <div
                    class="staff-reviewer-app-wrapper"
                    v-if="item.id == activeElementId"
                  >
                    <div
                      class="staff-reviewer-app-comment"
                      v-if="
                        item.application_consideration &&
                        item.application_consideration.comment
                      "
                    >
                      <span>{{ $t("TableMessageText") }}</span>
                      <p
                        v-if="
                          item.application_consideration &&
                          item.application_consideration.comment
                        "
                      >
                        {{
                          (item.application_consideration &&
                            item.application_consideration.comment) ||
                          ""
                        }}
                      </p>
                    </div>

                    <div
                      class="staff-reviewer-app-file"
                      v-if="
                        item &&
                        item.application_consideration &&
                        item.application_consideration.attachment
                      "
                    >
                      <fileView
                        :name="
                          (item &&
                            item.application_consideration &&
                            item.application_consideration.attachment &&
                            item.application_consideration.attachment.name) ||
                          ''
                        "
                        :extension="
                          (item &&
                            item.application_consideration &&
                            item.application_consideration.attachment &&
                            item.application_consideration.attachment
                              .extension) ||
                          ''
                        "
                        :file_url="
                          (item &&
                            item.application_consideration &&
                            item.application_consideration.attachment &&
                            item.application_consideration.attachment.size) ||
                          ''
                        "
                        :size="
                          (item &&
                            item.application_consideration &&
                            item.application_consideration.attachment &&
                            item.application_consideration.attachment.url) ||
                          ''
                        "
                      />
                    </div>

                    <span
                      >{{ $t("time") }}:
                      {{
                        item &&
                        item.application_consideration &&
                        item.application_consideration.created_at | dateTime
                      }}
                      /
                      {{
                        item &&
                        item.application_consideration &&
                        item.application_consideration.created_at | dateDay
                      }}
                      <!-- {{
                        item.application_consideration &&
                        item.application_consideration.created_at
                          | dateTimeFilter
                      }} -->
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeElementId: 0,
    };
  },
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    author: {
      type: Object,
      default() {
        return {};
      },
    },
    regNumber: {
      type: String,
      default() {
        return null;
      },
    },
    time: {
      type: String,
      default() {
        return null;
      },
    },
    experts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    this.experts.forEach((element) => {
      element.isActive = false;
      console.log(element.isActive);
    });
    console.log(this.experts);
  },

  methods: {
    toggleCollapse(id) {
      // this.experts[this.experts.findIndex(el => el.id == id)].
      // console.log(this.experts[id].isActive, id);
      this.activeElementId = id != this.activeElementId ? id : null;
    },
  },
};
</script>

<style>
</style>