<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("SubmittingForRevision") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form-group">
          <label
            >{{ $t("TableMessageText") }}
            <span style="color: red">*</span></label
          >
          <b-form-textarea
            id="textarea-rows"
            placeholder="Введите"
            rows="8"
            v-model="form.comment"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <label for="date"
            >{{ $t("dateOfExpiration") }}
            <span style="color: red">*</span></label
          >
          <b-form-input
            id="date"
            v-model="form.revision_expire_date"
            type="date"
            :min="today"
          >
          </b-form-input>
        </div>
        <div class="form-group">
          <label for="">{{ $t("AttachDocument") }}</label>
          <fileUploader
            name="reviewer_new_application_send_form"
            @getFiles="getFiles"
          />
        </div>
        <div class="form_footer_custom">
          <b-button @click="sendForm" class="mybtn mybtn-blue">{{
            $t("Send")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        comment: "",
        attachment: null,
        revision_expire_date: null,
      },
    };
  },
  computed: {
    today() {
      var today = new Date();
      var dd = today.getDate() + 1;
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
  },
  methods: {
    ...mapActions("reviewerNewApplicationsId", ["action"]),
    getFiles(arg) {
      this.form.attachment = arg && arg.file;
    },
    async sendForm() {
      try {
        this.isLoading = true;
        let f = new FormData();
        f.append("application", this.$route.params && this.$route.params.id);
        f.append(
          "type",
          this.step == 1
            ? "returned_by_staff_reviewer"
            : "returned_by_staff_reviewer_at_second_step"
        );
        Object.keys(this.form).forEach((key) => {
          key != "attachment" &&
            this.form[key] &&
            f.append(key, this.form[key]);
          key == "attachment" &&
            this.form[key] &&
            this.form[key].name &&
            f.append(
              key,
              this.form[key],
              this.form[key] && this.form[key].name
            );
        });
        let res = await this.action(f);
        this.isLoading = false;
        // console.log(res)
        if (res && res.status == 201) {
          this.$toast.success(this.$t("successText"));
          this.close();
          this.$router.push("/cabinet/staff-reviewer/verified-applications");
        }
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.form_footer_custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>