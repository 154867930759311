export const actions = {
  async fetchPrograms() {
    let { data } = await this.$axios.get("/admin/program/list/");
    data = data.map((i) => ({ ...i, value: i.id, text: i.title }));
    return data;
  },
  async fetchApplicationList(_, { params }) {
    try {
      let res = await this.$axios.get("/admin/application/list/", { params });
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
};
