<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main" v-if="!loading">
      <div class="pages-title">
        <h2>{{ $t("ApplicationForm2") }}</h2>
      </div>
      <div class="request-form">
        <b-row>
          <b-col xl="6">
            <div class="form-col">
              <div
                class="form-group"
                v-if="
                  form.status == 'first_step_completed' ||
                  form.status == 'received_application_at_second_step'
                "
              >
                <label for=""
                  >{{ $t("RequestInfoProject") }}
                  <span class="red">*</span></label
                >
                <div class="form-example">
                  <fileUploader
                    key="1"
                    @getFiles="setProjectFile"
                    name="project"
                    :files="second_step_information_file"
                  />
                  <a
                    :href="infoTemplateFile"
                    class="form-example__item"
                    download="download"
                    target="_blank"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1"
                        stroke="#313131"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>{{ $t("RequestDownload") }}</span>
                  </a>
                </div>
                <small
                  class="error-text"
                  v-if="
                    $v.form.second_step_information_file.$dirty &&
                    !$v.form.second_step_information_file.required
                  "
                  >{{ $t("uploadTheFile") }}</small
                >
              </div>
              <div class="form-group" v-else>
                <label for=""
                  >{{ $t("modifiedFile") }} <span class="red">*</span></label
                >
                <div class="form-example">
                  <fileUploader
                    key="1"
                    @getFiles="setModifiedFile"
                    name="project"
                    :files="revisioned_second_step_information_file"
                  />
                  <a
                    :href="infoTemplateFile"
                    class="form-example__item"
                    download="download"
                    target="_blank"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1"
                        stroke="#313131"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>{{ $t("RequestDownload") }}</span>
                  </a>
                </div>
                <small
                  class="error-text"
                  v-if="
                    $v.form.second_step_information_file.$dirty &&
                    !$v.form.second_step_information_file.required
                  "
                  >{{ $t("uploadTheFile") }}</small
                >
              </div>
            </div>
          </b-col>
          <b-col xl="6">
            <div class="form-col">
              <div class="form-group" style="display: none;">
                <label for="">{{ $t("Other") }}</label>
                <fileUploader
                  key="2"
                  multiple
                  @getFiles="setOtherFiles"
                  name="other"
                  :files="second_step_files"
                  deleteapi="/site/application/secondstep/attachment/delete"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="form-col">
          <div class="form-btns" v-if="form.is_editable">
            <b-button class="mybtn mybtn-green" @click="onSave">{{
              $t("Save")
            }}</b-button>
            <b-button
              v-if="
                form &&
                form.status &&
                (form.status == 'first_step_completed' ||
                  form.status == 'returned_in_consideration_at_second_step')
              "
              class="mybtn mybtn-blue"
              @click="sendRequest"
              :disabled="!isCorrect || submitStatus == 'PENDING'"
              >{{ $t("RequestApply") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        second_step_files: [],
        second_step_information_file: null,
        revisioned_second_step_information_file: null,
      },
      programs: [],
      loading: false,
      second_step_files: [],
      second_step_information_file: "",
      revisioned_second_step_information_file: "",
      submitStatus: "OK",
      infoTemplateFile: null,
      isLoading: true,
      filterObj: {},
    };
  },
  validations: {
    form: {
      second_step_information_file: { required },
    },
  },
  async created() {
    try {
      this.loading = true;
      this.programs = await this.$store.dispatch(
        "userPrograms/fetchListSelect"
      );
      console.log("programs", this.programs);

      if (this.$route.query.id) {
        const form = await this.fetchAplicationDetail(this.$route.query.id);
        this.form = { ...form, program: form.program.id };
      }
      this.second_step_information_file =
        this.form.second_step_information_file;
      this.revisioned_second_step_information_file =
        this.form.revisioned_second_step_information_file;
      this.second_step_files = this.form.second_step_files;

      const file = this.programs.find((i) => i.id == this.form.program);
      this.infoTemplateFile =
        file && file.info_template_file && file.info_template_file.url;
      if (this.form.status == "returned_in_consideration_at_second_step") {
        this.filterObj.required = true;
      }

      this.loading = false;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {},
  computed: {
    isCorrect() {
      return this.form.second_step_information_file &&
        this.form.second_step_files &&
        (this.form.status == "returned_in_consideration_at_second_step"
          ? this.form.revisioned_second_step_information_file
          : true)
        ? true
        : false;
    },
  },
  methods: {
    ...mapActions("userRequest", [
      "fetchAplicationDetail",
      "updateApplication",
    ]),
    setProjectFile(f) {
      this.form.second_step_information_file = f && f.file;
    },
    setModifiedFile(f) {
      this.form.revisioned_second_step_information_file = f && f.file;
    },
    setOtherFiles(f) {
      this.form.second_step_files = f;
    },
    async onSave() {
      try {
        this.isLoading = true;
        let f = new FormData();
        Object.keys(this.form).forEach((k) => {
          if (k == "second_step_files" || k == "files") {
            this.form[k].forEach((i) => {
              if (i.file && i.file.extension) {
                console.log(i.file && i.file.extension);
              } else {
                i.file && f.append(k, i.file, i.file.name);
              }
            });
          } else if (
            k == "second_step_information_file" ||
            k == "information_file" ||
            k == "revisioned_second_step_information_file"
          ) {
            if (this.form[k] && this.form[k].extension) {
              console.log(this.form[k] && this.form[k].extension);
            } else {
              this.form[k] && f.append(k, this.form[k], this.form[k].name);
            }
          }
        });
        if (this.form.id) {
          const res = await this.updateApplication({
            form: f,
            id: this.$route.query.id,
          });
          if (res.status == 200 || res.status == 201) {
            this.$router.push(
              `/cabinet/user/applications/${this.$route.query.id}/`
            );
            this.$toast.success(this.$t("successSaved"));
          } else {
            const data = res.response.data;
            console.log(data, "data");
            Object.keys(data).forEach((i) => {
              if (Array.isArray(data[i])) {
                throw new Error(`${i}: ${data[i]}`);
              } else {
                const obj = data[i];
                Object.keys(obj).forEach((k) => {
                  throw new Error(`${i}: ${obj[k][0]}`);
                });
              }
            });
          }
        }
      } catch (e) {
        this.$toast.error(e.message, {
          position: "top",
        });
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async sendRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return;
      }
      this.submitStatus = "PENDING";
      try {
        this.isLoading = true;
        let res = null;
        let f = new FormData();
        Object.keys(this.form).forEach((k) => {
          if (k == "second_step_files") {
            this.form[k].forEach((i) => {
              if (i.file && i.file.extension) {
                console.log(i.file && i.file.extension);
              } else {
                i.file && f.append(k, i.file, i.file.name);
              }
            });
          } else if (
            k == "second_step_information_file" ||
            k == "revisioned_second_step_information_file"
          ) {
            if (this.form[k] && this.form[k].extension) {
              console.log(this.form[k] && this.form[k].extension);
            } else {
              this.form[k] && f.append(k, this.form[k], this.form[k].name);
            }
          }
        });
        this.form.status == "returned_in_consideration_at_second_step"
          ? f.set("status", "revisioned_application_at_second_step")
          : f.set("status", "received_application_at_second_step");
        if (this.form.id) {
          res = await this.$axios.patch(
            `/site/application/update/${this.form.id}/`,
            f
          );
          if (res.status == 200 || res.status == 201) {
            this.$router.push(
              `/cabinet/user/applications/${this.$route.query.id}/`
            );
            this.$toast.success(this.$t("successSaved"));
          } else {
            const data = res.response.data;
            Object.keys(data).forEach((i) => {
              if (Array.isArray(data[i])) {
                throw new Error(`${i}: ${data[i]}`);
              } else {
                const obj = data[i];
                Object.keys(obj).forEach((k) => {
                  throw new Error(`${i}: ${obj[k][0]}`);
                });
              }
            });
          }
        }

        if (
          res &&
          res.response &&
          res.response.status == 201 &&
          res.response.status == 200
        )
          this.$toast.success(this.$t("successText"));
        else if (res && res.response && res.response.status == 400) {
          const data = res.response.data;
          Object.keys(data).forEach((i) => {
            if (Array.isArray(data[i])) {
              throw new Error(`${i}: ${data[i]}`);
            } else {
              const obj = data[i];
              Object.keys(obj).forEach((k) => {
                throw new Error(`${i}: ${obj[k][0]}`);
              });
            }
          });
        }
        this.$toast.success(this.$t("successText"));
        this.form.status = "new";
        this.submitStatus = "OK";
        this.$router.push("/cabinet/user/applications");
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
