<template>
  <div class="popup">
    <div class="popup-main">
      <div class="popup-header">
        <h3 class="popup-title text-danger">Почему было отклонена</h3>
        <i class="icon-close" @click="close"></i>
      </div>
      <div class="popup-body">
        <div class="row">
          <div class="col-md-6">
            <small>Кто одобрил и когда</small>
          </div>
          <div class="col-md-12">
            <div class="card p-2 px-3">
              <b>Ruziyev Muhammaddiyor</b>
              <div class="col-md-12">
                <small>ID: REP234</small>
                <small style="float: right">Время: 18:32 / 27 июл 2021</small>
              </div>
            </div>
          </div>
          <div class="row py-4">
            <small>{{ $t("TableMessageText") }}</small>
            <b
              >В ваших документах есть недостатки. В проекте паспорта есть
              недостатки в вашем документе, поэтому он был отклонен. Повторите
              попытку.</b
            >
          </div>
        </div>
        <div class="row pt-3">
          <label>{{ $t("AttachedDocument") }}</label>
          <div class="col-md-6">
            <Fileview />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fileview from "@/components/file/View";

export default {
  data() {
    return {};
  },
  components: {
    Fileview,
  },
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>