<template>
  <div class="catalog">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="row">
      <div class="col-md-3">
        <div class="catalog-side">
          <div class="catalog-side__tabs">
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'nationality' }"
              @click="changeTab('nationality')"
            >
              {{ $t("Nationalities") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'country' }"
              @click="changeTab('country')"
            >
              {{ $t("Countries") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'region' }"
              @click="changeTab('region')"
            >
              {{ $t("User.Region") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'district' }"
              @click="changeTab('district')"
            >
              {{ $t("Districts") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'typeorganization' }"
              @click="changeTab('typeorganization')"
            >
              {{ $t("User.LegalType") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: currentTab == 'speciality' }"
              @click="changeTab('speciality')"
            >
              {{ $t("Specialization") }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="catalog-main">
          <div class="pages-title">
            <h3>{{ title }}</h3>
            <button
              @click="showModalCreate = true"
              class="btn mybtn mybtn-blue"
            >
              {{ $t("Add") }}
            </button>
          </div>
          <div v-if="loading">loading...</div>
          <div v-else class="table">
            <table>
              <thead>
                <tr>
                  <th width="1%">ID</th>
                  <th width="150px">{{ $t("TableTitle") }}</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="el in tabList" :key="el.id">
                  <td>{{ el.id }}</td>
                  <td>{{ el.title }}</td>
                  <td>
                    <div class="table-btns">
                      <button
                        @click="editDetail(el.id)"
                        class="btn btn-primary"
                      >
                        <b-icon icon="pencil"></b-icon>
                      </button>
                      <button
                        v-if="
                          currentTab != 'district' && currentTab != 'region'
                        "
                        @click="removeDetail(el.id)"
                        class="btn btn-danger"
                      >
                        <b-icon icon="trash"></b-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <utilsPagination
          :page="page"
          :limit="limit"
          :count="count"
          @paginate="paginate"
          @onChangeLimit="onChangeLimit"
        />
      </div>
    </div>
    <popupsCatalogCreate
      :close="close"
      v-if="showModalCreate"
      :createDetail="createItem"
    />
    <popupsCatalogUpdate
      :detail="updateDetail"
      :close="close"
      v-if="showModalUpdate"
      :updateDetail="updateItem"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      currentTab: "nationality",
      page: 1,
      limit: 10,
      count: 0,
      tabList: [],
      form: {
        id: null,
        title: null,
      },
      showModalCreate: false,
      showModalUpdate: false,
      updateDetail: {},
      loading: false,
    };
  },
  watch: {
    currentTab(newVal) {
      this.$router.push({
        query: {
          tab: newVal,
        },
      });
      this.fetchTabList();
    },
  },
  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    },
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
      };
      return params;
    },
    title() {
      return this.currentTab == "nationality"
        ? this.$t("Nationalities")
        : this.currentTab == "country"
        ? this.$t("Countries")
        : this.currentTab == "region"
        ? this.$t("User.Region")
        : this.currentTab == "district"
        ? this.$t("Districts")
        : this.currentTab == "typeorganization"
        ? this.$t("User.LegalType")
        : this.currentTab == "speciality"
        ? this.$t("Specialization")
        : this.$t("All");
    },
  },
  async mounted() {
    // this.$router.push({
    //         query: {
    //             tab: this.currentTab
    //         }
    //     })
    this.isLoading = true;
    this.$route.query &&
      this.$route.query.tab &&
      (this.currentTab = this.$route.query.tab);
    await this.fetchTabList();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("adminCatalog", [
      "fetchListByTabName",
      "createDetail",
      "update",
      "deleteDetail",
      "getDetail",
    ]),
    changeTab(arg) {
      this.currentTab = arg;
    },
    async fetchTabList() {
      this.loading = true;
      this.count = 0;
      try {
        this.isLoading = true;
        let { count, results } = await this.fetchListByTabName({
          tab: this.currentTab,
          params: this.params,
        });
        this.count = count;
        this.tabList = results;
        this.loading = false;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.isLoading = false;
      }
    },
    async createItem(form) {
      try {
        await this.createDetail({
          tab: this.currentTab,
          form,
        });
        // this.$router.go()
        this.fetchTabList();
        this.close();
      } catch (err) {
        console.error(err);
      }
    },
    async updateItem(form) {
      try {
        await this.update({
          tab: this.currentTab,
          form,
        });
        // this.$router.go()
        this.fetchTabList();
        this.close();
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      this.showModalCreate = false;
      this.showModalUpdate = false;
    },
    paginate(e) {
      this.page = e;
      this.fetchTabList();
    },
    async removeDetail(id) {
      try {
        this.isLoading = true;
        await this.deleteDetail({
          tab: this.currentTab,
          id,
        });
        // this.$router.go()
        this.fetchTabList();
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async editDetail(id) {
      try {
        let res = await this.getDetail({
          tab: this.currentTab,
          id,
        });
        this.updateDetail = res;
        this.showModalUpdate = true;
      } catch (err) {
        console.error(err);
      }
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.fetchTabList();
    },
  },
};
</script>

<style scoped>
.catalog-side__tabs > div {
  cursor: pointer;
}
</style>