<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("FormRegister") }}</h2>
    </div>
    <form @keyup.enter="createUserAccount" class="auth-block__form">
      <div class="form-group">
        <label for="username"
          >{{ $t("User.Username") }} <span class="red">*</span></label
        >
        <b-form-input
          id="username"
          v-model="form.username"
          @input="checkUser"
          :placeholder="$t('errorUsername')"
        ></b-form-input>
        <p v-if="username_error" style="color: red">
          {{ $t("UsernameExists") }}
        </p>
      </div>
      <div v-if="usertype == 'applicant_legal'" class="form-group">
        <label for="legal_name"
          >{{ $t("User.LegalName") }}<span class="red">*</span></label
        >
        <b-form-input
          id="legal_name"
          v-model="form.legal_name"
          placeholder=""
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="password"
          >{{ $t("User.Password") }} <span class="red">*</span></label
        >
        <!-- <b-form-input id="password" type="password" v-model="form.password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.password" :hasTitle="false" />
      </div>
      <div class="form-group">
        <label for="confirm_password"
          >{{ $t("FormConfirmPassword") }} <span class="red">*</span></label
        >
        <!-- <b-form-input type="password" id="confirm_password" v-model="form.confirm_password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.confirm_password" :hasTitle="false" />
      </div>
      <div class="form-btns">
        <b-button
          @click="createUserAccount"
          :disabled="!isFormFilled"
          variant="primary"
          >{{ $t("FormRegister2") }}</b-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: {
    extraFields: {
      type: Object,
      default() {
        return {};
      },
    },
    phone: {
      type: String,
      default() {
        return "";
      },
    },
    usertype: {
      type: String,
      default() {
        return "applicant_physical";
      },
    },
  },
  data() {
    return {
      isLoading: false,
      userType: "normal",
      form: {
        username: null,
        password: null,
        confirm_password: null,
      },
      username_error: false,
    };
  },
  computed: {
    isFormFilled() {
      return (
        this.form.username && this.isPasswordConfirmed && this.form.legal_name
      );
    },
    isPasswordConfirmed() {
      return (
        this.form.password &&
        this.form.confirm_password &&
        this.form.password == this.form.confirm_password
      );
    },
    password() {
      return this.form.password;
    },
    confirm_password() {
      return this.form.confirm_password;
    },
  },
  watch: {
    password() {
      // console.log(newValue);
    },
    confirm_password() {
      // console.log(newValue);
    },
  },
  methods: {
    ...mapActions("auth", ["createAccount", "smartRouter"]),
    ...mapMutations("auth", ["setState"]),
    async createUserAccount() {
      try {
        this.isLoading = true;
        let params = {
          ...this.extraFields,
          role: this.usertype,
          username: this.form.username,
          password: this.form.password,
          phone: "998" + this.phone,
        };
        this.form.legal_name && (params.legal_name = this.form.legal_name);
        await this.checkUser(this.form.username);
        await this.createAccount(params);
        // res == "username found" && (this.username_error = true);

        // this.smartRouter({
        //     access: res && res.access_token,
        //     refresh: res && res.refresh_token
        // })
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async checkUser(val) {
      if (val) {
        const { is_exists } = await this.$store.dispatch("app/checkUser", {
          username: val,
        });
        is_exists == true
          ? (this.username_error = true)
          : (this.username_error = false);
      } else {
        this.username_error = false;
      }
    },
  },
};
</script>

<style scoped>
</style>