<template>
  <div class="popup">
    <div class="popup-main">
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("SidebarMenu3") }}</h3>
        <i class="icon-close" @click="$emit('close')"></i>
      </div>
      <div class="popup-body">
        <div class="popup-reports-form">
          <div class="form-group">
            <label for="org_name"
              >{{ $t("theme") }} <span class="red">*</span></label
            >
            <b-form-input
              id="org_name"
              v-model="form.theme"
              :placeholder="$t('EnterText')"
              :class="{
                invalid: $v.form.theme.$dirty && !$v.form.theme.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.theme.$dirty && !$v.form.theme.required"
              >{{ $t("enterTheme") }}</small
            >
          </div>
          <div class="form-group">
            <label for="message"
              >{{ $t("TableMessageText") }} <span class="red">*</span></label
            >
            <b-form-textarea
              id="message"
              v-model="form.message"
              :placeholder="$t('EnterText')"
              :class="{
                invalid: $v.form.message.$dirty && !$v.form.message.required,
              }"
            ></b-form-textarea>
            <small
              class="error-text"
              v-if="$v.form.message.$dirty && !$v.form.message.required"
              >{{ $t("enterMessageText") }}</small
            >
          </div>
          <div class="form-group">
            <label for="">{{ $t("AttachDocument") }}</label>
            <fileUploader name="feedback" @getFiles="getFile" />
          </div>

          <div class="form-group">
            <b-button
              class="mybtn mybtn-blue"
              @click="onSubmit"
              :disabled="submitStatus == 'pending'"
              >{{ $t("Send") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    submitStatus: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        theme: "",
        message: "",
        file: null,
        feedback: this.$route.params.id,
      },
    };
  },
  validations: {
    form: {
      theme: { required },
      message: { required },
    },
  },
  methods: {
    async onSubmit() {
      let f = new FormData();
      Object.keys(this.form).forEach((i) => {
        i != "file" && this.form[i] && f.append(i, this.form[i]);
        i == "file" &&
          this.form[i] &&
          f.append(i, this.form[i], this.form[i].name);
      });
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          const res = await this.$axios.post(
            "/admin/feedback/answer/create/",
            f
          );
          if (res.status == 200 || res.status == 201) {
            this.$toast.success(this.$t("successText"));
            this.$emit("close");
            this.$router.go();
          } else {
            const data = res.response.data;
            Object.keys(data).forEach((i) => {
              throw new Error(`${i}: ${data[i]}`);
            });
          }
        } catch (e) {
          this.$toast.error(e.message);
          console.log(e);
        }
      }
    },

    getFile(val) {
      this.form.file = val.file;
    },
  },
};
</script>

<style></style>
