export default [
  {
    key: "draft",
    value: 0,
    title: {
      ru: "Заявка сохранено",
      en: "Application saved",
      uz: "Ilova saqlangan",
      oz: "Ilova saqlangan",
    },
    type: "check",
  },
  {
    key: "received_application",
    value: 1,
    title: {
      ru: "Заявка принята",
      en: "Received application",
      uz: "Ilova qabul qilindi",
      oz: "Ilova qabul qilindi",
    },
    type: "check",
  },
  {
    key: "approved_in_consideration",
    value: 2,
    title: {
      ru: "Одобрено на этапе рассмотрения 1-го этапа",
      en: "Approved at the stage of review of the 1st stage",
      uz: "1-bosqich uchun ariza ko’rib chiqish jarayonida tasdiqlandi",
      oz: "Одобрено на этапе рассмотрения 1-го этапа",
    },
    type: "check",
  },
  {
    key: "returned_in_consideration",
    value: 3,
    title: {
      ru: "Заявка возвращена на доработку 1-го этапа",
      en: "Approved at the stage of review of the 1st stage",
      uz: "1-bosqich uchun ariza ko’rib chiqish jarayonida tasdiqlandi",
      oz: "Заявка возвращена на доработку 1-го этапа",
    },
    type: "warning",
  },
  {
    key: "revisioned_application",
    value: 4,
    title: {
      ru: "Доработка 1-го этапа завершена",
      en: "The revision of the 1st stage is completed",
      uz: "1-bosqich uchun qayta ishlash yakunlandi",
      oz: "Доработка 1-го этапа завершена",
    },
    type: "check",
  },
  {
    key: "rejected_in_consideration",
    value: 5,
    title: {
      ru: "Отклонена на этапе рассмотрения 1-го этапа",
      en: "Rejected at the consideration of the 1st stage",
      uz: "1-bosqich uchun ariza ko’rib chiqish jarayonida rad etildi",
      oz: "Отклонена на этапе рассмотрения 1-го этапа",
    },
    type: "close",
  },
  {
    key: "in_technical_expert",
    value: 6,
    title: {
      ru: "Заявка переведена в технической экспертизы",
      en: "Application was transferred to the technical expertise",
      uz: "Ariza texnik ekspertizaga yuborildi",
      oz: "Texnik expert ko'rigida",
    },
    type: "check",
  },
  {
    key: "waiting_third_technical_expert",
    value: 7,
    title: {
      ru: "Заявка переведена в дополнительную техническую экспертизу",
      en: "Application was transferred to an additional technical expertise",
      uz: "Ariza qo’shimcha texnik ekspertizaga yuborildi",
      oz: "Заявка переведена в дополнительную техническую экспертизу",
    },
    type: "warning",
  },
  {
    key: "rejected_in_technical_expert",
    value: 8,
    title: {
      ru: "Заявка отклонена на стадии технической экспертизы 1го этапа",
      en:
        "Application was rejected at the stage of technical examination of the 1st stage",
      uz: "1-bosqich uchun ariza texnik ekspertiza tomonidan rad etildi",
      oz: "Заявка отклонена на стадии технической экспертизы 1го этапа",
    },
    type: "close",
  },
  {
    key: "in_international_expert",
    value: 9,
    title: {
      ru: "Заявка передана в международную экспертную группу 1го этапа",
      en:
        "Application has been transferred to the international expert board of the 1st stage",
      uz: "1-bosqich uchun ariza xalqaro ekspertlar kengashiga yuborildi",
      oz: "1-босқич учун ариза халқаро експертлар кенгашига юборилди",
    },
    type: "check",
  },
  {
    key: "approved_in_international_expert",
    value: 10,
    title: {
      ru: "Заявка одобрен международным экспертом на 1го этапа",
      en:
        "Application is approved by an international expert board at the 1st stage",
      uz:
        "1-bosqich uchun ariza xalqaro ekspertlar kengashi tomonidan tasdiqlandi",
      oz: "Заявка одобрен международным экспертом на 1го этапа",
    },
    type: "check",
  },
  {
    key: "rejected_in_international_expert",
    value: 11,
    title: {
      ru: "Заявка отклонено международным экспертом на 1го этапа",
      en:
        "Application was rejected by an international expert board at the 1st stage",
      uz:
        "1-bosqich uchun ariza xalqaro ekspertlar kengashi tomonidan rad etildi",
      oz: "Заявка отклонено международным экспертом на 1го этапа",
    },
    type: "close",
  },
  {
    key: "first_step_completed",
    value: 12,
    title: {
      ru: "1-этап завершен",
      en: "Stage 1 completed",
      uz: "1-bosqich yakunlandi",
      oz: "1-этап завершен",
    },
    type: "check",
  },
  // Second Step Status List
  {
    key: "received_application_at_second_step",
    value: 13,
    title: {
      ru: "Принята документа на 2 этап",
      en: "Accepted document for the 2nd stage",
      uz: "2-bosqich uchun qabul qilingan hujjatlar",
      oz: "Принята документа на 2 этап",
    },
    type: "check",
  },
  {
    key: "approved_in_consideration_at_second_step",
    value: 14,
    title: {
      ru: "Одобрено на этапе рассмотрения 2-го этапа",
      en: "Approved at the stage of review of the 2nd stage",
      uz: "2-bosqich uchun ariza ko’rib chiqish jarayonida tasdiqlandi",
      oz: "Одобрено на этапе рассмотрения 2-го этапа",
    },
    type: "check",
  },
  {
    key: "returned_in_consideration_at_second_step",
    value: 15,
    title: {
      ru: "Заявка возвращена на доработку 2-го этапа",
      en: "Application was returned for revision of the 2nd stage",
      uz: "2-bosqich uchun ariza qayta ishlash uchun yuborildi",
      oz: "Заявка возвращена на доработку 2-го этапа",
    },
    type: "warning",
  },
  {
    key: "revisioned_application_at_second_step",
    value: 16,
    title: {
      ru: "Доработка 2-го этапа завершена",
      en: "The revision of the 2nd stage is completed",
      uz: "2-bosqich uchun qayta ishlash yakunlandi",
      oz: "Доработка 2-го этапа завершена",
    },
    type: "check",
  },
  {
    key: "rejected_in_consideration_at_second_step",
    value: 17,
    title: {
      ru: "Отклонена на этапе рассмотрения 2-го этапа",
      en: "Rejected at the consideration of the 2nd stage",
      uz: "2-bosqich uchun ariza ko’rib chiqish jarayonida rad etildi",
      oz: "Отклонена на этапе рассмотрения 2-го этапа",
    },
    type: "close",
  },
  {
    key: "in_technical_expert_at_second_step",
    value: 18,
    title: {
      ru: "Заявка переведена в технической экспертизы",
      en:
        "Application was rejected at the stage of technical examination of the 2nd stage",
      uz: "2-bosqich uchun ariza texnik ekspertiza tomonidan rad etildan",
      oz: "Texnik expert ko'rigida",
    },
    type: "check",
  },
  {
    key: "waiting_third_technical_expert_at_second_step",
    value: 19,
    title: {
      ru: "Заявка переведена в дополнительную техническую экспертизу",
      en: "Application was transferred to an additional technical expertise",
      uz: "Ariza qo’shimcha texnik ekspertizaga yuborildi",
      oz: "Заявка переведена в дополнительную техническую экспертизу",
    },
    type: "warning",
  },
  {
    key: "rejected_in_technical_expert_at_second_step",
    value: 20,
    title: {
      ru: "Заявка отклонена на стадии технической экспертизы 2го этапа",
      en:
        "Application was rejected at the stage of technical examination of the 2nd stage",
      uz: "2-bosqich uchun ariza texnik ekspertiza tomonidan rad etildan",
      oz: "Заявка отклонена на стадии технической экспертизы 2го этапа",
    },
    type: "close",
  },
  {
    key: "in_international_expert_at_second_step",
    value: 21,
    title: {
      ru: "Заявка передана в международную экспертную группу 2го этапа",
      en:
        "Application has been transferred to the international expert board of the 2nd stage",
      uz: "2-bosqich uchun ariza xalqaro ekspertlar kengashiga yuborildi",
      oz: "Заявка передана в международную экспертную группу 2го этапа",
    },
    type: "check",
  },
  {
    key: "approved_in_international_expert_at_second_step",
    value: 22,
    title: {
      ru: "Заявка одобрен международным экспертом на 2го этапа",
      en:
        "Application is approved by an international expert board for the 2nd stage",
      uz:
        "2-bosqich uchun ariza xalqaro ekspertlar kengashi tomonidan tasdiqlandi",
      oz: "Заявка одобрен международным экспертом на 2го этапа",
    },
    type: "check",
  },
  {
    key: "rejected_in_international_expert_at_second_step",
    value: 23,
    title: {
      ru: "Заявка отклонено международным экспертом на 2го этапа",
      en:
        "Application was rejected by an international expert board for the 2nd stage",
      uz:
        "2-bosqich uchun ariza xalqaro ekspertlar kengashi tomonidan rad etildi",
      oz: "Заявка отклонено международным экспертом на 2го этапа",
    },
    type: "close",
  },
  {
    key: "winned_application",
    value: 24,
    title: {
      ru: "2-этап завершен",
      en: "Stage 2 completed",
      uz: "2-bosqich yakunlandi",
      oz: "2-этап завершен",
    },
    type: "check",
  },
];
