<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="request-view">
      <div class="request-view-info">
        <div class="request-view-info-logo">
          <img
            :src="
              (request && request.program && request.program.icon) ||
              require('@/assets/images/programs/medal.svg')
            "
            alt="not found"
          />
        </div>
        <div class="request-view-info-text">
          <div class="staff-view-info-header">
            <div class="staff-view-header">
              <span class="status-text" :class="(status && status.key) || ''">{{
                (status && status.title && status.title[$i18n.locale]) || ""
              }}</span>
              <p><span>ID:</span>{{ request && request.reg_number }}</p>
              <p>
                <span>{{ $t("Date") }}:</span
                >{{ (request && request.created_at) || "" | dateDay }}
              </p>
            </div>

            <div class="request-button">
              <!-- <button @click="Req">Данные заявителя</button> -->
            </div>
          </div>
          <div class="request-view-info-body">
            <p>
              <span>{{ $t("RequestProgramm") }}:</span>
              {{ (request && request.program && request.program.title) || "" }}
            </p>
            <p>
              <span>{{ $t("TableTitle") }}:</span>
              {{ (request && request.title) || "" }}
            </p>
          </div>
        </div>
      </div>
      <div class="request-view-wrapper">
        <div class="pages-title">
          <h3>{{ $t("RequestData") }}</h3>
        </div>
        <div class="request-view-body">
          <div class="request-view-body-wrapper">
            <div
              class="request-view-body-wrapper-border"
              style="height: calc(100% - (364px / 2))"
            ></div>
            <div class="request-view-body-item active">
              <span class="request-view-stage">1 {{ $t("Stage") }}</span>
              <span class="request-view-circle"></span>
              <div class="request-view-body-buttons">
                <button
                  v-if="
                    request &&
                    request.application_consideration &&
                    request.application_consideration.first_step
                  "
                  class="t-gray-btn"
                  :class="
                    request &&
                    request.application_consideration &&
                    request.application_consideration.first_step &&
                    request.application_consideration.first_step.type.includes(
                      'approved'
                    )
                      ? 'approved'
                      : 'rejected'
                  "
                  @click="openFirstModal"
                >
                  {{ $t("ViewTheAnswer") }}
                </button>
              </div>

              <div class="request-view-row">
                <div
                  class="request-view-col"
                  v-if="request && request.pasport_file"
                >
                  <label>{{ $t("RequestPassportProject") }}</label>
                  <fileView
                    :name="
                      request &&
                      request.pasport_file &&
                      request.pasport_file.name
                    "
                    :extension="
                      request &&
                      request.pasport_file &&
                      request.pasport_file.extension
                    "
                    :file_url="
                      request &&
                      request.pasport_file &&
                      request.pasport_file.url
                    "
                    :size="
                      request &&
                      request.pasport_file &&
                      request.pasport_file.size
                    "
                  />
                </div>
                <div
                  class="request-view-col"
                  v-if="request && request.information_file"
                >
                  <label>{{ $t("RequestInfoProject") }}</label>
                  <fileView
                    :name="
                      request &&
                      request.information_file &&
                      request.information_file.name
                    "
                    :extension="
                      request &&
                      request.information_file &&
                      request.information_file.extension
                    "
                    :file_url="
                      request &&
                      request.information_file &&
                      request.information_file.url
                    "
                    :size="
                      request &&
                      request.information_file &&
                      request.information_file.size
                    "
                  />
                </div>
                <div
                  class="request-view-col"
                  v-for="(file, index) in request && request.files"
                  :key="index"
                >
                  <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                  <fileView
                    :name="file && file.attachment && file.attachment.name"
                    :extension="
                      file && file.attachment && file.attachment.extension
                    "
                    :file_url="file && file.attachment && file.attachment.url"
                    :size="file && file.attachment && file.attachment.size"
                  />
                </div>
              </div>
            </div>
            <div
              class="request-view-body-item"
              :class="{ active: getStatusStep(status && status.key) == 2 }"
              v-if="getStatusStep(status && status.key) == 2"
            >
              <span class="request-view-stage">2 {{ $t("RequestStep") }}</span>
              <span class="request-view-circle"></span>
              <div
                class="request-view-row"
                v-if="request && request.second_step_information_file"
              >
                <div
                  class="request-view-col"
                  v-if="request && request.second_step_information_file"
                >
                  <label>{{ $t("RequestInfoProject") }}</label>
                  <fileView
                    :name="
                      request &&
                      request.second_step_information_file &&
                      request.second_step_information_file.name
                    "
                    :extension="
                      request &&
                      request.second_step_information_file &&
                      request.second_step_information_file.extension
                    "
                    :file_url="
                      request &&
                      request.second_step_information_file &&
                      request.second_step_information_file.url
                    "
                    :size="
                      request &&
                      request.second_step_information_file &&
                      request.second_step_information_file.size
                    "
                  />
                </div>
                <div
                  class="request-view-col"
                  v-for="(file, index) in request && request.second_step_files"
                  :key="index"
                >
                  <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                  <fileView
                    :name="file && file.attachment && file.attachment.name"
                    :extension="
                      file && file.attachment && file.attachment.extension
                    "
                    :file_url="file && file.attachment && file.attachment.url"
                    :size="file && file.attachment && file.attachment.size"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-btns">
          <b-button @click="openModal('reject')" class="mybtn mybtn-red">{{
            $t("Reject")
          }}</b-button>
          <b-button @click="openModal('approve')" class="mybtn mybtn-blue">{{
            $t("Approve")
          }}</b-button>
        </div>
      </div>
    </div>
    <popupsApprove
      v-if="approve"
      :step="getStatusStep(status.key)"
      role="technical"
      :close="close"
    />
    <popupsReject
      v-if="reject"
      :step="getStatusStep(status.key)"
      role="technical"
      :close="close"
    />
    <popupsAnswerInformation
      v-if="rejected || approved"
      :title="title"
      :commentInfo="modalInfo"
      :close="close"
    />
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      request: null,
      status: null,
      statusList: [],
      approve: false,
      reject: false,
      rejected: false,
      approved: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.request = await this.$store.dispatch(
      "technical/fetchAplicationDetail",
      this.$route.params.id
    );
    let status = statusList.find((el) => el.key == this.request.status);
    // console.log(status)
    this.status = status;
    if (this.request.revisioned_information_file) {
      this.request.information_file = this.request.revisioned_information_file;
    }
    if (this.request.revisioned_second_step_information_file) {
      this.request.second_step_information_file =
        this.request.revisioned_second_step_information_file;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("technical", ["fetchAplicationDetail"]),
    openModal(val) {
      this[val] = true;
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    getStatusType(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.type;
    },
    getStatusStep(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.value > 12 ? 2 : 1;
    },
    openFirstModal() {
      let val =
        this.request.application_consideration &&
        this.request.application_consideration.first_step &&
        this.request.application_consideration.first_step.type &&
        this.request.application_consideration.first_step.type.includes(
          "approved"
        )
          ? "approved"
          : "rejected";
      this.title = val;
      this[val] = true;
      this.modalInfo =
        this.request.application_consideration &&
        this.request.application_consideration.first_step;
    },
    close() {
      this.rejected = false;
      this.approved = false;
      this.reject = false;
      this.approve = false;
    },
  },
};
</script>

<style>
</style>