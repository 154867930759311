<template>
  <div class="popup">
    <div class="popup-main popup-main__middle">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("Employee") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <p style="color: red" class="errorTitle" v-if="user_found">
          {{ $t("PasswordExists") }}
        </p>
        <div class="popup-reports-form">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="first_name"
                  >{{ $t("User.Firstname") }} <span class="red">*</span>:</label
                >
                <b-form-input
                  id="first_name"
                  v-model="form.first_name"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="last_name"
                  >{{ $t("User.Lastname") }} <span class="red">*</span>:</label
                >
                <b-form-input
                  id="last_name"
                  v-model="form.last_name"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="middle_name">{{ $t("User.Middlename") }}:</label>
                <b-form-input
                  id="middle_name"
                  v-model="form.middle_name"
                ></b-form-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="username"
                  >{{ $t("User.Username") }} <span class="red">*</span>:</label
                >
                <b-form-input
                  id="username"
                  v-model="form.username"
                  :class="{ invalid: usernameError }"
                  @input="checkUser"
                ></b-form-input>
                <small class="red" v-if="usernameError">{{
                  $t("UsernameExists")
                }}</small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for=""
                  >{{ $t("User.Password") }}
                  <span class="red" v-if="!form.id">*</span>:</label
                >
                <!-- <b-form-input
                        id="password"
                        v-model="form.password"
                        ></b-form-input> -->
                <utilsPasswordInput v-model="form.password" :hasTitle="false" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for=""
                  >{{ $t("FormConfirmPassword") }}
                  <span class="red" v-if="!form.id">*</span>:</label
                >
                <!-- <b-form-input
                        id="confirm_pass"
                        v-model="form.conf_password"
                        ></b-form-input> -->
                <utilsPasswordInput
                  v-model="form.conf_password"
                  :hasTitle="false"
                />
              </div>
            </div>
            <div class="col-md-12">
              <p style="margin-top: -12px;"><small>{{ $t("PasswordInfo") }}</small></p>
            </div>
            <div
              :class="
                this.form.role == 'technical_expert' ? 'col-md-6' : 'col-md-12'
              "
            >
              <div class="form-group">
                <label for=""
                  >{{ $t("EmployeeType") }} <span class="red">*</span></label
                >
                <!-- <b-form-input id="" v-model="program"></b-form-input> -->
                <b-form-select v-model="form.role" :options="roles">
                  <template #first>
                    <b-form-select-option disabled :value="null">{{
                      $t("PleaseSelect")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="col-md-6" v-if="this.form.role == 'technical_expert'">
              <div class="form-group">
                <label for="">{{ $t("Speciality") }}</label>
                <!-- <b-form-input id="" v-model="program"></b-form-input> -->
                <b-form-select
                  v-model="form.speciality"
                  :options="specialityList"
                >
                  <template #first>
                    <b-form-select-option disabled :value="null">{{
                      $t("PleaseSelect")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <!-- <b-button :disabled="!fullFilled" @click="submit" class="mybtn mybtn-green">Сохранить</b-button> -->
            <b-button @click="submit" class="mybtn mybtn-green">{{
              $t("Save")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        middle_name: "",
        password: "",
        conf_password: "",
        username: "",
        role: null,
        speciality: null,
      },
      roles: [
        { value: "admin", text: this.$t("Admin") },
        { value: "staff_reviewer", text: this.$t("EmployeePiu") },
        { value: "director", text: this.$t("Director") },
        { value: "technical_expert", text: this.$t("TechnicalExpert") },
        { value: "international_expert", text: this.$t("InternationalExpert") },
        { value: "leading_international_expert", text: this.$t("LeadingInternationalExpert") },        
        { value: "content_manager", text: this.$t("ContentManager") },
      ],
      user_found: false,
      specialityList: [],
      isLoading: true,
      usernameError: false,
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
      console.log(this.form.role);
      this.detail && this.detail.id && (this.form = this.detail);
      this.form.role = this.$route.query && this.$route.query.role;
      this.specialityList = await this.fetchSpecialityList();
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    fullFilled() {
      let notFilled;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          notFilled = true;
          return;
        }
      });

      return !notFilled;
    },
  },
  methods: {
    ...mapActions("adminEmployees", [
      "createEmployee",
      "updateEmployee",
      "fetchSpecialityList",
    ]),
    async submit() {
      try {
        this.isLoading = true;
        let res =
          this.form && this.form.id
            ? await this.updateEmployee(this.form)
            : await this.createEmployee(this.form);
        !res ? (this.user_found = true) : this.$router.go();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async checkUser(val) {
      if (val) {
        const { is_exists } = await this.$store.dispatch("app/checkUser", {
          username: val,
        });
        is_exists == true
          ? (this.usernameError = true)
          : (this.usernameError = false);
      } else {
        this.usernameError = false;
      }
    },
  },
};
</script>

<style>
</style>