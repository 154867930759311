<template>
  <div class="header">
    <div class="header-title">
      <h2>{{ (title && title[$i18n.locale]) || "" }}</h2>
    </div>

    <div class="header-settings">
      <div class="header-lang">
        <b-dropdown id="dropdown-1" :text="currentLang">
          <b-dropdown-item
            v-for="(lang, index) in langs"
            :key="index"
            @click="changeLang(lang.key)"
            >{{ lang.title }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <appNotifications
        v-if="
          role == 'applicant_physical' ||
          role == 'staff_reviewer' ||
          role == 'director' ||
          role == 'applicant_legal'
        "
        :role="role"
      />
      <!-- <div class="header-profile">
        <b-dropdown id="dropdown-3" text="Аброр Аскаров">
          <b-dropdown-item>First Action</b-dropdown-item>
          <b-dropdown-item>Second Action</b-dropdown-item>
          <b-dropdown-item>Third Action</b-dropdown-item>
        </b-dropdown>
      </div> -->
      <span>{{ $store.state.auth.username }}</span>
      <!-- <i class="icon-navbar"></i> -->
    </div>
  </div>
</template>

<script>
import routeAccessList from "../constants/route_access_list";
export default {
  data() {
    return {
      langs: [
        { title: "Русский", key: "ru" },
        { title: "Engilish", key: "en" },
        { title: "O'zbekcha", key: "uz" },
      ],
      currentLang: this.$i18n.locale,
      title: {},
      notifications: [],
      role: null,
    };
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    locale && (this.currentLang = locale);
    locale && (this.$i18n.locale = locale);
    !locale && localStorage.setItem("locale", "ru");

    // set header title
    let newVal = this.$route;
    this.title = this.titleGenerator(newVal.name);
    const token = localStorage.getItem("access");
    const { user_role } = this.JwtParser(token);
    this.role = user_role;
    console.log(this.role);
  },
  methods: {
    changeLang(val) {
      this.$i18n.locale = val;
      this.currentLang = val;
      localStorage.setItem("locale", val);
      this.$router.go();
    },
    titleGenerator(arg) {
      let res = {};
      Object.keys(routeAccessList).forEach((key) => {
        let routeIndex = routeAccessList[key].routes.findIndex(
          (el1) => el1.name == arg || arg.includes(el1.name)
        );
        if (routeIndex != -1) {
          res = routeAccessList[key].routes[routeIndex].title;
          return;
        }
      });
      return res;
    },
    JwtParser(token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        this.title = this.titleGenerator(newVal.name);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>