import { render, staticRenderFns } from "./TechnicalExpertInfo.vue?vue&type=template&id=4d68a3b2&"
import script from "./TechnicalExpertInfo.vue?vue&type=script&lang=js&"
export * from "./TechnicalExpertInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports