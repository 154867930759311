import axios from 'axios'
// import { getToken } from '@/constants/utils/storage'
import store from '../store'
// import store from '@/store/'
// import Vue from 'vue'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 60000,
    headers: {
        "Content-Type": "application/json"
    },
})

// const isDev = process.env.NODE_ENV !== "production"

axiosInstance.interceptors.request.use(
    (config) => {
        // const storageToken = getToken()
        const storageToken = localStorage.getItem('access')
        if (storageToken) {
            config.headers['Authorization'] = `Bearer ${storageToken}`
        }
        let locale = localStorage.getItem('locale')
        locale && (config.headers['Accept-Language'] = locale)
            // console.log(config)
        return config
    },
    (error) => {
        console.error('request error', error)
        return error
    }
)

function emitCustomEvent(eventName, eventData) {
    document.dispatchEvent(
        new CustomEvent(eventName, {
            detail: eventData
        })
    )
}


function emitRouteName(routeName) {
    emitCustomEvent('d-router-redirect', routeName)
}

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const statusCode = (error.response || {}).status || -1
        const errorProps = error

        if (statusCode == -1) {
            emitCustomEvent('response-unknown-error', statusCode)

            return Promise.reject(errorProps)
        }

        if (statusCode == 401) {
            // unautorization
            store.dispatch('auth/refreshToken')
        }

        if (statusCode == 403) {
            emitRouteName('login')
            return Promise.resolve(errorProps)
        }

        if (statusCode == 500) {
            alert('500 server error')
            return Promise.resolve(errorProps)
        }

        if (statusCode == 400) {
            return Promise.resolve(errorProps)
        }

    }
)

export default axiosInstance