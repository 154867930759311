import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import vSelect from "vue-select";
import { i18n } from "./plugins/VueI18n";
import { registerBaseComponents } from "./utils/autoRegisterComponents";
import helper from "./mixins/helper.js";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import dateFilter from "./filter/dateFilter";
import dateTimeFilter from "./filter/dateTimeFilter";
// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// CSS
import "./assets/fonts/NotoSans/stylesheet.css";
import "./assets/fonts/Inter/stylesheet.css";
import "./assets/fonts/icomoon/style.css";
import "./assets/css/auth.css";
import "./assets/css/toxa.css";
import "./assets/css/main.css";
import "./assets/css/media.css";
import "vue-select/dist/vue-select.css";

registerBaseComponents(Vue);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.mixin(helper);
Vue.component("v-select", vSelect);
Vue.filter("dateFilter", dateFilter);
Vue.filter("dateTimeFilter", dateTimeFilter);
Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,

    render: (h) => h(App),
}).$mount("#app");