<template>
  <div class="sidebar">
    <div class="sidebar-logo">
      <img src="@/assets/images/logo.svg" alt="logo" />
      <span>{{ $t("SidebarLogo") }}</span>
    </div>
    <div class="sidebar-main">
      <!-- <div v-if="false" class="sidebar-menu">
        <router-link to="/cabinet/user/account" class="sidebar-menu__item">
          <i>
            <svg
              width="23"
              height="28"
              viewBox="0 0 23 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ellipse
                cx="11.4386"
                cy="7.70372"
                rx="6.37071"
                ry="6.37071"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.33352 22.9344C1.33181 22.4866 1.43197 22.0442 1.62642 21.6408C2.23665 20.4203 3.95747 19.7735 5.38539 19.4806C6.4152 19.2608 7.45924 19.114 8.50972 19.0412C10.4546 18.8704 12.4107 18.8704 14.3556 19.0412C15.406 19.1148 16.45 19.2616 17.48 19.4806C18.9079 19.7735 20.6287 20.3593 21.2389 21.6408C21.63 22.4632 21.63 23.4179 21.2389 24.2403C20.6287 25.5218 18.9079 26.1076 17.48 26.3883C16.4514 26.6171 15.407 26.7681 14.3556 26.8398C12.7727 26.974 11.1823 26.9985 9.59591 26.9131C9.22978 26.9131 8.87585 26.9131 8.50972 26.8398C7.46234 26.769 6.42192 26.618 5.39759 26.3883C3.95747 26.1076 2.24885 25.5218 1.62642 24.2403C1.43296 23.8322 1.3329 23.3861 1.33352 22.9344Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <span>Личные данные</span>
        </router-link>
        <router-link to="/cabinet/user/request" class="sidebar-menu__item">
          <i>
            <svg
              width="24"
              height="27"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.0456 18.7438H7.84961"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.3238 12.1413H7.84912"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <span>Заявки</span>
        </router-link>
        <router-link to="/cabinet/user/feedback" class="sidebar-menu__item">
          <i>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.0236 12.082L17.3364 16.6611C16.2601 17.5049 14.7513 17.5049 13.675 16.6611L7.93896 12.082"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.18367 4.66699H21.7542C23.5667 4.68732 25.2919 5.45356 26.5278 6.78726C27.7638 8.12096 28.4027 9.9057 28.2958 11.7258V20.4297C28.4027 22.2498 27.7638 24.0345 26.5278 25.3682C25.2919 26.7019 23.5667 27.4681 21.7542 27.4885H9.18367C5.29045 27.4885 2.6665 24.3212 2.6665 20.4297V11.7258C2.6665 7.83426 5.29045 4.66699 9.18367 4.66699Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <span>Обратная связь</span>
        </router-link>
        <router-link to="/cabinet/user/reports" class="sidebar-menu__item">
          <i>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.593 19.0102C26.593 23.7811 23.7816 26.5925 19.0107 26.5925H8.62913C3.84608 26.5925 1.03467 23.7811 1.03467 19.0102V8.61647C1.03467 3.84559 2.78724 1.03418 7.55812 1.03418H10.2235C11.1812 1.03418 12.083 1.48508 12.6576 2.25124L13.8747 3.86993C14.4505 4.63459 15.3515 5.08509 16.3088 5.087H20.0817C24.8647 5.087 26.6295 7.52112 26.6295 12.3894L26.593 19.0102Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.41211 17.3185H20.2034"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <span>Отчеты</span>
        </router-link>
        <router-link to="/cabinet/user/help" class="sidebar-menu__item">
          <i>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 19V18C16.7911 18 17.5645 17.7654 18.2223 17.3259C18.8801 16.8864 19.3928 16.2616 19.6955 15.5307C19.9983 14.7998 20.0775 13.9956 19.9231 13.2196C19.7688 12.4437 19.3878 11.731 18.8284 11.1716C18.269 10.6122 17.5563 10.2312 16.7804 10.0769C16.0044 9.92252 15.2002 10.0017 14.4693 10.3045C13.7384 10.6072 13.1136 11.1199 12.6741 11.7777C12.2346 12.4355 12 13.2089 12 14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 24.25C16.6904 24.25 17.25 23.6904 17.25 23C17.25 22.3096 16.6904 21.75 16 21.75C15.3096 21.75 14.75 22.3096 14.75 23C14.75 23.6904 15.3096 24.25 16 24.25Z"
              />
            </svg>
          </i>
          <span>Помощь</span>
        </router-link>
      </div> -->
      <div class="sidebar-menu">
        <router-link
          v-for="menu in menuList"
          :key="menu.name"
          :to="{ name: menu.name }"
          class="sidebar-menu__item"
        >
          <i v-html="menu.icon"></i>
          <span>{{ menu.title[$i18n.locale] }}</span>
        </router-link>
      </div>
      <span style="cursor: pointer" @click="exit" class="sidebar-menu__item">
        <i>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0211 7.85265V6.60865C18.0211 3.89531 15.8211 1.69531 13.1078 1.69531H6.6078C3.8958 1.69531 1.6958 3.89531 1.6958 6.60865V21.4486C1.6958 24.162 3.8958 26.362 6.6078 26.362H13.1211C15.8265 26.362 18.0211 24.1686 18.0211 21.4633V20.206"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M27.0791 14.028H11.0244"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M23.1748 10.1426L27.0788 14.0292L23.1748 17.9172"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
        <span>{{ $t("Exit") }}</span>
      </span>
    </div>

    <div class="sidebar-footer">
      <span class="dev">{{ $t("Developer") }}: <a href="#">Online Service Group</a></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import routeAccessList from "../constants/route_access_list";
export default {
  data() {
    return {
      menuList: [],
    };
  },
  computed: {
    ...mapState("auth", ["role"]),
  },
  mounted() {
    let menuList = (this.role && routeAccessList[`${this.role}`]) || [];
    this.menuList = [...menuList.routes];
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    exit() {
      this.logout();
      // this.$router.push('/login')
    }
    
  }
};
</script>

<style lang="scss" scoped>
</style>