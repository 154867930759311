<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("Component") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="popup-reports-form">
          <div class="form-group">
            <label for="">{{ $t("PeriodAdmission1Stage") }}</label>
            <div class="row">
              <div class="col-6">
                <b-form-input
                  id=""
                  type="date"
                  v-model="form.start_date"
                  placeholder=""
                ></b-form-input>
              </div>
              <div class="col-6">
                <b-form-input
                  id=""
                  type="date"
                  v-model="form.end_date"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">{{ $t("PeriodAdmission2Stage") }}</label>
            <div class="row">
              <div class="col-6">
                <b-form-input
                  id=""
                  type="date"
                  v-model="form.second_step_start_date"
                  placeholder=""
                ></b-form-input>
              </div>
              <div class="col-6">
                <b-form-input
                  id=""
                  type="date"
                  v-model="form.second_step_end_date"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="">{{ $t("RequestProgramm") }}</label>
            <b-form-select id="" v-model="form.program" :options="allPrograms">
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("selectProgramm")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="form-group">
            <label for="checkbox-1">{{ $t("TableStatus") }}</label>
            <b-form-checkbox
              id="checkbox-1"
              v-model="form.is_active"
              name="is_active"
            >
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>

          <div class="form-group">
            <b-button class="mybtn mybtn-green" @click="submit">{{
              $t("Save")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        start_date: null,
        end_date: null,
        second_step_start_date: null,
        second_step_end_date: null,
        program: null,
        is_active: false,
      },
      allPrograms: [],
      isLoading: false,
    };
  },
  async mounted() {
    try {
      this.isLoading = true;
      const allPrograms = await this.$store.dispatch(
        "adminBoards/fetchAllPrograms"
      );
      this.allPrograms = allPrograms.map((i) => ({
        value: i.id,
        text: i.title,
      }));
      this.detail && this.detail.id && (this.form = this.detail);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions("adminBoards", [
      "createBoard",
      "updateBoard",
      "fetchAllPrograms",
    ]),
    async submit() {
      try {
        this.isLoading = true;
        this.form && this.form.id
          ? await this.updateBoard({ id: this.form.id, form: this.form })
          : await this.createBoard(this.form);
        this.close();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    fullFilled() {
      let notFilled;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          notFilled = true;
          return;
        }
      });

      return !notFilled;
    },
  },
};
</script>

<style>
</style>