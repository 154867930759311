<template>
    <div class="fileview">
        <div class="fileview-icon" :class="extension.slice(1)">
            <img :src="fileType" alt="">
        </div>
        <div class="fileview-info">
            <h4>{{ name }}</h4>
            <span>{{ getSizeFile(size) }}</span>
        </div>
        <div class="fileview-download">
            <a :href="file_url" download="download" target="_blank">{{ $t("download") }}</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default() {
                    return null
                }
            },
            extension: {
                type: String,
                default() {
                    return null
                }
            },
            size: {
                type: [String, Number],
                default() {
                    return null
                }
            },
            file_url: {
                type: String,
                default() {
                    return null
                }
            }
        },
        data() {
            return {
                fileTypes: {
                    pdf: require("@/assets/images/icons/pdf.svg"),
                    word: require("@/assets/images/icons/word.svg"),
                    excel: require("@/assets/images/icons/excel.svg"),
                    default: require("@/assets/images/icons/file.svg"),
                },
            }
        },
        computed: {
            fileType() {
                return (this.extension == ".doc" || this.extension == ".docx") ? this.fileTypes.word : this.extension == ".pdf" ? this.fileTypes.pdf : (this.extension == ".xls" || this.extension == ".xlsx") ? this.fileTypes.excel : this.fileTypes.default
            }
        },
        methods: {
            getSizeFile(val) {
                let newValue = null;
                if (val > 1024 * 1024 * 1024) {
                    newValue = (val / (1024 * 1024 * 1024)).toFixed(3) + " " + "gb";
                } else if (val > 1024 * 1024) {
                    newValue = (val / (1024 * 1024)).toFixed(3) + " " + "mb";
                } else if (val > 1024) {
                    newValue = (val / 1024).toFixed(3) + " " + "kb";
                } else {
                    newValue = val + " " + "bt";
                }
                return newValue;
            },
        }
    }
</script>

<style scoped>
    
</style>