<template>
  <div class="auth-block">
    <div class="auth-block__title">
      <h2>{{ $t("ForgotTitle") }}</h2>
    </div>
    <div class="auth-block__form">
      <div class="form-group">
        <label for="password"
          >{{ $t("Password") }} <span class="red">*</span></label
        >
        <!-- <b-form-input id="password" type="password" v-model="form.password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.password" :hasTitle="false" />
      </div>
      <div class="form-group">
        <label for="confirm_password"
          >{{ $t("FormConfirmPassword") }} <span class="red">*</span></label
        >
        <!-- <b-form-input type="password" id="confirm_password" v-model="form.confirm_password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.confirm_password" :hasTitle="false" />
      </div>
      <div class="form-btns">
        <b-button :disabled="!isFormFilled" variant="primary">{{
          $t("Confirmation")
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      form: {
        password: null,
        confirm_password: null,
      },
    };
  },
  computed: {
    isFormFilled() {
      return this.isPasswordConfirmed;
    },
    isPasswordConfirmed() {
      return (
        this.form.password &&
        this.form.confirm_password &&
        this.form.password == this.form.confirm_password
      );
    },
    password() {
      return this.form.password;
    },
    confirm_password() {
      return this.form.confirm_password;
    },
  },
  watch: {
    password() {
      // console.log(newValue);
    },
    confirm_password() {
      // console.log(newValue);
    },
  },
  methods: {},
};
</script>

<style scoped>
</style>