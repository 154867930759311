<template>
  <div class="feedback-all">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <popupsFeedback
      v-if="isCreate"
      @close="isCreate = false"
      @save="onCreate"
      :submitStatus="submitStatus"
    />
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("FeedbackList") }}</h3>
        <b-button class="mybtn mybtn-blue" @click="isCreate = true">{{
          $t("Create")
        }}</b-button>
      </div>

      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="100px">{{ $t("Theme") }}</th>
              <th width="200px">{{ $t("TableMessageText") }}</th>
              <th width="1%">{{ $t("TableData") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(item, index) of feedbackList"
              :key="item.id"
              :to="'/cabinet/user/feedback/' + item.id"
              tag="tr"
              class="link"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.theme }}</td>
              <td>{{ item.message }}</td>
              <td>{{ item && item.created_at | dateFilter }}</td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      submitStatus: "Ok",
      isCreate: false,
      feedbackList: [],
      isLoading: true,
    };
  },
  async created() {
    this.getFeedbackList();
  },
  methods: {
    ...mapActions("userFeedback", ["fetchFeedbackList", "createFeedback"]),
    async getFeedbackList() {
      try {
        this.isLoading = true;
        this.feedbackList = await this.fetchFeedbackList();
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onCreate(val) {
      try {
        this.isLoading = true;
        this.submitStatus = "pending";
        await this.createFeedback(val);
        this.$toast.success(this.$t("successText"), {
          position: "top-right",
        });
        this.submitStatus = "Ok";
        this.getFeedbackList();
        this.isCreate = false;
      } catch (e) {
        this.$toast.error(this.$t("errorText"), {
          position: "top-right",
        });
        this.submitStatus = "Error";
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
