var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination-controls"},[_c('div',{staticClass:"pagination-limit"},[_c('span',[_vm._v(_vm._s(_vm.$t("numberRows")))]),_c('b-dropdown',{attrs:{"text":_vm.currentLimit}},_vm._l((_vm.limits),function(item,index){return _c('b-dropdown-item',{key:index,on:{"click":function($event){return _vm.changeLimit(item)}}},[_vm._v(_vm._s(item))])}),1)],1),(_vm.count)?_c('div',{staticClass:"pagination-items"},[_c('button',{staticClass:"pagination-item",attrs:{"disabled":!_vm.currentPage || _vm.currentPage == 1},on:{"click":function($event){_vm.currentPage--}}},[_vm._v(" < ")]),_vm._l((_vm.allPages),function(item){return [(
            item == 1 ||
            item == _vm.allPages ||
            (_vm.currentPage - 2 <= item && _vm.currentPage + 2 >= item)
          )?_c('button',{key:item,staticClass:"pagination-item",class:{ active: _vm.currentPage == item },attrs:{"disabled":_vm.currentPage == item},on:{"click":function($event){_vm.currentPage = item}}},[_vm._v(" "+_vm._s(item)+" ")]):(
            (_vm.currentPage + 2 < item && _vm.currentPage + 3 >= item) ||
            (_vm.currentPage - 2 > item && _vm.currentPage - 3 <= item)
          )?_c('button',{key:item,staticClass:"pagination-item",attrs:{"disabled":"ture"}},[_vm._v(" ... ")]):_vm._e()]}),_c('button',{staticClass:"pagination-item",attrs:{"disabled":!_vm.currentPage || _vm.currentPage >= _vm.allPages},on:{"click":function($event){_vm.currentPage++}}},[_vm._v(" > ")])],2):_vm._e()]),_c('div',{staticClass:"pagination-info"},[_c('span',[_vm._v(_vm._s(_vm.$t("Total"))+": "+_vm._s(_vm.count))])])])}
var staticRenderFns = []

export { render, staticRenderFns }