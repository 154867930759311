var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-form"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Firstname"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid:
                _vm.$v.form.first_name.$dirty && !_vm.$v.form.first_name.required,
            },attrs:{"id":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),(_vm.$v.form.first_name.$dirty && !_vm.$v.form.first_name.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterName")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Lastname"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid:
                _vm.$v.form.last_name.$dirty && !_vm.$v.form.last_name.required,
            },attrs:{"id":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),(_vm.$v.form.last_name.$dirty && !_vm.$v.form.last_name.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterLastName")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Middlename")))]),_c('b-form-input',{attrs:{"id":""},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Passport"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid: _vm.$v.form.passport.$dirty && !_vm.$v.form.passport.required,
            },attrs:{"id":""},model:{value:(_vm.form.passport),callback:function ($$v) {_vm.$set(_vm.form, "passport", $$v)},expression:"form.passport"}}),(_vm.$v.form.passport.$dirty && !_vm.$v.form.passport.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterPasport")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.PersonId"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid:
                _vm.$v.form.person_id.$dirty && !_vm.$v.form.person_id.required,
            },attrs:{"id":""},model:{value:(_vm.form.person_id),callback:function ($$v) {_vm.$set(_vm.form, "person_id", $$v)},expression:"form.person_id"}}),(_vm.$v.form.person_id.$dirty && !_vm.$v.form.person_id.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("EnterPinfl")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.INN"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid: _vm.$v.form.inn.$dirty && !_vm.$v.form.inn.required,
            },attrs:{"id":"","type":"number"},model:{value:(_vm.form.inn),callback:function ($$v) {_vm.$set(_vm.form, "inn", $$v)},expression:"form.inn"}}),(_vm.$v.form.inn.$dirty && !_vm.$v.form.inn.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterInn")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.BirthDay"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid: _vm.$v.form.birthday.$dirty && !_vm.$v.form.birthday.required,
            },attrs:{"id":"","type":"date"},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", $$v)},expression:"form.birthday"}}),(_vm.$v.form.birthday.$dirty && !_vm.$v.form.birthday.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterBirthday")))]):_vm._e()],1)])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.PerAddress"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid:
                _vm.$v.form.per_address.$dirty && !_vm.$v.form.per_address.required,
            },attrs:{"id":""},model:{value:(_vm.form.per_address),callback:function ($$v) {_vm.$set(_vm.form, "per_address", $$v)},expression:"form.per_address"}}),(_vm.$v.form.per_address.$dirty && !_vm.$v.form.per_address.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterPerAddress")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Region"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-select',{class:{
              invalid: _vm.$v.form.region.$dirty && !_vm.$v.form.region.required,
            },attrs:{"id":"","options":_vm.allRegions},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t("User.SelectRegion")))])]},proxy:true}]),model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}}),(_vm.$v.form.region.$dirty && !_vm.$v.form.region.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("chooseRegion")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Postcode"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
              invalid: _vm.$v.form.postcode.$dirty && !_vm.$v.form.postcode.required,
            },attrs:{"id":""},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}}),(_vm.$v.form.postcode.$dirty && !_vm.$v.form.postcode.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterPostcode")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Email")))]),_c('b-form-input',{attrs:{"id":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Phone"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('div',{staticClass:"group-phone"},[_c('div',{staticClass:"group-phone__first"},[_vm._v("+998")]),_c('b-form-input',{class:{
                invalid: _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.required,
              },attrs:{"id":"phone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),(_vm.$v.form.phone.$dirty && !_vm.$v.form.phone.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterPhone")))]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.Telegram")))]),_c('b-form-input',{attrs:{"id":""},model:{value:(_vm.form.telegram_account),callback:function ($$v) {_vm.$set(_vm.form, "telegram_account", $$v)},expression:"form.telegram_account"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("User.EditPass")))]),_c('button',{staticClass:"btn mybtn mybtn-outline",on:{"click":function($event){_vm.isOpenNewPassword = true}}},[_vm._v(" "+_vm._s(_vm.$t('User.EditPass2'))+" ")])])])])],1),_c('div',{staticClass:"form-col"},[_c('div',{staticClass:"form-btns"},[_c('b-button',{staticClass:"mybtn mybtn-green",on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)]),(_vm.isOpenNewPassword)?_c('popupsNewPassword',{attrs:{"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }