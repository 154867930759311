export const actions = {
    async createHelp(_, payload) {
        try {
            let res = await this.$axios.post('/admin/help/create/', payload)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async updateHelp(_, { id, form }) {
        try {
            let res = await this.$axios.patch(`/admin/help/update/${id}/`, form)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async fetchList(_, { params }) {
        try {
            let res = await this.$axios.get('/admin/help/list/', { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async fetchDetail(_, { id }) {
        try {
            let res = await this.$axios.get(`/admin/help/update/${id}/`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async deleteHelp(_, payload) {
        try {
            let res = await this.$axios.delete(`/admin/helop/delete/${payload}/`)
            console.log(res)
        } catch (err) {
            return err
        }
    },
}