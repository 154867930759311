<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="request-view">
      <b-row>
        <b-col xl="9">
          <div class="request-view-info">
            <div class="request-view-info-logo">
              <img
                :src="
                  (request && request.program && request.program.icon) ||
                  require('@/assets/images/programs/medal.svg')
                "
                alt="not found"
              />
            </div>
            <div class="request-view-info-text">
              <div class="staff-view-info-header">
                <div class="staff-view-header">
                  <!-- <small
                    :class="
                      status && status.value ? 'text-danger' : 'text-primary'
                    "
                    >{{
                      (status && status.title && status.title[$i18n.locale]) ||
                      ""
                    }}</small> -->
                  <span
                    class="status-text"
                    :class="(status && status.key) || ''"
                    >{{
                      (status && status.title && status.title[$i18n.locale]) ||
                      ""
                    }}</span
                  >
                  <!-- <p><span>ID:</span>REP234</p> -->
                  <p><span>ID:</span>{{ request && request.reg_number }}</p>
                  <p>
                    <span>{{ $t("TableData") }}:</span
                    >{{ (request && request.created_at) || "" | dateDay }}
                  </p>
                </div>

                <div class="request-button">
                  <!-- <button @click="Req">Данные заявителя</button> -->
                </div>
              </div>
              <div class="request-view-info-body">
                <p>
                  <span>{{ $t("RequestProgramm") }}:</span>
                  {{
                    (request && request.program && request.program.title) || ""
                  }}
                </p>
                <p>
                  <span>{{ $t("RequestName") }}:</span>
                  {{ (request && request.title) || "" }}
                </p>
                <!-- <p><span>Название:</span> {{ request && request.title }}</p> -->
              </div>
            </div>
          </div>
          <div class="request-view-wrapper">
            <div class="pages-title">
              <h3>{{ $t("RequestData") }}</h3>
              <span
                v-if="
                  status &&
                  (status.key == 'returned_in_consideration' ||
                    status.key == 'returned_in_consideration_at_second_step')
                "
                class="pages-title__revision"
                >{{ $t("CompletionDate") }}:
                <strong>{{
                  request &&
                  request.revision &&
                  request.revision.revision_expire_date
                    .split("-")
                    .reverse()
                    .join(".")
                }}</strong></span
              >
            </div>
            <div class="request-view-body">
              <div class="request-view-body-wrapper">
                <div
                  class="request-view-body-wrapper-border"
                  style="height: calc(100% - (364px / 2))"
                ></div>
                <div class="request-view-body-item active">
                  <span class="request-view-stage"
                    >1 {{ $t("RequestStep") }}</span
                  >
                  <span class="request-view-circle"></span>

                  <div class="request-view-row">
                    <!-- <div
                      class="request-view-col"
                      v-if="request && request.pasport_file"
                    >
                      <label>{{ $t("RequestPassportProject") }}</label>
                      <fileView
                        :name="
                          request &&
                            request.pasport_file &&
                            request.pasport_file.name
                        "
                        :extension="
                          request &&
                            request.pasport_file &&
                            request.pasport_file.extension
                        "
                        :file_url="
                          request &&
                            request.pasport_file &&
                            request.pasport_file.url
                        "
                        :size="
                          request &&
                            request.pasport_file &&
                            request.pasport_file.size
                        "
                      />
                    </div> -->
                    <div
                      class="request-view-col"
                      v-if="request && request.information_file"
                    >
                      <label>{{ $t("RequestInfoProject") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.information_file &&
                          request.information_file.name
                        "
                        :extension="
                          request &&
                          request.information_file &&
                          request.information_file.extension
                        "
                        :file_url="
                          request &&
                          request.information_file &&
                          request.information_file.url
                        "
                        :size="
                          request &&
                          request.information_file &&
                          request.information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-if="request && request.revisioned_information_file"
                    >
                      <label>{{ $t("modifiedFile") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.name
                        "
                        :extension="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.extension
                        "
                        :file_url="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.url
                        "
                        :size="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-for="(file, index) in request && request.files"
                      :key="index"
                    >
                      <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                      <fileView
                        :name="file && file.attachment && file.attachment.name"
                        :extension="
                          file && file.attachment && file.attachment.extension
                        "
                        :file_url="
                          file && file.attachment && file.attachment.url
                        "
                        :size="file && file.attachment && file.attachment.size"
                      />
                    </div>
                  </div>

                  <div class="request-btns" v-if="request.is_editable">
                    <b-button
                      v-if="
                        (status &&
                          (status.key == 'draft' ||
                            status.key == 'received_application') &&
                          request.program &&
                          request.program.is_active) ||
                        status.key == 'returned_in_consideration' ||
                        request.status == 'revisioned_application'
                      "
                      class="mybtn mybtn-blue"
                      @click="onEdit"
                      >{{ $t("RequestEditApp") }}</b-button
                    >
                    <b-button
                      v-if="
                        status &&
                        status.key == 'draft' &&
                        request.program &&
                        request.program.is_active
                      "
                      class="mybtn mybtn-blue"
                      @click="sendRequest"
                      :disabled="!isCorrect"
                      >{{ $t("RequestApply") }}</b-button
                    >
                    <b-button
                      v-if="status && status.key == 'returned_in_consideration'"
                      class="mybtn mybtn-blue"
                      :disabled="!isCorrect"
                      @click="sendRevisionedRequest"
                      >{{ $t("FinishTheRevision") }}</b-button
                    >
                  </div>
                </div>
                <div
                  class="request-view-body-item"
                  :class="{ active: status && status.value > 11 }"
                >
                  <span class="request-view-stage"
                    >2 {{ $t("RequestStep") }}</span
                  >
                  <span class="request-view-circle"></span>
                  <div
                    class="request-view-row"
                    v-if="
                      request &&
                      (request.second_step_information_file ||
                        (request.second_step_files &&
                          request.second_step_files.length))
                    "
                  >
                    <div
                      class="request-view-col"
                      v-if="request && request.second_step_information_file"
                    >
                      <label>{{ $t("RequestInfoProject") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.name
                        "
                        :extension="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.extension
                        "
                        :file_url="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.url
                        "
                        :size="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-if="
                        request &&
                        request.revisioned_second_step_information_file
                      "
                    >
                      <label>{{ $t("modifiedFile") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.name
                        "
                        :extension="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file
                            .extension
                        "
                        :file_url="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.url
                        "
                        :size="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-for="(file, index) in request &&
                      request.second_step_files"
                      :key="index"
                    >
                      <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                      <fileView
                        :name="file && file.attachment && file.attachment.name"
                        :extension="
                          file && file.attachment && file.attachment.extension
                        "
                        :file_url="
                          file && file.attachment && file.attachment.url
                        "
                        :size="file && file.attachment && file.attachment.size"
                      />
                    </div>
                  </div>
                  <div class="request-view-body-item-info" v-else>
                    <h3>2 {{ $t("RequestStep") }}</h3>
                    <p v-html="$t('RequestInfoStep2')"></p>
                    <router-link
                      v-if="status && status.key == 'first_step_completed'"
                      :to="`/cabinet/user/applications/form2/?id=${
                        request && request.id
                      }`"
                      tag="button"
                      >{{ $t("AddDocument") }}</router-link
                    >
                    <button v-else>{{ $t("AddDocument") }}</button>
                  </div>
                  <div
                    class="request-btns"
                    v-if="
                      request.is_editable &&
                      request &&
                      (request.second_step_information_file ||
                        (request.second_step_files &&
                          request.second_step_files.length))
                    "
                  >
                    <b-button
                      v-if="
                        status &&
                        (status.key == 'first_step_completed' ||
                          status.key == 'received_application_at_second_step' ||
                          status.key ==
                            'returned_in_consideration_at_second_step' ||
                          status.key == 'revisioned_application_at_second_step')
                      "
                      class="mybtn mybtn-blue"
                      @click="onEditSecond"
                      >{{ $t("RequestEditApp") }}</b-button
                    >
                    <b-button
                      v-if="status && status.key == 'first_step_completed'"
                      class="mybtn mybtn-blue"
                      @click="sendRequestSecond"
                      :disabled="!isCorrectSecond"
                      >{{ $t("RequestApply") }}</b-button
                    >
                    <b-button
                      v-if="
                        status &&
                        status.key == 'returned_in_consideration_at_second_step'
                      "
                      :disabled="!isCorrectSecond"
                      class="mybtn mybtn-blue"
                      @click="sendRevisionedRequestSecond"
                      >{{ $t("FinishTheRevision") }}</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col xl="3">
          <div class="request-view__side">
            <div class="history-dots">
              <span
                @click="activeHistoryStep = 1"
                class="history-dot"
                :class="{ active: activeHistoryStep == 1 }"
              ></span>
              <span
                @click="activeHistoryStep = 2"
                class="history-dot"
                :class="{ active: activeHistoryStep == 2 }"
              ></span>
            </div>
            <div class="pages-title">
              <h3>
                {{
                  activeHistoryStep == 1
                    ? $t("RequestStep1")
                    : $t("RequestStep2")
                }}
              </h3>
            </div>
            <ul v-if="activeHistoryStep == 1">
              <li
                class="status-item"
                :class="getStatusType(item.application_status)"
                v-for="item in request &&
                request.status_history &&
                request.status_history.first_step"
                :key="item.id"
              >
                <i
                  :class="'icon-' + getStatusType(item.application_status)"
                ></i>
                <div class="request-view__side-right">
                  <small class="date"
                    >{{ item.created_at | dateDay }} |
                    {{ item.created_at | dateTime }}</small
                  >
                  <p>{{ getStatus(item.application_status) }}</p>
                  <span
                    @click="openShowComment(item || {})"
                    v-if="
                      item.content_object &&
                      item.content_object.id &&
                      (item.content_object.comment ||
                        item.content_object.attachment) &&
                      !(
                        item.content_object &&
                        item.content_object.author &&
                        item.content_object.author.role == 'technical_expert'
                      )
                    "
                    >{{
                      getConsiderationType(
                        item.content_object && item.content_object.type
                      )
                    }}
                    <i class="icon-long_right"></i
                  ></span>
                </div>
              </li>
            </ul>
            <ul v-if="activeHistoryStep == 2">
              <li
                class="status-item"
                :class="getStatusType(item.application_status)"
                v-for="item in request &&
                request.status_history &&
                request.status_history.second_step"
                :key="item.id"
              >
                <i
                  :class="'icon-' + getStatusType(item.application_status)"
                ></i>
                <div class="request-view__side-right">
                  <small class="date"
                    >{{ item.created_at | dateDay }} |
                    {{ item.created_at | dateTime }}</small
                  >
                  <p>{{ getStatus(item.application_status) }}</p>
                  <span
                    @click="openShowComment(item || {})"
                    v-if="
                      item.content_object &&
                      item.content_object.id &&
                      (item.content_object.comment ||
                        item.content_object.attachment) &&
                      !(
                        item.content_object &&
                        item.content_object.author &&
                        item.content_object.author.role == 'technical_expert'
                      )
                    "
                    >{{
                      getConsiderationType(
                        item.content_object && item.content_object.type
                      )
                    }}
                    <i class="icon-long_right"></i
                  ></span>
                </div>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
    <popupsStatusComment
      v-if="showComment"
      :commentInfo="commentInfo"
      :close="closeComment"
    />
  </div>
</template>
<script>
import statusList from "@/constants/request_status_list";
export default {
  data() {
    return {
      request: null,
      status: null,
      statusList: [],
      commentInfo: {},
      showComment: false,
      activeHistoryStep: 1,
      isLoading: false,
    };
  },
  async mounted() {
    await this.fetchDetail();
    this.activeHistoryStep = this.getStatusStep(this.status.key);
    console.log("sss", this.isCorrectSecond);
    console.log(this.request);
    console.log("status", this.status);
  },
  computed: {
    isCorrect() {
      return this.request &&
        this.request.title &&
        // this.request.pasport_file &&
        this.request.information_file &&
        this.request.program &&
        (this.request.status == "returned_in_consideration"
          ? this.request.revisioned_information_file
          : true)
        ? true
        : false;
    },
    isCorrectSecond() {
      return this.request &&
        this.request.second_step_information_file &&
        (this.request.status == "returned_in_consideration_at_second_step"
          ? this.request.revisioned_second_step_information_file
          : true)
        ? true
        : false;
    },
  },
  methods: {
    async fetchDetail() {
      try {
        this.isLoading = true;
        this.request = await this.$store.dispatch(
          "userRequest/fetchAplicationDetail",
          this.$route.params.id
        );
        let status = statusList.find((el) => el.key == this.request.status);
        console.log(status);
        this.statusList = statusList;
        this.status = status;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    onEdit() {
      this.$router.push({
        name: "cabinetUserApplicationsForm",
        query: { id: this.$route.params.id },
      });
    },
    onEditSecond() {
      this.$router.push({
        name: "cabinetUserApplicationsForm2",
        query: { id: this.$route.params.id },
      });
    },
    async sendRequest() {
      try {
        this.isLoading = true;
        let f = new FormData();

        f.append("status", "received_application");
        await this.$axios.patch(
          `/site/application/update/${this.request.id}/`,
          f
        );
        this.$toast.success(this.$t("successText"));
        this.fetchDetail();
      } catch (err) {
        // console.error(err);
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async sendRequestSecond() {
      try {
        this.isLoading = true;
        let f = new FormData();

        f.append("status", "received_application_at_second_step");
        await this.$axios.patch(
          `/site/application/update/${this.request.id}/`,
          f
        );
        this.$toast.success(this.$t("successText"));
        this.fetchDetail();
      } catch (err) {
        // console.error(err);
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async sendRevisionedRequest() {
      try {
        this.isLoading = true;
        let f = new FormData();

        f.append("status", "revisioned_application");
        await this.$axios.patch(
          `/site/application/update/${this.request.id}/`,
          f
        );
        this.$toast.success(this.$t("successText"));
        this.fetchDetail();
      } catch (err) {
        // console.error(err);
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async sendRevisionedRequestSecond() {
      try {
        this.isLoading = true;
        let f = new FormData();

        f.append("status", "revisioned_application_at_second_step");
        await this.$axios.patch(
          `/site/application/update/${this.request.id}/`,
          f
        );
        this.$toast.success(this.$t("successText"));
        this.fetchDetail();
      } catch (err) {
        // console.error(err);
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    getStatusType(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.type;
    },
    getStatusStep(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.value > 12 ? 2 : 1;
    },
    openShowComment(val) {
      this.commentInfo = val;
      this.showComment = true;
    },
    closeComment() {
      this.showComment = false;
    },
    getConsiderationType(val) {
      let text = "";
      if (val.includes("rejected")) {
        text = this.$t("Rejected");
      } else if (val.includes("returned")) {
        text = this.$t("ReturnedRevision");
      } else if (val.includes("approved")) {
        text = this.$t("Approved");
      }
      return text;
    },
    openResponce() {
      this.showComment = true;
    },
  },
  watch: {
    $route() {
      this.fetchDetail();
      this.activeHistoryStep = this.getStatusStep(this.status.key);
    },
  },
};
</script>

<style scoped></style>
