export const actions = {
  async createEmployee(_, payload) {
    try {
      let res = await this.$axios.post("/admin/user/create/", payload);
      if (res && res.data) {
        return true;
      } else {
        false;
      }
    } catch (err) {
      console.log("error", err);
    }
  },
  async updateEmployee(_, payload) {
    try {
      let res = await this.$axios.patch(
        `/admin/user/update/${payload.id}/`,
        payload
      );
      if (res && res.data) {
        return true;
      } else {
        false;
      }
    } catch (err) {
      console.log("error", err);
    }
  },
  async fetchList(_, { params }) {
    try {
      let res = null;
      if (Array.isArray(params.role)) {
        let role = ``;
        params.role.forEach((str, i) => {
          role =
            params.role.length - 1 != i
              ? role + "role=" + str + "&"
              : role + "role=" + str;
        });
        res = await this.$axios.get(`/admin/user/list/?${role}`, {
          params: {
            limit: params.limit,
            offset: params.offset,
          },
        });
      } else {
        res = await this.$axios.get(`/admin/user/list/`, {
          params: {
            limit: params.limit,
            offset: params.offset,
            role: params.role,
          },
        });
      }

      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async fetchDetail(_, { id }) {
    try {
      let res = await this.$axios.get(`/admin/user/update/${id}/`);
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async fetchSpecialityList() {
    try {
      let { data } = await this.$axios.get(`/admin/common/speciality/list/`);
      data = data.map((i) => ({ value: i.id, text: i.title }));
      return data;
    } catch (err) {
      return err;
    }
  },
};
