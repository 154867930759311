<template>
  <div class="popup">
    <div class="popup-main popup-main__small">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("User.EditPass") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form">
          <div class="form-group">
            <label for="password"
              >{{ $t("User.Password") }} <span class="red">*</span></label
            >
            <!-- <b-form-input id="password" type="password" v-model="form.password" placeholder="Введите пароль"></b-form-input> -->
            <utilsPasswordInput v-model="form.password" :hasTitle="false" />
          </div>
          <div class="form-group">
            <label for="confirm_password"
              >{{ $t("FormConfirmPassword") }} <span class="red">*</span></label
            >
            <!-- <b-form-input type="password" id="confirm_password" v-model="form.confirm_password" placeholder="Введите пароль"></b-form-input> -->
            <utilsPasswordInput
              v-model="form.confirm_password"
              :hasTitle="false"
            />
          </div>
          <div class="form-group mb-0">
            <b-button
              @click="submit"
              :disabled="!isFormFilled"
              class="mybtn mybtn-blue"
              >{{ $t("Save") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        password: null,
        confirm_password: null,
      },
      isLoading: false,
    };
  },
  computed: {
    isFormFilled() {
      return this.isPasswordConfirmed;
    },
    isPasswordConfirmed() {
      return (
        this.form.password &&
        this.form.confirm_password &&
        this.form.password == this.form.confirm_password
      );
    },
    password() {
      return this.form.password;
    },
    confirm_password() {
      return this.form.confirm_password;
    },
  },
  watch: {
    password() {
      // console.log(newValue);
    },
    confirm_password() {
      // console.log(newValue);
    },
  },
  methods: {
    async submit() {
      try {
        this.isLoading = true;
        let res = await this.$axios.patch("/site/user/me/update/", this.form);
        if (res && res.response && res.response.status == 400) {
          Object.keys(res.response.data).forEach((i) => {
            throw new Error(res.response.data[i][0]);
          });
        }
        this.close();
        this.$toast.success("Успешно сохранено");
      } catch (err) {
        this.$toast.error(err.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>