export const actions = {
    async fetchPrograms() {
        let { data } = await this.$axios.get("/admin/program/list/");
        data = data.map((i) => ({...i, value: i.id, text: i.title }));
        return data;
    },
    async fetchApplicationList(_, { params }) {
        try {
            let res = await this.$axios.get('/site/application/list/internationalexpert/', { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async fetchApplicationConsdiredList(_, { params }) {
        try {
            let res = await this.$axios.get('/site/application/list/considered/internationalexpert/', { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async fetchAplicationDetail(_, payload) {
        try {
            let res = await this.$axios.get(`/site/application/detail/${payload}/internationalexpert/`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
};