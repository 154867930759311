<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("RequestProgramm") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="popup-reports-form">
          <utilsLangTab @changeLang="onChangeLang" />
          <div class="form-group">
            <label for=""
              >{{ $t("Title") }} (<span class="text-capitalize">{{
                activeLang
              }}</span
              >)</label
            >
            <b-form-input
              id=""
              v-model="form['title_' + activeLang]"
              placeholder=""
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("Prefix") }} (<span class="text-capitalize">{{
                activeLang
              }}</span
              >)</label
            >
            <b-form-input
              id=""
              v-model="form.prefix"
              placeholder=""
            ></b-form-input>
          </div>
          <!-- <div class="form-group">
            <label for="">Passport files</label>
            <fileUploader
              :key="passort_file"
              @getFiles="setPasportFile"
              name="passort_file"
              :files="passort_file"
            />
          </div> -->
          <div class="form-group">
            <label for="">{{ $t("InfoFile") }}</label>
            <fileUploader
              :key="info_file"
              @getFiles="setInfoFile"
              name="info_file"
              :files="info_file"
            />
          </div>
          <div class="form-group">
            <label for="">{{ $t("ProgramIcon") }}</label>
            <input type="file" accept="image/svg+xml" @change="handleFile" />
            <div class="input-image" v-if="image_url">
              <img :src="image_url" />
            </div>
          </div>
          <div class="form-group">
            <b-form-checkbox v-model="form.is_active" class="form-checkbox">
              Is Active</b-form-checkbox
            >
          </div>

          <div class="form-group">
            <b-button @click="submit" class="mybtn mybtn-green">{{
              $t("Save")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      activeLang: "ru",
      form: {
        title_ru: "",
        title_en: "",
        title_uz: "",
        icon: null,
        is_active: true,
        // passport_template_file: null,
        info_template_file: null,
        prefix_ru: "",
        prefix_en: "",
        prefix_uz: "",
      },
      image_url: "",
      passort_file: null,
      info_file: null,
      isLoading: false,
    };
  },
  mounted() {
    this.detail &&
      this.detail.id &&
      ((this.form = this.detail),
      (this.image_url = this.detail.icon),
      (this.info_file = this.detail.info_template_file),
      (this.form.icon = null),
      (this.form.info_template_file = null));
  },
  methods: {
    ...mapActions("adminPrograms", ["createProgram", "updateProgram"]),
    onChangeLang(value) {
      this.activeLang = value;
    },
    async submit() {
      let form = this.form;

      let f = new FormData();
      Object.keys(form).forEach((k) => {
        k != "icon" &&
          k != "info_template_file" &&
          k != "passport_template_file" &&
          f.append(k, form[k]);
        k == "icon" && form[k] && f.append(k, form[k], form[k].name);
        // k == "passport_template_file" && form[k] && f.append(k, form[k], form[k].name);
        k == "info_template_file" &&
          form[k] &&
          f.append(k, form[k], form[k].name);
      });
      console.log("tag", f);
      try {
        this.isLoading = true;
        this.form && this.form.id
          ? await this.updateProgram({ id: this.form.id, form: f })
          : await this.createProgram(f);
        this.close();
        console.log("form", this.form);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    handleFile(e) {
      // console.log(e.target.files[0])
      this.image_url = URL.createObjectURL(e.target.files[0]);
      this.form.icon = e.target.files[0];
    },
    // setPasportFile(f) {
    //   this.form.passport_template_file = f && f.file;
    // },
    setInfoFile(f) {
      this.form.info_template_file = f && f.file;
    },
  },
  computed: {
    fullFilled() {
      let notFilled;
      Object.keys(this.form).forEach((key) => {
        if (!this.form[key]) {
          notFilled = true;
          return;
        }
      });

      return !notFilled;
    },
  },
};
</script>

<style>
</style>