const uz = {
  errorPhone: "Raqamingizni kiriting",
  errorPassword: "Parolni kiriting",
  errorUsername: "Login kiriting",
  Authorization: "Avtorizatsiya",
  NaturalPerson: "Jismoniy shaxs",
  JuridicalPerson: "Yuridik shaxs",
  TypePerson: "Foydalanuvchi turi",
  Username: "Login",
  Telephone: "Telefon",
  Password: "Parol",
  Passport: "Паспорт",
  Login: "Kirish",
  Developer: "Ishlab chiqdi",
  PhoneExists: "Telefon raqami allaqachon mavjud",
  ForgotTitle: "Parolni tiklash",
  UserNotFound: "Foydalanuvchi topilmadi",
  UsernameExists: "Foydalanuvchi nomi allaqachon olingan",
  PasswordExists: "Kiritilgan parol juda qisqa. U kamida 8 ta belgidan iborat bo'lishi shart va faqat raqamlardan iborat bo'lmasligi kerak",
  PasswordInfo: "Parol kamida 8 ta belgidan iborat bo'lishi shart va faqat raqamlardan iborat bo'lmasligi kerak!",

  User: {
    Username: "Login",
    Password: "Parol",
    Firstname: "Ism",
    Lastname: "Familiya",
    Middlename: "Otasining ismi",
    Passport: "Pasport seriyasi va raqami",
    PersonId: "PINFL",
    INN: "STIR",
    BirthDay: "Tug'ilgan sana",
    PerAddress: "Manzil",
    Region: "Viloyat",
    District: "Tuman/Shahar",
    Postcode: "Pochta indeksi",
    Email: "E-Pochta",
    Phone: "Telefon raqam",
    Telegram: "Telegram profil",
    PhoneAdditional: "",
    TemAddress: "Yuridik manzil",
    FormActivity: "Faoliyat shakli",
    Gender: "",
    BirthPlace: "",
    BirthCountry: "",
    Nationality: "",
    Citizenship: "",
    PportIssuePlace: "",
    PportIssueDate: "",
    PportExprDate: "",
    LegalName: "Kompaniya / tashkilot nomi",
    LegalType: "Tashkilot turi",
    LegalInn: "INN",
    ContactPhone: "Tashkilotning aloqa telefon raqami",
    ContactPhone2: "Vakilning telefon raqami",
    Organizations: "tashkilotlar",
    Representative: "vakil",
    WebSiteOrganization: "Tashkilot veb-sayti",
    EditPass: "Parolni tahrirlash",
    EditPass2: "Parolni o'zgartirish",
    SelectRegion: "Viloyatni tanlang",
  },

  Close: "Yopish",
  Save: "Saqlash",
  Create: "Yaratish",
  Admin: "Administrator",
  Add: "Qo'shish",
  Stage: "bosqich",
  Other: "ko'proq",
  Exit: "Chiqish",
  Delete: "O'chirish",
  AddFile: "Fayl qo'shish",
  UploadFile: "Fayl yuklang",
  Theme: "Mavzu",
  Continue: "Davom ettirmoq",
  AddDocument: "Dokument qo'shish",
  PersonalData: "Shaxsiy ma'lumot",
  SidebarLogo: "O'zbekiston milliy innovatsion tizimini modernizatsiya qilish",
  SidebarMenu1: "Shaxsiy ma'lumot",
  SidebarMenu2: "Ilovalar",
  SidebarMenu3: "qayta aloqa",
  SidebarMenu4: "Hisobotlar",
  SidebarMenu5: "Yordam",
  TableMessageText: "Xabar matni",
  TableData: "Ma'lumotlar",
  TableTitle: "Nomi",
  TableDocument: "Dokument",
  TableStatus: "Status",
  TableNewApp: "Yangi ilova",
  TableStartDate: "Boshlanish vaqti",
  TableEndDate: "Tugash muddati",
  FormConfirmPassword: "Parolni tasdiqlash",
  FormRegister: "Ro'yxatga olish",
  FormRegister2: "Ro'yxatdan o'tish",
  FormRegisterLegal: "Yuridik shaxs sifatida ro'yxatdan o'tish",
  FormOrganizationName: "Kompaniya / tashkilot nomi",
  FormCodeFromSMS: "SMS kodi",
  FormGetCode: "SMS -da kodni oling",
  FormSendCodeAfter: "Kodni 10 soniyadan keyin yana yuboring.",
  FormFullName: "Familiyasi ismi otasini ismi",
  FormPassportSerial: "Pasport seriyasi va raqami",
  FormPINFL: "PINFL",
  FormINN: "INN",
  FormBirthday: "Tug'ilgan kun",
  FormAddress: "Ro'yxatdan o'tish manzili",
  FormCountry: "Страна/город",
  FormEmailIndex: "Qishloq shahri",
  FormEmail: "E-mail",
  FormTelNumber: "Telefon raqami",
  FormAccTelegram: "Telegram hisob",
  FeedbackList: "Murojaatlarning ro'yxati",
  HelpSystemTitle: "Tizim haqida",
  HelpSystemText:
    "Bu ichimlikning yorliqlari uchun. Oddiy odam shunday qilishi uchun immunitet tizimidagi og'riqdan qutulish kerak. Buni hovlisida qilmang. Ko'chmas mulkli keng ko'lamli to'shak, chirkin avtomobillar massasi. Yo'q, muxlislar davolanadi va quvnoqlik faqat xushomad qiladi. Bu bitta qatlam, choyshab, piyoz yostig'i bo'lsin. Ba'zida orklar sher tumshug'i majburlanadi, mening muammolarim dono odamlarga muhtoj. Ertaga, xudolar dizel yoqilg'isiga borishni xohlashganda. Hatto Internetdan qo'rqish ham yaxshi. Va ommaviy element - bu multfilm, lekin hech qanday qiziqish yo'q. Azizim, ertaga azob beruvchi bo'lmasligi uyat. Nibh cursus sit quis duis hayotda, lekin iqtisodiy va. Hisob -kitoblar yo'q, mablag 'yo'q va uy vazifasi yo'q.",
  ReportsTitle: "Loyihaning borishi to'g'risida hisobot",
  RequestData: "Ilova ma'lumotlari",
  RequestEditApp: "Arizani tahrirlash",
  RequestPassportProject: "Loyiha pasporti",
  RequestInfoProject: "Loyiha haqida ma'lumot",
  RequestOther: "Boshqa",
  RequestStatusApp: "Ilova holati",
  RequestProgramm: "Dastur",
  RequestDownload: "Shablonni yuklab olish",
  RequestApply: "Arizani yuborish",
  RequestMyApp: "Mening ilovalarim",
  RequestCreateAppeal: "Chiptani yarating",
  RequestStep: "Bosqich",
  Date: "Sana",
  RequestName: "Nomi",
  RequestInfoStep2:
    "Sizning arizangiz ko'rib chiqildi va 2 -bosqichga topshirildi, <br /> kerakli hujjatlarni kiriting",
  RequestStep1: "1 - bosqich holati",
  RequestStep2: "2 - bosqich holati",

  menu: {
    Account: "Account",
    Users: "Users",
    Employees: "Xodimlar",
  },
  enterName: "Ismingizni kiriting",
  enterLastName: "Familiyangizni kiriting",
  enterPasport: "Pasport seriyasi va raqamini kiriting",
  EnterPinfl: "PINFLni kiriting",
  enterInn: "INNni kiriting",
  enterBirthday: "Tug'ilgan kuningizni kiriting",
  enterPerAddress: "Yashash manzilingizni kiriting",
  chooseRegion: "Viloyatni tanlang",
  enterPostcode: "Pochta kodini kiriting",
  enterPhone: "Telefon raqamini kiriting",
  enterLegalName: "Kompaniya/tashkilot nomini kiriting",
  selectTypeOrganization: "Tashkilot turini tanlang",
  enterFullNameDirector: "Direktorning to'liq ismini kiriting",
  enterLegaladdress: "Yuridik manzilni kiriting",
  enterProjectName: "Loyiha nomini kiriting",
  selectProgramm: "Dasturni tanlang",
  uploadTheFile: "Faylni yuklang",
  successText: "Muvaffaqiyatli yuborildi",
  errorText: "Xato",
  enterTheme: "Mavzuni kiriting",
  enterMessageText: "Murojaat matnini kiriting",

  errorLogin: "Login yoki parol xato kiritildi",
  forgot: "Parolni unutdingizmi?",
  successSaved: "Muvaffaqiyatli saqlandi",
  successDelete: "Muvaffaqiyatli o'chirildi",

  download: "Ko'chirish",
  listBoards: "Список досок",
  FIO: "F.I.O",
  FIODirector: "Direktorning to'liq ismi",
  Rejected: "Rad etilgan",
  Approved: "Tasdiqlangan",
  TitleProject: "Loyihaning nomi",
  toBack: "Orqaga",
  Confirmation: "Tasdiqlash",
  EmployeeType: "Xodim turi",
  Speciality: "Mutaxassislik",
  emptyNotificationsList: "Bildirishnomalar ro'yxati bo'sh!",
  Reject: "Rad etish",
  Approve: "Tasdiqlash",
  yes: "ha",
  no: "yo'q",
  confirmRequest: "Ushbu so'rovni chindan ham tasdiqlashni xohlaysizmi?",
  InformationResponse: "Javob haqida ma'lumot",
  time: "Vaqt",
  phone: "Telefon",
  PopupStaffReviewTitle: "Biriktirilgan ekspertlar",
  PopupStaffReviewTitle1: "Kim tasdiqladi va qachon",
  PopupStaffReviewTitle2: "Asosiy mutaxassislar",
  StaffReviewDetailButton: "Arizachining ma'lumotlari",
  toAnswer: "Javob qo'shish",
  Send: "Yuborish",
  applicationsStatistics: "Arizalar statistikasi",
  allApplications: "Barcha ilovalar",
  UnderConsideration: "Kurib chiqilayatgan",
  NotAccepted: "Qabul qilinmagan",
  announcedTheWinner: "Go'lib deb topilgan",
  TotalNumber: "Umumiy soni",
  ViewTheAnswer: "Javobni ko'rish",
  SendRevision: "Qayta ko'rib chiqish uchun yuborish",
  ReturnedRevision: "Qayta ko'rib chiqish uchun yuborilgan",
  ListApplications: "Ilovalar ro'yxati",
  Apply: "Filter",
  Clear: "Filterni tozalash",
  SendForTechnicalExpertise: "Texnik ekspertizaga yuborish",
  SendForAdditionalTechnicalExpertise:
    "Qo'shimcha texnik ekspertizaga yuborish",
  Complete: "Yakunlash",
  CompleteStage1: "1 bosqichni yakunlash",
  CompleteStage2: "2 bosqichni yakunlash",
  SearchByID: "ID bo'yicha qidiruv",
  ApplicationRejected: "Ariza rad etildi",
  CompletionDate: "Qayta ko'rib chiqishni tugatish sanasi",
  FinishTheRevision: "Arizani yuborish",
  Total: "Jami",
  MessageText: "Xabar matni",
  theme: "Mavzu",
  Time: "Vaqt",
  AboutSystem: "Tizim haqida",
  Notifications: "Bildirishnomalar",
  Components: "Komponentlar",
  ListOfComponents: "Komponentlar ro'yxati",
  Nationalities: "Millatlar",
  Countries: "Mamlakatlar",
  Districts: "Tumanlar",
  Specialization: "Mutaxassisligi",
  All: "Hammasi",
  EmployeePiu: "LAOG xodimi",
  TechnicalExpert: "Texnik mutaxassis",
  InternationalExpert: "Xalqaro ekspert",
  LeadingInternationalExpert: "Etakchi xalqaro ekspert",
  Director: "Koordinator",
  ContentManager: "Tarkib menejeri",
  Role: "Rol",
  List: "Ro'yxat",
  ListOfPrograms: "Dasturlar ro'yxati",
  ApplicationForm2: "Ariza shakli (2 bosqich)",
  ShowAll: "Hammasini ko'rsatish",
  SMSCode: "SMS kodi",
  SendTheCodeAgainIn: "Kodni {timer} soniyadan keyin yana yuboring.",
  SendTheCodeAgain: "Kodni yana yuborish",
  WrongSmsCode: "Noto'g'ri SMS kod Iltimos, to'g'ri SMS kodini kiriting",
  AttachedDocument: "Ilova qilingan hujjat",
  AttachDocument: "Hujjatni biriktirish",
  Component: "Komponent",
  PeriodAdmission1Stage: "1-bosqichga qabul qilish muddati",
  PeriodAdmission2Stage: "2-bosqichga qabul qilish muddati",
  Active: "Faol",
  Catalog: "Katalog",
  Title: "Sarlavha",
  Order: "tartib",
  Employee: "Xodim",
  PleaseSelect: "Iltimos tanlang",
  EnterText: "Matn kiriting",
  Content: "Kontent",
  Prefix: "Prefiks",
  InfoFile: "Ma'lumot fayli",
  ProgramIcon: "Dastur belgisi",
  CompleteStep: "{step}-chi bosqichni yakunlang",
  SubmittingForRevision: "Qayta ko'rib chiqish uchun yuborish",
  dateOfExpiration: "Amal qilish muddati",
  mainExpert: "{count}-Asosiy mutaxassis",
  SelectKeyExperts: "Asosiy mutaxassislarni tanlang",
  ReserveExpert: "Zaxira mutaxassisi",
  ChooseReserveExpert: "Zaxira mutaxassisni tanlang",
  PasswordInclude8:
    "Parol raqamlar, harflar va kamida 8 ta belgidan iborat bo'lishi kerak",
  numberRows: "qatorlar soni",
  modifiedFile: "Qayta ishlangan fayl",
};
export default uz;
