export const actions = {
    async fetchListByTabName(_, { tab, params }) {
        try {
            let res = await this.$axios.get(`/admin/common/${tab}/list/`, { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async getDetail(_, { tab, id }) {
        try {
            let res = await this.$axios.get(`/admin/common/${tab}/update/${id}/`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async update(_, { tab, form }) {
        try {
            let res = await this.$axios.patch(`/admin/common/${tab}/update/${form.id}/`, form)
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async deleteDetail(_, { tab, id }) {
        try {
            let res = await this.$axios.delete(`/admin/common/${tab}/delete/${id}/`)
            console.log(res)
        } catch (err) {
            return err
        }
    },
    async createDetail(_, { tab, form }) {
        try {
            let res = await this.$axios.post(`/admin/common/${tab}/create/`, form)
            console.log(res)
        } catch (err) {
            return err
        }
    }
}