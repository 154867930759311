export const actions = {
  async fetchFeedbackList(_, { params }) {
    const { data } = await this.$axios.get("/admin/feedback/list/", {
      params: params,
    });
    return data;
  },
  async fetchFeedbackDetail(_, id) {
    const { data } = await this.$axios.get(`/admin/feedback/detail/${id}/`);
    return data;
  },
};
