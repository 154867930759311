import { getToken as storageToken } from "@/constants/utils/storage";
import router from "@/router";
import routeAccessList from "@/constants/route_access_list";

export const state = () => ({
  access: null,
  refresh: null,
  isReportsPopup: false,
  role: null,
  username: null,
  roleText: null,
});

export const mutations = {
  setState(state, payload) {
    Object.keys(payload).forEach((key) => {
      if (key in state) state[key] = payload[key];
    });
  },
  getPopup(state, payload) {
    state[payload.name] = payload.isActive;
  },
};
export const getters = {
  getReportsPopup: (state) => state.isReportsPopup,
  getUserRole: (state) => state.roleText,
};
export const actions = {
  init({ commit }) {
    const token = storageToken();
    const rtoken = storageToken("refresh");

    const p = {
      access: token || null,
      refresh: rtoken || null,
    };

    commit("setState", p);

    // if (p.access) {
    //     dispatch("setCredentials", {
    //         data: {
    //             access: p.access,
    //             refresh: p.refresh
    //         }
    //     })
    // }

    // if (p.refresh) {
    //     dispatch('refreshToken')
    // }
  },
  // async getToken({ dispatch }, params) {
  //     try {
  //         let res = await this.$axios.get("url for get token", { params })
  //         if (res && res.data && res.data.access && res.data.refresh) {
  //             dispatch("setCredentials", res)
  //         }
  //         return res

  //     } catch (err) {
  //         return err
  //     }
  // },
  setCredentials({ commit }, res) {
    const access_token = res && res.data && res.data.access;
    const token_split = access_token.split(".");
    const fields_str = JSON.parse(atob(token_split[1]));

    commit("setState", (res && res.data) || {});
    localStorage.setItem("tokens", JSON.stringify((res && res.data) || {}));
    localStorage.setItem("whoami", fields_str.access_level);
    localStorage.setItem("user_info", JSON.stringify(fields_str));
    commit("profile/setState", { fields: fields_str }, { root: true });
  },
  async refreshToken({ state, dispatch }) {
    try {
      if (state.refresh) {
        let res = await this.$axios.post("/refresh/", {
          refresh: state.refresh,
        });
        res &&
          res.data &&
          res.data.access &&
          dispatch("smartRouter", {
            access: res.data.access,
            refresh: null,
          });

        router.go();
      } else {
        dispatch("logout");
      }
    } catch (err) {
      console.error(err);
    }
  },
  async login({ dispatch }, payload) {
    try {
      let res = await this.$axios.post("/auth/token/", payload);
      if (res && res.data && res.data.access) {
        let { access, refresh } = res && res.data;
        let routerPath = await dispatch("smartRouter", { access, refresh });
        router.push(routerPath);
        return "logged in";
      } else {
        return true;
      }
    } catch (err) {
      console.error(err);
      return err;
    }
  },
  async registrationStep1(_, { phone, reset }) {
    let res = await this.$axios.post("/site/user/sms-authentification/", {
      phone,
      reset,
    });
    return res && res.data;
  },
  async verifySmsCode(_, { token, sms_code }) {
    let res = await this.$axios.post("/site/user/check/verification/", {
      token,
      sms_code,
    });
    return res && res.data;
  },
  async createAccount({ dispatch }, payload) {
    // let res = await this.$axios.post('/site/user/me/create/', payload)
    // return res && res.data
    let res = await this.$axios.post("/site/user/me/create/", payload);
    let { access_token, refresh_token } = (res && res.data) || {};
    if (res && res.data) {
      let routerPath = await dispatch("smartRouter", {
        access: access_token,
        refresh: refresh_token,
      });
      router.push(routerPath);
      return "every thing is working";
    } else {
      return "username found";
    }
  },
  logout({ state, commit }) {
    commit("setState", {
      access: null,
      refresh: null,
      role: null,
    });
    // localStorage.removeItem("tokens")
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("role");
    // localStorage.removeItem('locale')
    if (
      localStorage.getItem("last_path_name") &&
      localStorage.getItem("last_path_name") == "admin-login"
    ) {
      router.push({
        name: "admin-login",
      });
      return;
    }

    if (
      localStorage.getItem("last_path_name") &&
      localStorage.getItem("last_path_name") == "login"
    ) {
      router.push({
        name: "login",
      });
      return;
    }

    if (
      state.roleText == "applicant_physical" ||
      state.roleText == "applicant_legal"
    ) {
      router.push({
        name: "login",
      });
    } else {
      router.push({
        name: "admin-login",
      });
    }
  },
  async smartRouter({ commit, dispatch }, { access, refresh }) {
    localStorage.setItem("access", access);
    localStorage.setItem("refresh", refresh);
    let { user_role, username } = await dispatch("parseJwt", access);
    commit("setState", {
      access,
      refresh,
      username,
    });
    let userRole = await dispatch("roleDefinder", user_role);
    return (
      userRole &&
      routeAccessList[`${userRole}`] &&
      routeAccessList[`${userRole}`].init
    );
  },
  roleDefinder({ commit }, payload) {
    let role =
      payload == "applicant_physical" || payload == "applicant_legal"
        ? 1
        : payload == "admin"
        ? 2
        : payload == "technical_expert"
        ? 3
        : payload == "staff_reviewer" || payload == "director"
        ? 4
        : payload == "international_expert" ||
          payload == "leading_international_expert"
        ? 5
        : 0;
    commit("setState", { role, roleText: payload });
    localStorage.setItem("role", role);
    return role;
  },
  parseJwt(_, token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },
  async resetPassword(_, payload) {
    try {
      let res = await this.$axios.post("/site/user/reset-password/", payload);
      console.log(res);
    } catch (err) {
      return err;
    }
  },
};
