<template>
  <div>
    <utilsAccount @submit="submit" :accountData="accountData" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      accountData: [
        {
          title: {
            ru: "First name",
            uz: "Имя",
            oz: "Ism",
          },
          field: {
            name: "first_name",
            required: true,
            component: {
              name: "input",
              type: "text",
            },
          },
        },
        {
          title: {
            ru: "Last name",
            uz: "Фамилия",
            oz: "Familiya",
          },
          field: {
            name: "last_name",
            required: true,
            component: {
              name: "input",
              type: "text",
            },
          },
        },
        {
          title: {
            ru: "Middle name",
            uz: "Отчество",
            oz: "Otasining ismi",
          },
          field: {
            name: "middle_name",
            required: true,
            component: {
              name: "input",
              type: "text",
            },
          },
        },
        {
          title: {
            ru: "Birth date",
            uz: "Дата рождения",
            oz: "Tug'ilgan sana",
          },
          field: {
            name: "dob",
            required: true,
            component: {
              name: "input",
              type: "date",
            },
          },
        },
        {
          title: {
            ru: "E-Mail",
            uz: "E-Mail",
            oz: "E-Mail",
          },
          field: {
            name: "email",
            required: true,
            component: {
              name: "input",
              type: "text",
            },
          },
        },
        {
          title: {
            ru: "Phone number",
            uz: "Телефон",
            oz: "Telefon",
          },
          field: {
            name: "phone",
            required: true,
            component: {
              name: "input",
              type: "phone",
            },
          },
        },
        {
          title: {
            ru: "Region",
            uz: "Область",
            oz: "Viloyat",
          },
          field: {
            name: "region",
            required: true,
            component: {
              name: "select",
              options: [],
            },
          },
        },
      ],
    };
  },
  mounted() {
    this.getDetail();
    this.getRegionList();
  },
  methods: {
    ...mapActions("app", [
      "fetchUserDetail",
      "updateUserDetail",
      "fetchAllRegions",
    ]),
    async getDetail() {
      try {
        let res = await this.fetchUserDetail();
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    },
    async submit(form) {
      try {
        let res = await this.updateUserDetail({ form });
        console.log(res);
      } catch (err) {
        console.error(err);
      }
    },
    async getRegionList() {
      try {
        let res = await this.fetchAllRegions();
        let regionIndex = this.accountData.findIndex(
          (el) => el && el.field && el.field.name == "region"
        );
        this.accountData[regionIndex].field.component.options = res;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
</style>