<template>
  <div class="profile-form">
    <b-row>
      <b-col md="6">
        <div class="form-col">
          <div class="form-group">
            <label for=""
              >{{ $t("User.Firstname") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.first_name"
              :class="{
                invalid:
                  $v.form.first_name.$dirty && !$v.form.first_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.first_name.$dirty && !$v.form.first_name.required"
              >{{ $t("enterName") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Lastname") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.last_name"
              :class="{
                invalid:
                  $v.form.last_name.$dirty && !$v.form.last_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.last_name.$dirty && !$v.form.last_name.required"
              >{{ $t("enterLastName") }}</small
            >
          </div>

          <div class="form-group">
            <label for="">{{ $t("User.Middlename") }}</label>
            <b-form-input id="" v-model="form.middle_name"></b-form-input>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Passport") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.passport"
              :class="{
                invalid: $v.form.passport.$dirty && !$v.form.passport.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.passport.$dirty && !$v.form.passport.required"
              >{{ $t("enterPasport") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.PersonId") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.person_id"
              :class="{
                invalid:
                  $v.form.person_id.$dirty && !$v.form.person_id.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.person_id.$dirty && !$v.form.person_id.required"
              >{{ $t("EnterPinfl") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.INN") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              type="number"
              v-model="form.inn"
              :class="{
                invalid: $v.form.inn.$dirty && !$v.form.inn.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.inn.$dirty && !$v.form.inn.required"
              >{{ $t("enterInn") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.BirthDay") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.birthday"
              type="date"
              :class="{
                invalid: $v.form.birthday.$dirty && !$v.form.birthday.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.birthday.$dirty && !$v.form.birthday.required"
              >{{ $t("enterBirthday") }}</small
            >
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="form-col">
          <div class="form-group">
            <label for=""
              >{{ $t("User.PerAddress") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.per_address"
              :class="{
                invalid:
                  $v.form.per_address.$dirty && !$v.form.per_address.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.per_address.$dirty && !$v.form.per_address.required"
              >{{ $t("enterPerAddress") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Region") }} <span class="red">*</span></label
            >
            <b-form-select
              id=""
              v-model="form.region"
              :options="allRegions"
              :class="{
                invalid: $v.form.region.$dirty && !$v.form.region.required,
              }"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("User.SelectRegion")
                }}</b-form-select-option>
              </template>
            </b-form-select>
            <small
              class="error-text"
              v-if="$v.form.region.$dirty && !$v.form.region.required"
              >{{ $t("chooseRegion") }}</small
            >
          </div>

          <div class="form-group">
            <label for=""
              >{{ $t("User.Postcode") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.postcode"
              :class="{
                invalid: $v.form.postcode.$dirty && !$v.form.postcode.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.postcode.$dirty && !$v.form.postcode.required"
              >{{ $t("enterPostcode") }}</small
            >
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.Email") }}</label>
            <b-form-input id="" v-model="form.email"></b-form-input>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Phone") }} <span class="red">*</span></label
            >
            <div class="group-phone">
              <div class="group-phone__first">+998</div>
              <b-form-input
                id="phone"
                v-model="form.phone"
                :class="{
                  invalid: $v.form.phone.$dirty && !$v.form.phone.required,
                }"
              ></b-form-input>
            </div>
            <small
              class="error-text"
              v-if="$v.form.phone.$dirty && !$v.form.phone.required"
              >{{ $t("enterPhone") }}</small
            >
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.Telegram") }}</label>
            <b-form-input id="" v-model="form.telegram_account"></b-form-input>
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.EditPass") }}</label>
            <button
              @click="isOpenNewPassword = true"
              class="btn mybtn mybtn-outline"
            >
              {{$t('User.EditPass2')}}
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="form-col">
      <div class="form-btns">
        <!-- <b-button class="mybtn mybtn-grey">{{ $t("Close") }}</b-button> -->
        <b-button class="mybtn mybtn-green" @click="onSave">{{
          $t("Save")
        }}</b-button>
      </div>
    </div>
    <popupsNewPassword v-if="isOpenNewPassword" :close="close" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    allRegions: {
      type: Array,
      default() {
        return [];
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        person_id: "",
        inn: "",
        email: "",
        passport: "",
        sess_id: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone: "",
        phone_additional: "",
        birthday: "",
        per_address: "",
        tem_address: "",
        birth_place: "",
        birth_country: "",
        nationality: "",
        citizenship: "",
        pport_issue_place: "",
        pport_issue_date: "",
        pport_expr_date: "",
        legal_name: "",
        legal_inn: "",
        region: "",
        district: "",
        postcode: "",
        telegram_account: "",
        director_full_name: "",
        organization_phone: "",
        organization_email: "",
        organization_website: "",
        legal_address: "",
        organization_type: null,
        organization_activity: "",
      },
      isOpenNewPassword: false,
    };
  },
  validations: {
    form: {
      person_id: { required },
      inn: { required },
      passport: { required },
      first_name: { required },
      last_name: { required },
      phone: { required },
      birthday: { required },
      per_address: { required },
      region: { required },
      postcode: { required },
    },
  },
  mounted() {
    this.form = this.detail;
    console.log(this.form);
  },
  methods: {
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return;
      }
      this.submitStatus = "PENDING";
      try {
        this.$emit("submit", this.form);
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      this.isOpenNewPassword = false;
    },
  },
};
</script>

<style>
.error-text {
  color: red;
}
</style>