export const APPLICANT_PHYSICAL = 1
export const ADMIN = 2
export const TECHNICAL_EXPERT = 3
export const STAFF_REVIEWER = 4
export const INTERNATIONAL_EXPERT = 5


export const access_levels = [
    1, 2, 3, 4, 5
]

// applicant_physical
// applicant_legal
// staff_distributor
// TECHNICAL_EXPERT
// expert_international
// expert_international_leading
// director
// admin
// content_manager