<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    {{ currentModal }}
    <div class="request-view">
      <b-row>
        <b-col xl="9">
          <div class="request-view-info">
            <div class="request-view-info-logo">
              <img
                :src="
                  (request && request.program && request.program.icon) ||
                  require('@/assets/images/programs/medal.svg')
                "
                alt="not found"
              />
            </div>
            <div class="request-view-info-text">
              <div class="staff-view-info-header">
                <div class="staff-view-header">
                  <!-- <small
                    :class="
                      status && status.value ? 'text-danger' : 'text-primary'
                    "
                    >{{
                      (status && status.title && status.title[$i18n.locale]) ||
                      ""
                    }}</small> -->
                  <span
                    class="status-text"
                    :class="(status && status.key) || ''"
                    >{{
                      (status && status.title && status.title[$i18n.locale]) ||
                      ""
                    }}</span
                  >
                  <!-- <p><span>ID:</span>REP234</p> -->
                  <p><span>ID:</span>{{ request && request.reg_number }}</p>
                  <p>
                    <span>{{ $t("Date") }}:</span
                    >{{ (request && request.created_at) || "" | dateDay }}
                  </p>
                </div>

                <div class="request-button">
                  <button @click="Req" class="t-gray-btn">
                    {{ $t("StaffReviewDetailButton") }}
                  </button>
                </div>
              </div>
              <div class="request-view-info-body">
                <p>
                  <span>{{ $t("RequestProgramm") }}:</span>
                  {{
                    (request && request.program && request.program.title) || ""
                  }}
                </p>
                <p>
                  <span>{{ $t("TableTitle") }}:</span>
                  {{ (request && request.title) || "" }}
                </p>
              </div>
            </div>
          </div>
          <div class="request-view-wrapper">
            <div class="pages-title">
              <h3>{{ $t("RequestData") }}</h3>
            </div>
            <div class="request-view-body">
              <div class="request-view-body-wrapper">
                <div
                  class="request-view-body-wrapper-border"
                  style="height: calc(100% - (364px / 2))"
                ></div>
                <div class="request-view-body-item active">
                  <span class="request-view-stage"
                    >1 {{ $t("RequestStep") }}</span
                  >
                  <span class="request-view-circle"></span>
                  <div
                    class="request-view-body-buttons"
                    v-if="
                      request &&
                      request.technical_experts &&
                      request.technical_experts.length
                    "
                  >
                    <button class="t-gray-btn" @click="Req2('first')">
                      {{ $t("PopupStaffReviewTitle") }}
                    </button>
                  </div>
                  <div class="request-view-row">
                    <div
                      class="request-view-col"
                      v-if="request && request.pasport_file"
                    >
                      <label>{{ $t("RequestPassportProject") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.pasport_file &&
                          request.pasport_file.name
                        "
                        :extension="
                          request &&
                          request.pasport_file &&
                          request.pasport_file.extension
                        "
                        :file_url="
                          request &&
                          request.pasport_file &&
                          request.pasport_file.url
                        "
                        :size="
                          request &&
                          request.pasport_file &&
                          request.pasport_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-if="request && request.information_file"
                    >
                      <label>{{ $t("RequestInfoProject") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.information_file &&
                          request.information_file.name
                        "
                        :extension="
                          request &&
                          request.information_file &&
                          request.information_file.extension
                        "
                        :file_url="
                          request &&
                          request.information_file &&
                          request.information_file.url
                        "
                        :size="
                          request &&
                          request.information_file &&
                          request.information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-if="request && request.revisioned_information_file"
                    >
                      <label>{{ $t("modifiedFile") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.name
                        "
                        :extension="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.extension
                        "
                        :file_url="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.url
                        "
                        :size="
                          request &&
                          request.revisioned_information_file &&
                          request.revisioned_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-for="(file, index) in request && request.files"
                      :key="index"
                    >
                      <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                      <fileView
                        :name="file && file.attachment && file.attachment.name"
                        :extension="
                          file && file.attachment && file.attachment.extension
                        "
                        :file_url="
                          file && file.attachment && file.attachment.url
                        "
                        :size="file && file.attachment && file.attachment.size"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="request-view-body-item"
                  :class="{ active: getStatusStep(status && status.key) == 2 }"
                  v-if="getStatusStep(status && status.key) == 2"
                >
                  <span class="request-view-stage"
                    >2 {{ $t("RequestStep") }}</span
                  >
                  <span class="request-view-circle"></span>
                  <div
                    class="request-view-body-buttons"
                    v-if="
                      request &&
                      request.second_step_technical_experts &&
                      request.second_step_technical_experts.length
                    "
                  >
                    <button class="t-gray-btn" @click="Req2('second')">
                      {{ $t("PopupStaffReviewTitle") }}
                    </button>
                  </div>
                  <div
                    class="request-view-row"
                    v-if="request && request.second_step_information_file"
                  >
                    <div
                      class="request-view-col"
                      v-if="request && request.second_step_information_file"
                    >
                      <label>{{ $t("RequestInfoProject") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.name
                        "
                        :extension="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.extension
                        "
                        :file_url="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.url
                        "
                        :size="
                          request &&
                          request.second_step_information_file &&
                          request.second_step_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-if="request && request.revisioned_second_step_information_file"
                    >
                      <label>{{ $t("modifiedFile") }}</label>
                      <fileView
                        :name="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.name
                        "
                        :extension="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.extension
                        "
                        :file_url="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.url
                        "
                        :size="
                          request &&
                          request.revisioned_second_step_information_file &&
                          request.revisioned_second_step_information_file.size
                        "
                      />
                    </div>
                    <div
                      class="request-view-col"
                      v-for="(file, index) in request &&
                      request.second_step_files"
                      :key="index"
                    >
                      <label>{{ $t("RequestOther") }} {{ index + 1 }}</label>
                      <fileView
                        :name="file && file.attachment && file.attachment.name"
                        :extension="
                          file && file.attachment && file.attachment.extension
                        "
                        :file_url="
                          file && file.attachment && file.attachment.url
                        "
                        :size="file && file.attachment && file.attachment.size"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- First Step Btns -->
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                (status.key == 'received_application' ||
                  status.key == 'returned_in_consideration' || status.key == 'revisioned_application')
              "
            >
              <b-button @click="openModal('reject')" class="mybtn mybtn-red">{{
                $t("Reject")
              }}</b-button>
              <b-button
                @click="openModal('approve')"
                class="mybtn mybtn-blue"
                >{{ $t("Approve") }}</b-button
              >
            </div>
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                status.key == 'approved_in_consideration'
              "
            >
              <b-button
                @click="openModal('sendTechnical')"
                class="mybtn mybtn-blue"
                >{{ $t("SendForTechnicalExpertise") }}</b-button
              >
            </div>
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                status.key == 'waiting_third_technical_expert'
              "
            >
              <b-button
                @click="openModal('sendThirdTechnical')"
                class="mybtn mybtn-blue"
                >{{ $t("SendForAdditionalTechnicalExpertise") }}</b-button
              >
            </div>
            <!-- Second Step Btns -->
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                (status.key == 'received_application_at_second_step' ||
                  status.key == 'returned_in_consideration_at_second_step' || status.key == 'revisioned_application_at_second_step')
              "
            >
              <b-button
                v-if="status.key == 'received_application_at_second_step'"
                @click="openModal('send')"
                class="mybtn mybtn-grey"
                >{{ $t("SendRevision") }}</b-button
              >
              <b-button @click="openModal('reject')" class="mybtn mybtn-red">{{
                $t("Reject")
              }}</b-button>
              <b-button
                @click="openModal('approve')"
                class="mybtn mybtn-blue"
                >{{ $t("Approved") }}</b-button
              >
            </div>
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                status.key == 'approved_in_consideration_at_second_step'
              "
            >
              <b-button
                @click="openModal('sendTechnical')"
                class="mybtn mybtn-blue"
                >{{ $t("SendForTechnicalExpertise") }}</b-button
              >
            </div>
            <div
              class="form-btns"
              v-if="
                isDirector &&
                status &&
                status.key == 'waiting_third_technical_expert_at_second_step'
              "
            >
              <b-button
                @click="openModal('sendThirdTechnical')"
                class="mybtn mybtn-blue"
                >{{ $t("SendForAdditionalTechnicalExpertise") }}</b-button
              >
            </div>
          </div>
        </b-col>
        <b-col xl="3">
          <div class="request-view__side">
            <div class="history-dots">
              <span
                @click="activeHistoryStep = 1"
                class="history-dot"
                :class="{ active: activeHistoryStep == 1 }"
              ></span>
              <span
                @click="activeHistoryStep = 2"
                class="history-dot"
                :class="{ active: activeHistoryStep == 2 }"
              ></span>
            </div>
            <div class="pages-title">
              <h3>
                {{
                  activeHistoryStep == 1
                    ? $t("RequestStep1")
                    : $t("RequestStep2")
                }}
              </h3>
            </div>
            <ul v-if="activeHistoryStep == 1">
              <li
                class="status-item"
                :class="getStatusType(item.application_status)"
                v-for="item in request &&
                request.status_history &&
                request.status_history.first_step"
                :key="item.id"
              >
                <i
                  :class="'icon-' + getStatusType(item.application_status)"
                ></i>
                <div class="request-view__side-right">
                  <small class="date"
                    >{{ item.created_at | dateDay }} |
                    {{ item.created_at | dateTime }}</small
                  >
                  <p>{{ getStatus(item.application_status) }}</p>
                  <span
                    @click="openShowComment(item || {})"
                    v-if="
                      item.content_object &&
                      item.content_object.id &&
                      (item.content_object.comment ||
                        item.content_object.attachment) &&
                      !(
                        item.content_object &&
                        item.content_object.author &&
                        item.content_object.author.role == 'technical_expert'
                      )
                    "
                    >{{
                      getConsiderationType(
                        item.content_object && item.content_object.type
                      )
                    }}
                    <i class="icon-long_right"></i
                  ></span>
                </div>
              </li>
            </ul>
            <ul v-if="activeHistoryStep == 2">
              <li
                class="status-item"
                :class="getStatusType(item.application_status)"
                v-for="item in request &&
                request.status_history &&
                request.status_history.second_step"
                :key="item.id"
              >
                <i
                  :class="'icon-' + getStatusType(item.application_status)"
                ></i>
                <div class="request-view__side-right">
                  <small class="date"
                    >{{ item.created_at | dateDay }} |
                    {{ item.created_at | dateTime }}</small
                  >
                  <p>{{ getStatus(item.application_status) }}</p>
                  <span
                    @click="openShowComment(item || {})"
                    v-if="
                      item.content_object &&
                      item.content_object.id &&
                      (item.content_object.comment ||
                        item.content_object.attachment) &&
                      !(
                        item.content_object &&
                        item.content_object.author &&
                        item.content_object.author.role == 'technical_expert'
                      )
                    "
                    >{{
                      getConsiderationType(
                        item.content_object && item.content_object.type
                      )
                    }}
                    <i class="icon-long_right"></i
                  ></span>
                </div>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </div>
    <popupsRejected v-if="showModal" :close="close" />
    <popupsAuthorApp
      v-if="showModalSecond"
      :close="close"
      :author="request && request.author"
      :regNumber="request && request.reg_number"
      :time="request && request.created_at"
    />
    <popupsTechnicalExpertInfo
      v-if="showModalThird"
      :close="close"
      :experts="experts"
    />
    <reviewerNewApplicationsIdSendModal
      v-if="currentModal == 'send'"
      :step="getStatusStep(status.key)"
      :close="closeModal"
    />
    <reviewerNewApplicationsIdApproveModal
      v-if="currentModal == 'approve'"
      :step="getStatusStep(status.key)"
      :close="closeModal"
    />
    <reviewerNewApplicationsIdRejectModal
      v-if="currentModal == 'reject'"
      :step="getStatusStep(status.key)"
      :close="closeModal"
    />
    <reviewerNewApplicationsIdSendTechnicalModal
      v-if="currentModal == 'sendTechnical'"
      :step="getStatusStep(status.key)"
      :close="closeModal"
    />
    <popupsStatusComment
      v-if="showComment"
      :commentInfo="commentInfo"
      :close="closeComment"
    />
    <reviewerNewApplicationsIdSendThirdTechnicalModal
      v-if="currentModal == 'sendThirdTechnical'"
      :step="getStatusStep(status.key)"
      :experts="propsExperts"
      :close="closeModal"
    />
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      id: null,
      isLoading: true,
      request: null,
      status: null,
      statusList: [],
      showModal: false,
      showModalSecond: false,
      showModalThird: false,
      commentInfo: {},
      showComment: false,
      activeHistoryStep: 1,
      experts: null,
      isDirector: false,
    };
  },
  computed: {
    ...mapGetters("reviewerNewApplicationsId", ["currentModal"]),
    propsExperts() {
      if (this.getStatusStep(this.status.key) == 1) {
        return this.request.technical_experts;
      } else {
        return this.request.second_step_technical_experts;
      }
    },
  },
  mounted() {
    this.getApplication();
    const token = localStorage.getItem("access");
    const user = this.JwtParser(token);
    this.isDirector = user.user_role == "director";
  },
  methods: {
    ...mapMutations("reviewerNewApplicationsId", ["openModal", "closeModal"]),
    async getApplication() {
      this.isLoading = true;
      this.request = await this.$store.dispatch(
        "adminRequests/fetchAplicationDetail",
        this.$route.params.id
      );
      let status = statusList.find((el) => el.key == this.request.status);
      // console.log(status)
      this.statusList = statusList;
      this.status = status;
      console.log("status", this.status);
      this.activeHistoryStep = this.getStatusStep(this.request.status);
      this.isLoading = false;
    },
    Req() {
      //   if (this.statusText == true) {
      //     this.showModal = true;
      //   } else {
      //   }
      this.showModalSecond = true;
    },
    Req2(val) {
      if (val == "first") {
        this.experts = this.request.technical_experts;
      } else {
        this.experts = this.request.second_step_technical_experts;
      }

      this.showModalThird = true;
    },
    close() {
      this.showModal = false;
      this.showModalSecond = false;
      this.showModalThird = false;
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    getStatusType(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.type;
    },
    getStatusStep(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.value > 12 ? 2 : 1;
    },
    openShowComment(val) {
      this.commentInfo = val;
      this.showComment = true;
    },
    closeComment() {
      this.showComment = false;
    },
    getConsiderationType(val) {
      let text = "";
      if (val.includes("rejected")) {
        text = this.$t("Rejected");
      } else if (val.includes("returned")) {
        text = this.$t("ReturnedRevision");
      } else if (val.includes("approved")) {
        text = this.$t("Approved");
      }
      return text;
    },
  },
  watch: {
    $route() {
      this.getApplication();
    },
  },
};
</script>

<style>
</style>