export default {
  methods: {
    parseJwt(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    },
    DMYDate(date) {
      const newDate = new Date(date);
      return `${newDate.getDay()}-${newDate.getMonth() +
        1}-${newDate.getFullYear()}`;
    },
    JwtParser(token) {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    },
  },
};
