import Vue from 'vue'
import VueRouter from 'vue-router'
const routes = require('./routes')
import store from '../store'
// import { access_levels } from '@/constants/access_levels'

function lazyload(path) {
    return () =>
        import (`@/views/${path}`)
}

// console.log("router", ((routes || {}).default || []).map(el => el))

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [{
            path: '/',
            component: () =>
                import ('@/layouts/default'),
            children: ((routes || {}).default || []).map(({ componentFilePath, ...props }) => ({
                ...props,
                component: lazyload(componentFilePath)
            }))
        },
        {
            name: 'login',
            path: '/login',
            component: () =>
                import ('@/layouts/login')
        },
        {
            name: 'admin-login',
            path: '/admin-login',
            component: () =>
                import ('@/layouts/adminLogin')
        },
        {
            name: "noAccess",
            path: "/403",
            component: () =>
                import ("@/layouts/403")
        },
        {
            name: "notFoundPage",
            path: "/404",
            component: () =>
                import ("@/layouts/404")
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "**",
            redirect: "/404"
        },
    ]
})

router.beforeEach(async(to, from, next) => {

    let access = localStorage.getItem('access');
    let refresh = localStorage.getItem('refresh');

    localStorage.setItem('last_path_name', to.name)

    access && refresh && await store.dispatch('auth/smartRouter', {
        access,
        refresh
    })

    const role = store.state.auth.role
    role && store.commit('auth/setState', {
        role: role
    })

    if (to.path == '/') {
        next({ name: 'login' })
        return
    }

    if (to.path != '/login' && to.path != '/admin-login') {
        // if role is not than use is not logged in so go to the login page
        !role && next({ name: 'login' })
            // if role is defined but routing to incorrect path this operation is route this to correct path
        role && to.meta && to.meta.access_level && (to.meta.access_level != role) ? next({ name: 'login' }) : next()
        return
    }

    if (to.path == '/login' && to.path == '/admin-login') {
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
        localStorage.removeItem('role')
            // localStorage.removeItem('locale')
    }



    next()
})



export default router