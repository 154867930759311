<template>
  <div class="profile-form">
    <b-row>
      <b-col md="6">
        <div class="form-col">
          <div class="form-group">
            <label for=""
              >{{ $t("User.LegalName") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.legal_name"
              :class="{
                invalid:
                  $v.form.legal_name.$dirty && !$v.form.legal_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.legal_name.$dirty && !$v.form.legal_name.required"
              >{{ $t("enterLegalName") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.LegalType") }} <span class="red">*</span></label
            >
            <b-form-select
              v-model="form.organization_type"
              :options="allOrgTypes"
              :class="{
                invalid:
                  $v.form.organization_type.$dirty &&
                  !$v.form.organization_type.required,
              }"
            ></b-form-select>
            <small
              class="error-text"
              v-if="
                $v.form.organization_type.$dirty &&
                !$v.form.organization_type.required
              "
              >{{ $t("selectTypeOrganization") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("FIODirector") }}<span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.director_full_name"
              :class="{
                invalid:
                  $v.form.director_full_name.$dirty &&
                  !$v.form.director_full_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="
                $v.form.director_full_name.$dirty &&
                !$v.form.director_full_name.required
              "
              >{{ $t("enterFullNameDirector") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Firstname") }}<span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.first_name"
              :class="{
                invalid:
                  $v.form.first_name.$dirty && !$v.form.first_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.first_name.$dirty && !$v.form.first_name.required"
              >{{ $t("enterName") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.Lastname") }}<span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.last_name"
              :class="{
                invalid:
                  $v.form.last_name.$dirty && !$v.form.last_name.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.last_name.$dirty && !$v.form.last_name.required"
              >{{ $t("enterLastName") }}</small
            >
          </div>

          <div class="form-group">
            <label for="">{{ $t("User.Middlename") }}</label>
            <b-form-input id="" v-model="form.middle_name"></b-form-input>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.INN") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.legal_inn"
              :class="{
                invalid:
                  $v.form.legal_inn.$dirty && !$v.form.legal_inn.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="$v.form.legal_inn.$dirty && !$v.form.legal_inn.required"
              >{{ $t("enterInn") }}</small
            >
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.TemAddress") }} <span class="red">*</span></label
            >
            <b-form-input
              id=""
              v-model="form.legal_address"
              :class="{
                invalid:
                  $v.form.legal_address.$dirty &&
                  !$v.form.legal_address.required,
              }"
            ></b-form-input>
            <small
              class="error-text"
              v-if="
                $v.form.legal_address.$dirty && !$v.form.legal_address.required
              "
              >{{ $t("enterLegaladdress") }}</small
            >
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="form-col">
          <div class="form-group">
            <label for="">{{ $t("User.FormActivity") }} </label>
            <b-form-input
              id=""
              v-model="form.organization_activity"
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.Phone") }} </label>
            <div class="group-phone">
              <div class="group-phone__first">+998</div>
              <b-form-input
                id="phone"
                v-model="form.organization_phone"
              ></b-form-input>
            </div>
          </div>
          <div class="form-group">
            <label for=""
              >{{ $t("User.ContactPhone") }} <span class="red">*</span></label
            >
            <div class="group-phone">
              <div class="group-phone__first">+998</div>
              <b-form-input
                id="phone"
                v-model="form.phone"
                :class="{
                  invalid: $v.form.phone.$dirty && !$v.form.phone.required,
                }"
              ></b-form-input>
            </div>
            <small
              class="error-text"
              v-if="$v.form.phone.$dirty && !$v.form.phone.required"
              >{{ $t("enterPhone") }}</small
            >
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.Email") }}</label>
            <!--  -->
            <b-form-input
              id=""
              v-model="form.organization_email"
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.Email") }} {{ $t("User.Representative") }}</label>
            <b-form-input id="" v-model="form.email"></b-form-input>
          </div>

          <div class="form-group">
            <label for="">{{ $t("FormAccTelegram") }}</label>
            <b-form-input id="" v-model="form.telegram_account"></b-form-input>
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.WebSiteOrganization") }}</label>
            <b-form-input
              id=""
              v-model="form.organization_website"
            ></b-form-input>
          </div>
          <div class="form-group">
            <label for="">{{ $t("User.EditPass") }}</label>
            <button
              @click="isOpenNewPassword = true"
              class="btn mybtn mybtn-outline"
            >
              {{ $t("User.EditPass2") }}
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="form-col">
      <div class="form-btns">
        <!-- <b-button class="mybtn mybtn-grey">Закрыть</b-button> -->
        <b-button class="mybtn mybtn-green" @click="onSave">{{
          $t("Save")
        }}</b-button>
      </div>
    </div>
    <popupsNewPassword v-if="isOpenNewPassword" :close="close" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    allOrgTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    detail: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        person_id: "",
        inn: "",
        email: "",
        passport: "",
        sess_id: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone: "",
        phone_additional: "",
        birthday: "",
        per_address: "",
        tem_address: "",
        birth_place: "",
        birth_country: "",
        nationality: "",
        citizenship: "",
        pport_issue_place: "",
        pport_issue_date: "",
        pport_expr_date: "",
        legal_name: "",
        legal_inn: "",
        region: "",
        district: "",
        postcode: "",
        telegram_account: "",
        director_full_name: "",
        organization_phone: "",
        organization_email: "",
        organization_website: "",
        legal_address: "",
        organization_type: null,
        organization_activity: "",
      },
      organizationTips: [
        {
          value: 1,
          text: "OOO",
        },
      ],
      isOpenNewPassword: false,
    };
  },
  validations: {
    form: {
      legal_name: { required },
      organization_type: { required },
      director_full_name: { required },
      first_name: { required },
      last_name: { required },
      legal_inn: { required },
      legal_address: { required },
      phone: { required },
    },
  },
  mounted() {
    this.form = this.detail;
    console.log(this.form);
  },
  methods: {
    onSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return;
      }
      this.submitStatus = "PENDING";
      try {
        this.$emit("submit", this.form);
      } catch (err) {
        console.error(err);
      }
    },
    close() {
      this.isOpenNewPassword = false;
    },
  },
};
</script>

<style>
</style>