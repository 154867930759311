<template>
  <div class="popup staff-review">
    <div class="popup-main">
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("StaffReviewDetailButton") }}</h3>
        <i class="icon-close" @click="close"></i>
      </div>
      <div class="popup-body">
        <div class="staff-reviewer" v-if="author">
          <!-- <span class="staff-reviewer-mini-title">{{
            $t("PopupStaffReviewTitle1")
          }}</span> -->
          <div class="staff-reviewer-card">
            <h3 class="staff-reviewer-title">
              {{ author && author.last_name || "" }} {{ author && author.first_name || "" }} {{ author && author.middle_name || "" }}
            </h3>
            <!-- 
                NaturalPerson: "Физическое лицо",
    JuridicalPerson: "Юридическое лицо", -->
            <div class="staff-reviewer-contact">
              <span>
                {{ $t("TypePerson") }}:
                {{
                  author.role == "applicant_physical"
                    ? $t("NaturalPerson")
                    : $t("JuridicalPerson")
                }}
              </span>
              <span v-if="author && author.phone"
                >{{ $t("phone") }}: {{ author.phone }}
              </span>
              <span v-if="author && author.email"
                >{{ $t("FormEmail") }}: {{ author.email }}
              </span>
            </div>
            <div class="staff-reviewer-card-bottom">
              <span>ID: {{ regNumber || "" }}</span>
              <span
                >{{ $t("time") }}: {{ time | dateTimeFilter }} /
                {{ time | dateFilter }}</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="staff-review-main" v-if="experts && experts.length">
          <span class="staff-reviewer-mini-title">{{
            $t("PopupStaffReviewTitle2")
          }}</span>
          <div class="row">
            <div class="col-lg-6" v-for="(item, index) in experts" :key="index">
              <div class="staff-review-item">
                <h3 class="staff-reviewer-title">
                  {{
                    item &&
                    item.last_name +
                      " " +
                      item.first_name +
                      " " +
                      item.middle_name
                  }}
                </h3>
                <small v-if="item.speciality">{{
                  item && item.speciality
                }}</small>
                <span v-if="item && item.phone"
                  >{{ $t("phone") }}: {{ item && item.phone }}
                </span>
                <span v-if="item && item.email"
                  >{{ $t("FormEmail") }}: {{ item && item.email }}
                </span>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    close: {
      type: Function,
      default() {
        return {};
      },
    },
    author: {
      type: Object,
      default() {
        return {};
      },
    },
    regNumber: {
      type: String,
      default() {
        return null;
      },
    },
    time: {
      type: String,
      default() {
        return null;
      },
    },
    experts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {},
};
</script>

<style>
</style>