<template>
  <div class="langs-blog">
    <button
      @click="getCurrentLang('ru')"
      class="langs-item"
      :class="{ active: activeLang == 'ru' }"
    >
      <img src="@/assets/images/flags/ru.png" alt="" /> <span>Ru</span>
    </button>
    <button
      @click="getCurrentLang('en')"
      class="langs-item"
      :class="{ active: activeLang == 'en' }"
    >
      <img src="@/assets/images/flags/uk.png" alt="" /> <span>En</span>
    </button>
    <button
      @click="getCurrentLang('uz')"
      class="langs-item"
      :class="{ active: activeLang == 'uz' }"
    >
      <img src="@/assets/images/flags/uz.png" alt="" /> <span>Uz</span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLang: "ru",
    };
  },
  methods: {
    getCurrentLang(value) {
      this.activeLang = value;
      this.$emit("changeLang", value);
    },
  },
};
</script>

<style scoped>
</style>