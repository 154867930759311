export const actions = {
    async fetchAllPrograms() {
        const { data } = await this.$axios.get("/admin/program/list/");
        return data;
    },
    async createBoard(_, payload) {
        try {
            let res = await this.$axios.post('/admin/application/board/create/', payload)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async updateBoard(_, { id, form }) {
        try {
            let res = await this.$axios.patch(`/admin/application/board/update/${id}/`, form)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async fetchList(_, { params }) {
        try {
            let res = await this.$axios.get('/admin/application/board/list/', { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async fetchDetail(_, { id }) {
        try {
            let res = await this.$axios.get(`/admin/application/board/update/${id}/`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async deleteBoard(_, payload) {
        try {
            let res = await this.$axios.delete(`/admin/application/board/delete/${payload}/`)
            console.log(res)
        } catch (err) {
            return err
        }
    },
}