<template>
  <div class="feedback-view">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="feedback-item">
      <div class="pages-title">
        <h3>
          {{ detail && detail.theme }}
        </h3>
        <span class="subinfo">
          {{ $t("Time") }}: {{ detail && detail.created_at | dateTime }} /
          {{ detail && detail.created_at | dateDay }}
        </span>
      </div>
      <div class="feedback-text">
        <p>
          {{ detail && detail.message }}
        </p>
      </div>
      <br />
      <b-row>
        <b-col md="4" v-if="detail && detail.file">
          <fileView
            :name="(detail && detail.file && detail.file.name) || ''"
            :extension="(detail && detail.file && detail.file.extension) || ''"
            :file_url="(detail && detail.file && detail.file.url) || ''"
            :size="(detail && detail.file && detail.file.size) || ''"
          />
        </b-col>
      </b-row>
    </div>
    <div
      class="feedback-answer"
      v-if="detail && detail.answers && detail.answers.length"
    >
      <div class="feedback-item" v-for="item of detail.answers" :key="item.id">
        <div class="pages-title">
          <h3>
            {{ item && item.author && item.author.last_name }}
            {{ item && item.author && item.author.first_name }}
            {{ item && item.author && item.author.middle_name }}
          </h3>
          <span class="subinfo">
            Время: {{ item && item.created_at | dateTime }} /
            {{ item && item.created_at | dateDay }}</span
          >
        </div>
        <div class="feedback-text">
          <h4>{{ $t("theme") }}: {{ item.theme }}</h4>
        </div>
        <div class="feedback-text">
          <p>{{ $t("MessageText") }}: {{ item.message }}</p>
        </div>
        <div class="feedback-file">
          <b-row>
            <b-col md="4" v-if="detail && detail.file">
              <fileView
                :name="(item && item.file && item.file.name) || ''"
                :extension="(item && item.file && item.file.extension) || ''"
                :file_url="(item && item.file && item.file.url) || ''"
                :size="(item && item.file && item.file.size) || ''"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="feedback-button" v-else>
      <b-button variant="primary" @click.prevent="openModal">{{
        $t("toAnswer")
      }}</b-button>
    </div>
    <popupsFeedbackAnswer v-if="isOpenPopup" @close="close" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      detail: null,
      isLoading: true,
      isOpenPopup: false,
    };
  },
  created() {
    this.getFeedback();
  },
  methods: {
    ...mapActions("reviewerFeedback", ["fetchFeedbackDetail"]),
    openModal() {
      this.isOpenPopup = true;
    },
    close() {
      this.isOpenPopup = false;
    },
    async getFeedback() {
      try {
        this.isLoading = true;
        this.detail = await this.fetchFeedbackDetail(this.$route.params.id);
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    $route() {
      this.getFeedback();
    },
  },
};
</script>

<style scoped>
.feedback-button {
  margin-left: 8px;
}
</style>
