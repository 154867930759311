import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/locale/index";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "ru",
  fallbackLocale: "ru",
  messages,
});
