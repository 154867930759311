<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("Approve") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form-group">
          <label>{{ $t("ReserveExpert") }}</label>
          <appSelect
            :options="filteredExperts"
            v-model="form.technical_experts[0]"
            :isId="true"
          />
        </div>
        <div class="form_footer_custom">
          <b-button @click="approveForm" class="mybtn mybtn-blue">{{
            $t("Approve")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    experts: {
      type: [Array, Object],
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      limit: 10,
      offset: 0,
      main_expert_list: [
        {
          text: this.$t("SelectKeyExperts"),
          value: null,
        },
      ],
      reserve_expert_list: [
        {
          text: this.$t("ChooseReserveExpert"),
          value: null,
        },
      ],
      form: {
        technical_experts: [],
      },
    };
  },
  mounted() {
    this.fetchExpertList();
  },
  computed: {
    ...mapGetters("reviewerNewApplicationsId", ["expertList"]),
    filteredExperts() {
      return this.expertList.filter(
        (i) => i.id != this.experts[0].id && i.id != this.experts[1].id
      );
    },
  },
  methods: {
    ...mapActions("reviewerNewApplicationsId", ["action", "fetchExpertList"]),
    async approveForm() {
      const id = this.$route.params.id;
      try {
        this.isLoading = true;
        // const res = await this.$axios.patch(
        //   `/admin/application/${id}/attach/technicalexpert/`,
        //   this.form
        // );
        let form = {};
        let api_url;
        if (this.step == 1) {
          form.technical_experts = this.form.technical_experts;
          api_url = `/admin/application/${id}/attach/technicalexpert/`;
        } else {
          form.second_step_technical_experts = this.form.technical_experts;
          api_url = `/admin/application/${id}/attach/secondstep/technicalexpert/`;
        }
        const res = await this.$axios.patch(api_url, form);
        this.close();
        if (res.status == 200) {
          this.$toast.success(this.$t("successText"));
        }
        this.isLoading = false;
        this.$router.go();
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
  },
  watch: {
    offset(val) {
      console.log(val);
      this.fetchExpertList({ limit: this.limit, offset: val });
    },
  },
};
</script>

<style>
.form_footer_custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>