var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup staff-review"},[_c('div',{staticClass:"popup-main"},[_c('div',{staticClass:"popup-header"},[_c('h3',{staticClass:"popup-title"},[_vm._v(_vm._s(_vm.$t("PopupStaffReviewTitle")))]),_c('i',{staticClass:"icon-close",on:{"click":_vm.close}})]),_c('div',{staticClass:"popup-body"},[(_vm.experts && _vm.experts.length)?_c('div',{staticClass:"staff-review-main"},[_c('span',{staticClass:"staff-reviewer-mini-title"},[_vm._v(_vm._s(_vm.$t("PopupStaffReviewTitle2")))]),_c('div',{staticClass:"row"},_vm._l((_vm.experts),function(item,index){return _c('div',{key:index,staticClass:"col-lg-12"},[_c('div',{staticClass:"staff-review-item"},[_c('h3',{staticClass:"staff-reviewer-title"},[_vm._v(" "+_vm._s(item && item.last_name + " " + item.first_name + " " + item.middle_name)+" ")]),(item.speciality)?_c('small',[_vm._v(_vm._s(item && item.speciality))]):_vm._e(),(item && item.phone)?_c('span',[_vm._v(_vm._s(_vm.$t("phone"))+": "+_vm._s(item && item.phone)+" ")]):_vm._e(),(item && item.email)?_c('span',[_vm._v(_vm._s(_vm.$t("FormEmail"))+": "+_vm._s(item && item.email)+" ")]):_vm._e(),(item && item.application_consideration)?_c('div',{staticClass:"staff-reviewer-app"},[_c('span',{style:(item.application_consideration &&
                    item.application_consideration.type.includes('approved')
                      ? 'color: green'
                      : 'color: red'),on:{"click":function($event){return _vm.toggleCollapse(item.id)}}},[_vm._v(" "+_vm._s(item.application_consideration && item.application_consideration.type.includes("approved") ? _vm.$t("Approved") : _vm.$t("Rejected"))+" "),_c('i',{staticClass:"icon-angle-down"})]),(item.id == _vm.activeElementId)?_c('div',{staticClass:"staff-reviewer-app-wrapper"},[(
                      item.application_consideration &&
                      item.application_consideration.comment
                    )?_c('div',{staticClass:"staff-reviewer-app-comment"},[_c('span',[_vm._v(_vm._s(_vm.$t("TableMessageText")))]),(
                        item.application_consideration &&
                        item.application_consideration.comment
                      )?_c('p',[_vm._v(" "+_vm._s((item.application_consideration && item.application_consideration.comment) || "")+" ")]):_vm._e()]):_vm._e(),(
                      item &&
                      item.application_consideration &&
                      item.application_consideration.attachment
                    )?_c('div',{staticClass:"staff-reviewer-app-file"},[_c('fileView',{attrs:{"name":(item &&
                          item.application_consideration &&
                          item.application_consideration.attachment &&
                          item.application_consideration.attachment.name) ||
                        '',"extension":(item &&
                          item.application_consideration &&
                          item.application_consideration.attachment &&
                          item.application_consideration.attachment
                            .extension) ||
                        '',"file_url":(item &&
                          item.application_consideration &&
                          item.application_consideration.attachment &&
                          item.application_consideration.attachment.size) ||
                        '',"size":(item &&
                          item.application_consideration &&
                          item.application_consideration.attachment &&
                          item.application_consideration.attachment.url) ||
                        ''}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("time"))+": "+_vm._s(_vm._f("dateTime")(item && item.application_consideration && item.application_consideration.created_at))+" / "+_vm._s(_vm._f("dateDay")(item && item.application_consideration && item.application_consideration.created_at))+" ")])]):_vm._e()]):_vm._e()])])}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }