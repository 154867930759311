<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("ListApplications") }}</h3>
        <div class="pages-title__filter d-flex justify-contend-end">
          <div class="form-group">
            <b-input
              v-model="reg_number"
              :placeholder="$t('SearchByID')"
              class="staff-search"
            ></b-input>
          </div>
          <div class="form-group">
            <b-form-select
              class="staff-select"
              v-model="program"
              :options="programs"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("selectProgramm")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <b-button
            class="mybtn mybtn-blue"
            v-if="!isFilter"
            @click="getApplicationListWithFilter"
            >{{ $t("Apply") }}</b-button
          >
          <b-button class="mybtn mybtn-gray" v-else @click="clearFilter">{{
            $t("Clear")
          }}</b-button>
        </div>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="1%">ID</th>
              <th width="100px">{{ $t("TableTitle") }}</th>
              <th width="200px">{{ $t("RequestProgramm") }}</th>
              <th width="1%">{{ $t("Date") }}</th>
              <th width="1%">{{ $t("Stage") }}</th>
              <th width="1%">{{ $t("TableStatus") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              tag="tr"
              :to="`/cabinet/staff-reviewer/new-applications/${item.id}`"
              class="link"
              v-for="item of applicationList"
              :key="item.id"
            >
              <td>{{ item.id }}</td>
              <td>
                <span class="nowrap">{{ item.reg_number }}</span>
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.program || "" }}</td>
              <td>{{ item.created_at | dateDay }}</td>
              <td>
                <span class="nowrap">
                  {{ getStep(item.status) }}
                </span>
              </td>
              <td>
                <span class="status-text" :class="item.status">{{
                  getStatus(item.status)
                }}</span>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
    <utilsPagination
      :limit="limit"
      :page="page"
      :count="count"
      @paginate="paginate"
      @onChangeLimit="onChangeLimit"
    />
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      applicationList: [],
      count: 0,
      limit: 10,
      page: 1,
      reg_number: null,
      program: null,
      programs: [],
      isFilter: false,
    };
  },
  computed: {
    params() {
      let params = {
        reg_number: this.reg_number,
        program: this.program,
        limit: this.limit,
        offset: this.offset,
        status: "received_application",
      };
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
  watch: {
    page() {
      this.getApplicationList();
    },
    reg_number() {
      this.isFilter = false;
    },
    program() {
      this.isFilter = false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getProgramsList();
    await this.getApplicationList();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("reviewer", ["fetchPrograms", "fetchApplicationList"]),
    async getApplicationList() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async getApplicationListWithFilter() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isFilter = true;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async getProgramsList() {
      try {
        let res = await this.fetchPrograms();
        this.programs = res;
      } catch (err) {
        console.error(err);
      }
    },
    getStep(val) {
      let status = statusList.find((el) => el.key == val);
      // console.log(status)
      return status && status.value > 10
        ? "2 -" + this.$t("Stage")
        : "1 -" + this.$t("Stage");
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    paginate(arg) {
      this.page = arg;
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.getApplicationList();
    },
    onChange(val) {
      console.log(val);
      this.isFilter = true;
    },
    clearFilter() {
      this.reg_number = null;
      this.program = null;
      this.getApplicationList();
      this.isFilter = false;
    },
  },
};
</script>

<style></style>
