<template>
  <div class="report">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("ReportsTitle") }}</h3>
        <b-button v-if="false" class="mybtn mybtn-blue" @click="openPopup">{{
          $t("Add")
        }}</b-button>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="136px">ID</th>
              <th width="303px">{{ $t("TableTitle") }}</th>
              <th width="383px">{{ $t("TableDocument") }}</th>
              <th width="150px">{{ $t("TableData") }}</th>
              <th width="200px">{{ $t("TableStatus") }}</th>
            </tr>
          </thead>
          <tbody v-if="false">
            <router-link to="/" tag="tr" class="link">
              <td>1</td>
              <td>REP323</td>
              <td>Молодежный проект</td>
              <td>Отчет июль-август. pdf (1.8 mb)</td>
              <td>28.09.2021</td>
              <td>
                <span class="new-order">{{ $t("TableNewApp") }}</span>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    openPopup() {
      this.$store.commit("auth/getPopup", {
        name: "isReportsPopup",
        isActive: true,
      });
    },
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style>
</style>