<template>
    <div class="preloader">
        <div class="preloader-image">
            <img src="@/assets/images/loader.svg" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .preloader{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;
    }
    .preloader-image{
        
    }
    .preloader-image img{
        width: 90px;
        height: 90px;
    }
</style>