<template>
  <div class="popup">
    <div
      class="popup-main popup-main__comment"
      :class="commentInfo && commentInfo.type"
    >
      <div class="popup-header">
        <h3 class="popup-title" :class="title">
          {{ getTitle(title) }}
        </h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div
          class="popup-body__comment"
          v-if="commentInfo && commentInfo.comment"
        >
          <span>{{ $t("TableMessageText") }}</span>
          <p>
            {{ commentInfo && commentInfo.comment }}
          </p>
        </div>
        <div
          class="popup-body__comment"
          v-if="commentInfo && commentInfo.attachment"
        >
          <span>{{ $t("AttachedDocument") }}</span>
          <div class="row">
            <div class="col-md-6">
              <fileView
                :name="
                  (commentInfo &&
                    commentInfo.attachment &&
                    commentInfo.attachment.name) ||
                  ''
                "
                :extension="
                  (commentInfo &&
                    commentInfo.attachment &&
                    commentInfo.attachment.extension) ||
                  ''
                "
                :file_url="
                  (commentInfo &&
                    commentInfo.attachment &&
                    commentInfo.attachment.url) ||
                  ''
                "
                :size="
                  (commentInfo &&
                    commentInfo.attachment &&
                    commentInfo.attachment.size) ||
                  ''
                "
              />
            </div>
          </div>
        </div>
        <div class="popup-body__comment__footer">
          <span
            >{{ $t("time") }}:
            {{ commentInfo && commentInfo.created_at | dateTime }} /
            {{ commentInfo && commentInfo.created_at | dateDay }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    commentInfo: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    console.log(this.commentInfo);
  },
  data() {
    return {};
  },
  methods: {
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    getStatusType(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.type;
    },
    getTitle(val) {
      if (val == "approved") {
        return this.$t("Approved");
      } else if (val == "rejected") {
        return this.$t("Rejected");
      }
    },
  },
};
</script>

<style></style>
