<template >
  <div class="pages-main">
    <div class="pages-title">
      <h3>{{ $t("Notifications") }}</h3>
    </div>
    <ul class="notifications">
      <router-link
        tag="li"
        :to="getRoute(item)"
        class="notifications__item"
        v-for="item in notifications"
        :key="item.id"
        ><div v-html="item.text"></div
      ></router-link>
    </ul>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      notifications: [],
    };
  },
  async created() {
    this.getNotifications();
  },
  methods: {
    ...mapActions("app", ["fetchNotifications"]),
    async getNotifications() {
      this.notifications = await this.fetchNotifications();
      console.log(this.notifications);
    },
    getRoute(val) {
      if (
        val &&
        val.content_object &&
        val.content_object.application &&
        val.content_object.class_name.includes("Application")
      ) {
        return (
          "/cabinet/user/applications/" + val.content_object.application.id
        );
      } else if (
        val &&
        val.content_object &&
        val.content_object.class_name == "FeedbackAnswer"
      ) {
        return `/cabinet/user/feedback/${
          val.content_object && val.content_object.feedback
        }`;
      } else {
        return "/cabinet/user/notifications";
      }
    },
  },
};
</script>
<style >
</style>