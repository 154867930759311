import Vue from "vue";
Vue.filter("dateDay", function(value) {
    let day = (value += "").split("T");
    return value ?
        (day[0] += "")
        .split("-")
        .reverse()
        .join(".") :
        "";
});

Vue.filter("dateTime", function(value) {
    let time = (value += "").split("T");
    return value ? (time[1] += "").split(":")[0] + ":" + (time[1] += "").split(":")[1] : "";
});

Vue.filter("dateBirthDate", function(value) {
    return value.split("-").reverse().join(".");
});