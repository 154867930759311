<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("FormRegister") }}</h2>
    </div>
    <div class="auth-block__nav">
      <a
         v-if="authForm.applicant_physical_field"
        href="#"
        class="auth-block__nav--item"
        :class="{ active: userType == 'natural' }"
        @click.prevent="userType = 'natural'"
        >{{ $t("NaturalPerson") }}</a
      >
      <a
      v-if="authForm.applicant_legal_field"
        href="#"
        class="auth-block__nav--item"
        :class="{ active: userType == 'legal' }"
        @click.prevent="userType = 'legal'"
        >{{ $t("JuridicalPerson") }}</a
      >
    </div>
    <form @keyup.enter="getSmsCode" class="auth-block__form">
      <div class="form-group">
        <label for="phone"
          >{{ $t("User.Phone") }} <span class="red">*</span></label
        >
        <div class="input-phone">
          <div class="input-phone__first">+998</div>
          <b-form-input
            id="phone"
            maxLength="9"
            v-model="form.phone"
          ></b-form-input>
        </div>
      </div>
      <p v-if="phone_exicted" style="color: red">{{ $t("PhoneExists") }}</p>
      <div class="form-btns">
        <b-button @click="getSmsCode" variant="primary">{{
          $t("FormGetCode")
        }}</b-button>
      </div>
      <span @click="$emit('goBack')" style="color: #2e7df6; cursor: pointer">
        &lt;- {{ $t("toBack") }}</span
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    isLegal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      userType: "natural",
      form: {
        org_name: null,
        phone: null,
      },
      sms_token: null,
      phone_exicted: false,
      authForm: {},
      notTab: false
    };
  },
  mounted() {
    this.userType = this.isLegal ? "legal" : "natural";
    this.$emit('changeUserType', this.userType == 'legal' ? 'applicant_legal' : 'applicant_physical')
    this.getAuthForm()
  },
  methods: {
    ...mapActions('auth', ['registrationStep1']),
    async getAuthForm() {
      let res = await this.$axios.get(`/site/user/authform/`)
      let data = res && res.data
      this.authForm = data || {}
    },
    async getSmsCode() {
      try {
        this.isLoading = true;
        let res = await this.registrationStep1({
          phone: "+998" + this.form.phone,
          reset: false,
        });
        // console.log(res)
        this.sms_token = res && res.token;
        res && res.token
          ? this.$emit("getSmsToken", {
              sms: this.sms_token,
              phone: this.form.phone,
            })
          : (this.phone_exicted = true);
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
  },
  watch: {
    userType(newVal) {
      this.$emit('changeUserType', newVal == 'legal' ? 'applicant_legal' : 'applicant_physical')
    },
    authForm(newVal) {
       newVal.applicant_physical_field ? (this.userType = 'natural') : newVal.applicant_legal_field ? (this.userType = 'legal') : (this.notTab = true)
    }
  }
};
</script>

<style scoped>
</style>