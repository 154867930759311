import camelCase from 'lodash/camelCase';

export const registerBaseComponents = vm => {
    const requireComponents = require.context("../components", true, /\.(vue|js)$/i)
    requireComponents.keys().forEach(fileName => {
        const componentConfig = requireComponents(fileName)
        let componentName = camelCase(fileName.slice().replace(/(\.\/|\.(vue|js))/g, ""))
        componentName = componentName ? componentName.slice().replace(/(index|meta)/gi, "") : componentName

        vm.component(componentName, componentConfig.default || componentConfig)
    })

}