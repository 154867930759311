<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("ListApplications") }}</h3>
        <div class="pages-title__filter d-flex justify-contend-end">
          <div class="form-group">
            <b-input
              v-model="start_date"
              type="date"
              placeholder="Start date"
              class="staff-search"
            ></b-input>
          </div>
          <div class="form-group">
            <b-input
              v-model="end_date"
              type="date"
              placeholder="End date"
              class="staff-search"
            ></b-input>
          </div>
          <div class="form-group">
            <b-form-select
              class="staff-select"
              v-model="program"
              :options="programs"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("selectProgramm")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <b-button
            class="mybtn mybtn-blue"
            v-if="!isFilter"
            @click="getStatisticsWithFilter"
            >{{ $t("Apply") }}</b-button
          >
          <b-button class="mybtn mybtn-gray" v-else @click="clearFilter">{{
            $t("Clear")
          }}</b-button>
        </div>
      </div>
      <div class="pages-body">
        <div class="statistics">
          <b-row>
            <b-col md="4">
              <div class="stats-item">
                <div class="stats-item__item">
                  <strong>{{ $t("allApplications") }}: </strong>
                  <span>{{ statistics.received_applications }}</span>
                </div>
                <div class="stats-item__item view">
                  <strong>{{ $t("UnderConsideration") }}: </strong>
                  <span>{{ statistics.considering_applications }}</span>
                </div>
                <div class="stats-item__item rejected">
                  <strong>{{ $t("NotAccepted") }}: </strong>
                  <span>{{ statistics.rejected_applications }}</span>
                </div>
                <div class="stats-item__item approved">
                  <strong>{{ $t("announcedTheWinner") }}: </strong>
                  <span>{{ statistics.winned_applications }}</span>
                </div>
              </div>
            </b-col>
            <b-col md="8">
              <client-only>
                <vue-highcharts
                  :options="options"
                  ref="pieChart"
                  :key="isLoading"
                ></vue-highcharts>
              </client-only>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueHighcharts from "vue2-highcharts";
export default {
  components: {
    VueHighcharts,
  },
  data() {
    return {
      options: {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
          },
          backgroundColor: "#fff",
        },
        label: {
          connectorAllowed: false,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.$t("applicationsStatistics"),
        },

        colors: ["#2e7df6", "#eb5757 ", "#219653"],

        plotOptions: {
          pie: {
            innerSize: 220,
            depth: 45,
            size: "300",
          },
        },

        series: [
          {
            name: this.$t("TotalNumber"),

            data: [
              [this.$t("UnderConsideration"), 3],
              [this.$t("NotAccepted"), 1],
              [this.$t("announcedTheWinner"), 1],
            ],
            dataLabels: false,
          },
        ],
      },
      isLoading: true,
      start_date: null,
      end_date: null,
      program: null,
      programs: [],
      isFilter: false,
      statistics: null,
    };
  },
  computed: {
    params() {
      const params = {
        program: this.program,
        date_after: this.start_date,
        date_before: this.end_date,
      };
      return params;
    },
  },
  watch: {
    start_date(val) {
      if (val && this.isFilter) this.isFilter = false;
    },
    end_date(val) {
      if (val && this.isFilter) this.isFilter = false;
    },
    program(val) {
      if (val && this.isFilter) this.isFilter = false;
    },
  },
  async mounted() {
    await this.getProgramsList();
    await this.getStatistics(this.params);
  },
  methods: {
    ...mapActions("reviewer", ["fetchPrograms"]),
    ...mapActions("reviewerStatistics", ["fetchStatistics"]),
    async getProgramsList() {
      try {
        let res = await this.fetchPrograms();
        this.programs = res;
      } catch (err) {
        console.error(err);
      }
    },
    async getStatistics() {
      this.isLoading = true;

      try {
        this.statistics = await this.fetchStatistics();
        this.options.series[0].data[0] = [
          this.$t("UnderConsideration"),
          this.statistics.considering_applications,
        ];
        this.options.series[0].data[1] = [
          this.$t("NotAccepted"),
          this.statistics.rejected_applications,
        ];
        this.options.series[0].data[2] = [
          this.$t("announcedTheWinner"),
          this.statistics.winned_applications,
        ];
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getStatisticsWithFilter() {
      this.isLoading = true;
      try {
        this.statistics = await this.fetchStatistics(this.params);
        this.options.series[0].data[0] = [
          this.$t("UnderConsideration"),
          this.statistics.considering_applications,
        ];
        this.options.series[0].data[1] = [
          this.$t("NotAccepted"),
          this.statistics.rejected_applications,
        ];
        this.options.series[0].data[2] = [
          this.$t("announcedTheWinner"),
          this.statistics.winned_applications,
        ];
        this.isFilter = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async clearFilter() {
      this.end_date = null;
      this.start_date = null;
      this.program = null;
      this.getStatistics();
      this.isFilter = false;
    },
  },
};
</script>

<style scoped>
</style>