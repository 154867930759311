import {
  APPLICANT_PHYSICAL,
  ADMIN,
  TECHNICAL_EXPERT,
  STAFF_REVIEWER,
  INTERNATIONAL_EXPERT,
} from "./access_levels";

export default {
  [APPLICANT_PHYSICAL]: {
    init: {
      name: "cabinetUserAccount",
    },
    routes: [
      {
        name: "cabinetUserAccount",
        title: { ru: "Профиль", uz: " Profil", en: "Account" },
        icon: `<svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="11.4386" cy="7.70372" rx="6.37071" ry="6.37071" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33352 22.9344C1.33181 22.4866 1.43197 22.0442 1.62642 21.6408C2.23665 20.4203 3.95747 19.7735 5.38539 19.4806C6.4152 19.2608 7.45924 19.114 8.50972 19.0412C10.4546 18.8704 12.4107 18.8704 14.3556 19.0412C15.406 19.1148 16.45 19.2616 17.48 19.4806C18.9079 19.7735 20.6287 20.3593 21.2389 21.6408C21.63 22.4632 21.63 23.4179 21.2389 24.2403C20.6287 25.5218 18.9079 26.1076 17.48 26.3883C16.4514 26.6171 15.407 26.7681 14.3556 26.8398C12.7727 26.974 11.1823 26.9985 9.59591 26.9131C9.22978 26.9131 8.87585 26.9131 8.50972 26.8398C7.46234 26.769 6.42192 26.618 5.39759 26.3883C3.95747 26.1076 2.24885 25.5218 1.62642 24.2403C1.43296 23.8322 1.3329 23.3861 1.33352 22.9344Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
      },
      {
        name: "cabinetUserApplications",
        title: { ru: "Заявки", uz: " Ilovalar", en: "Applications" },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      {
        name: "cabinetUserFeedback",
        title: {
          ru: "Жалобы и предложения",
          uz: "Shikoyat va takliflar",
          en: "Complaints and suggestions",
        },
        icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.0236 12.082L17.3364 16.6611C16.2601 17.5049 14.7513 17.5049 13.675 16.6611L7.93896 12.082" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18367 4.66699H21.7542C23.5667 4.68732 25.2919 5.45356 26.5278 6.78726C27.7638 8.12096 28.4027 9.9057 28.2958 11.7258V20.4297C28.4027 22.2498 27.7638 24.0345 26.5278 25.3682C25.2919 26.7019 23.5667 27.4681 21.7542 27.4885H9.18367C5.29045 27.4885 2.6665 24.3212 2.6665 20.4297V11.7258C2.6665 7.83426 5.29045 4.66699 9.18367 4.66699Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      // {
      //   name: "cabinetUserReports",
      //   title: { ru: "Отчеты", uz: "Hisobotlar", en: "Reports" },
      //   icon: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      //           <path fill-rule="evenodd" clip-rule="evenodd" d="M26.593 19.0102C26.593 23.7811 23.7816 26.5925 19.0107 26.5925H8.62913C3.84608 26.5925 1.03467 23.7811 1.03467 19.0102V8.61647C1.03467 3.84559 2.78724 1.03418 7.55812 1.03418H10.2235C11.1812 1.03418 12.083 1.48508 12.6576 2.25124L13.8747 3.86993C14.4505 4.63459 15.3515 5.08509 16.3088 5.087H20.0817C24.8647 5.087 26.6295 7.52112 26.6295 12.3894L26.593 19.0102Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //           <path d="M7.41211 17.3185H20.2034" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //         </svg>`,
      // },
      {
        name: "cabinetUserHelp",
        title: { ru: "Помощь", uz: "Yordam", en: "Help" },
        icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 19V18C16.7911 18 17.5645 17.7654 18.2223 17.3259C18.8801 16.8864 19.3928 16.2616 19.6955 15.5307C19.9983 14.7998 20.0775 13.9956 19.9231 13.2196C19.7688 12.4437 19.3878 11.731 18.8284 11.1716C18.269 10.6122 17.5563 10.2312 16.7804 10.0769C16.0044 9.92252 15.2002 10.0017 14.4693 10.3045C13.7384 10.6072 13.1136 11.1199 12.6741 11.7777C12.2346 12.4355 12 13.2089 12 14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 24.25C16.6904 24.25 17.25 23.6904 17.25 23C17.25 22.3096 16.6904 21.75 16 21.75C15.3096 21.75 14.75 22.3096 14.75 23C14.75 23.6904 15.3096 24.25 16 24.25Z"/>
              </svg>`,
      },
    ],
  },
  [ADMIN]: {
    init: {
      name: "cabinetAdminEmployees",
    },
    routes: [
      // {
      //   name: "cabinetAdminAccount",
      //   title: { ru: "Профиль", uz: " Profil", en: "Account" },
      //   icon: `<svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      //     <ellipse cx="11.4386" cy="7.70372" rx="6.37071" ry="6.37071" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //     <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33352 22.9344C1.33181 22.4866 1.43197 22.0442 1.62642 21.6408C2.23665 20.4203 3.95747 19.7735 5.38539 19.4806C6.4152 19.2608 7.45924 19.114 8.50972 19.0412C10.4546 18.8704 12.4107 18.8704 14.3556 19.0412C15.406 19.1148 16.45 19.2616 17.48 19.4806C18.9079 19.7735 20.6287 20.3593 21.2389 21.6408C21.63 22.4632 21.63 23.4179 21.2389 24.2403C20.6287 25.5218 18.9079 26.1076 17.48 26.3883C16.4514 26.6171 15.407 26.7681 14.3556 26.8398C12.7727 26.974 11.1823 26.9985 9.59591 26.9131C9.22978 26.9131 8.87585 26.9131 8.50972 26.8398C7.46234 26.769 6.42192 26.618 5.39759 26.3883C3.95747 26.1076 2.24885 25.5218 1.62642 24.2403C1.43296 23.8322 1.3329 23.3861 1.33352 22.9344Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //   </svg>`,
      // },
      {
        name: "cabinetAdminEmployees",
        title: { ru: "Сотрудники", uz: "Xodimlar", en: "Employees" },
        icon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0195 11.5188C17.8405 11.5188 19.3165 10.0428 19.3165 8.2218C19.3165 6.4018 17.8405 4.9248 16.0195 4.9248" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5361 15.1025C18.0801 15.1395 18.6201 15.2175 19.1531 15.3355C19.8921 15.4825 20.7821 15.7855 21.0981 16.4485C21.3001 16.8735 21.3001 17.3685 21.0981 17.7935C20.7831 18.4565 19.8921 18.7595 19.1531 18.9115" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.591 15.8115C13.281 15.8115 16.433 16.3705 16.433 18.6035C16.433 20.8375 13.301 21.4155 9.591 21.4155C5.901 21.4155 2.75 20.8575 2.75 18.6235C2.75 16.3895 5.881 15.8115 9.591 15.8115Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.59103 12.6245C7.15703 12.6245 5.20703 10.6735 5.20703 8.23947C5.20703 5.80647 7.15703 3.85547 9.59103 3.85547C12.025 3.85547 13.976 5.80647 13.976 8.23947C13.976 10.6735 12.025 12.6245 9.59103 12.6245Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
      },
      {
        name: "cabinetAdminUsers",
        title: { ru: "Пользователи", uz: "Foydalanuvchilar", en: "Users" },
        icon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5947 11.5369C19.1971 11.5369 20.4969 10.238 20.4969 8.63562C20.4969 7.03329 19.1971 5.73438 17.5947 5.73438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.9287 14.6904C19.4072 14.7234 19.883 14.7913 20.3514 14.8967C21.0022 15.0241 21.785 15.2908 22.0637 15.8748C22.2415 16.2488 22.2415 16.6842 22.0637 17.0591C21.786 17.643 21.0022 17.9088 20.3514 18.0427" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.28986 11.5369C4.68753 11.5369 3.3877 10.238 3.3877 8.63562C3.3877 7.03329 4.68753 5.73438 6.28986 5.73438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.95565 14.6904C4.47715 14.7234 4.0014 14.7913 3.53298 14.8967C2.88215 15.0241 2.09931 15.2908 1.82156 15.8748C1.64281 16.2488 1.64281 16.6842 1.82156 17.0591C2.0984 17.643 2.88215 17.9088 3.53298 18.0427" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9377 15.3154C15.1845 15.3154 17.9583 15.8068 17.9583 17.773C17.9583 19.7383 15.2028 20.248 11.9377 20.248C8.68991 20.248 5.91699 19.7567 5.91699 17.7904C5.91699 15.8242 8.67249 15.3154 11.9377 15.3154Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9374 12.5104C9.79602 12.5104 8.0791 10.7935 8.0791 8.65122C8.0791 6.50989 9.79602 4.79297 11.9374 4.79297C14.0787 4.79297 15.7956 6.50989 15.7956 8.65122C15.7956 10.7935 14.0787 12.5104 11.9374 12.5104Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                `,
      },
      {
        name: "cabinetAdminCatalog",
        title: { ru: "Cправочник", uz: "Katalog", en: "Catalog" },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
      },
      {
        name: "cabinetAdminBoards",
        title: { ru: "Доски", uz: "Doskalar", en: "Boards" },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      {
        name: "cabinetAdminPrograms",
        title: { ru: "Программы", uz: "Programmalar", en: "Programs" },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      {
        name: "cabinetAdminHelp",
        title: { ru: "Помощь", uz: "Yordam", en: "Help" },
        icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 19V18C16.7911 18 17.5645 17.7654 18.2223 17.3259C18.8801 16.8864 19.3928 16.2616 19.6955 15.5307C19.9983 14.7998 20.0775 13.9956 19.9231 13.2196C19.7688 12.4437 19.3878 11.731 18.8284 11.1716C18.269 10.6122 17.5563 10.2312 16.7804 10.0769C16.0044 9.92252 15.2002 10.0017 14.4693 10.3045C13.7384 10.6072 13.1136 11.1199 12.6741 11.7777C12.2346 12.4355 12 13.2089 12 14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 24.25C16.6904 24.25 17.25 23.6904 17.25 23C17.25 22.3096 16.6904 21.75 16 21.75C15.3096 21.75 14.75 22.3096 14.75 23C14.75 23.6904 15.3096 24.25 16 24.25Z"/>
                </svg>`,
      },
      /*{
                name: "cabinetAdminFeedback",
                title: { ru: "Обратная связь", uz: "Qayta aloqa", en: "Feedback" },
                icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.0236 12.082L17.3364 16.6611C16.2601 17.5049 14.7513 17.5049 13.675 16.6611L7.93896 12.082" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18367 4.66699H21.7542C23.5667 4.68732 25.2919 5.45356 26.5278 6.78726C27.7638 8.12096 28.4027 9.9057 28.2958 11.7258V20.4297C28.4027 22.2498 27.7638 24.0345 26.5278 25.3682C25.2919 26.7019 23.5667 27.4681 21.7542 27.4885H9.18367C5.29045 27.4885 2.6665 24.3212 2.6665 20.4297V11.7258C2.6665 7.83426 5.29045 4.66699 9.18367 4.66699Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
            },
            {
                name: "cabinetAdminReports",
                title: { ru: "Отчеты", uz: "Hisobotlar", en: "Reports" },
                icon: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M26.593 19.0102C26.593 23.7811 23.7816 26.5925 19.0107 26.5925H8.62913C3.84608 26.5925 1.03467 23.7811 1.03467 19.0102V8.61647C1.03467 3.84559 2.78724 1.03418 7.55812 1.03418H10.2235C11.1812 1.03418 12.083 1.48508 12.6576 2.25124L13.8747 3.86993C14.4505 4.63459 15.3515 5.08509 16.3088 5.087H20.0817C24.8647 5.087 26.6295 7.52112 26.6295 12.3894L26.593 19.0102Z"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.41211 17.3185H20.2034" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`
            },*/
    ],
  },
  [TECHNICAL_EXPERT]: {
    init: {
      name: "cabinetTechnicalExpertAttachedApplications",
    },
    routes: [
      {
        name: "cabinetTechnicalExpertAttachedApplications",
        title: {
          ru: "Прикрепленное заявки",
          uz: "Biriktirilgan ilovalar",
          en: "Attached application",
        },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
      },
      {
        name: "cabinetTechnicalExpertVerifiedApplications",
        title: {
          ru: "Проверенные заявки",
          uz: "Tasdiqlangan ilovalar",
          en: "Verified applications",
        },
        icon: `<svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.71429 3.88889H3.85714C3.09938 3.88889 2.37266 4.19325 1.83684 4.73502C1.30102 5.2768 1 6.0116 1 6.77778V24.1111C1 24.8773 1.30102 25.6121 1.83684 26.1539C2.37266 26.6956 3.09938 27 3.85714 27H18.1429C18.9006 27 19.6273 26.6956 20.1632 26.1539C20.699 25.6121 21 24.8773 21 24.1111V6.77778C21 6.0116 20.699 5.2768 20.1632 4.73502C19.6273 4.19325 18.9006 3.88889 18.1429 3.88889H15.2857M6.71429 3.88889C6.71429 4.65507 7.01531 5.38987 7.55112 5.93164C8.08694 6.47341 8.81367 6.77778 9.57143 6.77778H12.4286C13.1863 6.77778 13.9131 6.47341 14.4489 5.93164C14.9847 5.38987 15.2857 4.65507 15.2857 3.88889M6.71429 3.88889C6.71429 3.12271 7.01531 2.38791 7.55112 1.84614C8.08694 1.30436 8.81367 1 9.57143 1H12.4286C13.1863 1 13.9131 1.30436 14.4489 1.84614C14.9847 2.38791 15.2857 3.12271 15.2857 3.88889M11 14H15.2857M11 19.7778H15.2857M6.71429 14H6.72857M6.71429 19.7778H6.72857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
      },
      // {
      //   name: "cabinetTechnicalExpertHelp",
      //   title: { ru: "Помощь", uz: "Yordam", en: "Help" },
      //   icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      //             <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //             <path d="M16 19V18C16.7911 18 17.5645 17.7654 18.2223 17.3259C18.8801 16.8864 19.3928 16.2616 19.6955 15.5307C19.9983 14.7998 20.0775 13.9956 19.9231 13.2196C19.7688 12.4437 19.3878 11.731 18.8284 11.1716C18.269 10.6122 17.5563 10.2312 16.7804 10.0769C16.0044 9.92252 15.2002 10.0017 14.4693 10.3045C13.7384 10.6072 13.1136 11.1199 12.6741 11.7777C12.2346 12.4355 12 13.2089 12 14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      //             <path d="M16 24.25C16.6904 24.25 17.25 23.6904 17.25 23C17.25 22.3096 16.6904 21.75 16 21.75C15.3096 21.75 14.75 22.3096 14.75 23C14.75 23.6904 15.3096 24.25 16 24.25Z"/>
      //           </svg>`,
      // },
    ],
  },
  [STAFF_REVIEWER]: {
    init: {
      name: "cabinetStaffReviewerNewApplications",
    },
    routes: [
      {
        name: "cabinetStaffReviewerNewApplications",
        title: {
          ru: "Новые заявки",
          uz: "Yangi ilovalar",
          en: "New applications",
        },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      {
        name: "cabinetStaffReviewerVerifiedApplications",
        title: {
          ru: "Проверенные заявки",
          uz: "Tasdiqlangan ilovalar",
          en: "Verified applications",
        },
        icon: `<svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.71429 3.88889H3.85714C3.09938 3.88889 2.37266 4.19325 1.83684 4.73502C1.30102 5.2768 1 6.0116 1 6.77778V24.1111C1 24.8773 1.30102 25.6121 1.83684 26.1539C2.37266 26.6956 3.09938 27 3.85714 27H18.1429C18.9006 27 19.6273 26.6956 20.1632 26.1539C20.699 25.6121 21 24.8773 21 24.1111V6.77778C21 6.0116 20.699 5.2768 20.1632 4.73502C19.6273 4.19325 18.9006 3.88889 18.1429 3.88889H15.2857M6.71429 3.88889C6.71429 4.65507 7.01531 5.38987 7.55112 5.93164C8.08694 6.47341 8.81367 6.77778 9.57143 6.77778H12.4286C13.1863 6.77778 13.9131 6.47341 14.4489 5.93164C14.9847 5.38987 15.2857 4.65507 15.2857 3.88889M6.71429 3.88889C6.71429 3.12271 7.01531 2.38791 7.55112 1.84614C8.08694 1.30436 8.81367 1 9.57143 1H12.4286C13.1863 1 13.9131 1.30436 14.4489 1.84614C14.9847 2.38791 15.2857 3.12271 15.2857 3.88889M11 14H15.2857M11 19.7778H15.2857M6.71429 14H6.72857M6.71429 19.7778H6.72857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`,
      },
      {
        name: "cabinetStaffReviewerFeedback",
        title: {
          ru: "Жалобы и предложения",
          uz: "Shikoyat va takliflar",
          en: "Complaints and suggestions",
        },
        icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.0236 12.082L17.3364 16.6611C16.2601 17.5049 14.7513 17.5049 13.675 16.6611L7.93896 12.082" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.18367 4.66699H21.7542C23.5667 4.68732 25.2919 5.45356 26.5278 6.78726C27.7638 8.12096 28.4027 9.9057 28.2958 11.7258V20.4297C28.4027 22.2498 27.7638 24.0345 26.5278 25.3682C25.2919 26.7019 23.5667 27.4681 21.7542 27.4885H9.18367C5.29045 27.4885 2.6665 24.3212 2.6665 20.4297V11.7258C2.6665 7.83426 5.29045 4.66699 9.18367 4.66699Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
      },
      {
        name: "cabinetStaffReviewerStatistics",
        title: { ru: "Статистика", uz: "Statistika", en: "Statistics" },
        icon: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6667 17.333V15.9997M16 17.333V13.333M21.3333 17.333V10.6663M10.6667 27.9997L16 22.6663L21.3333 27.9997M4 5.33301H28M5.33333 5.33301H26.6667V21.333C26.6667 21.6866 26.5262 22.0258 26.2761 22.2758C26.0261 22.5259 25.687 22.6663 25.3333 22.6663H6.66667C6.31304 22.6663 5.97391 22.5259 5.72386 22.2758C5.47381 22.0258 5.33333 21.6866 5.33333 21.333V5.33301Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            `,
      },
    ],
  },
  [INTERNATIONAL_EXPERT]: {
    init: {
      name: "cabinetInternationalExpertAttachedApplications",
    },
    routes: [
      {
        name: "cabinetInternationalExpertAttachedApplications",
        title: {
          ru: "Прикрепленное заявки",
          uz: "Biriktirilgan ilovalar",
          en: "Attached application",
        },
        icon: `<svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6492 1.68262H6.7785C4.03317 1.68262 1.6665 3.90795 1.6665 6.65462V20.9386C1.6665 23.8399 3.8785 26.1533 6.7785 26.1533H17.4305C20.1772 26.1533 22.4025 23.6866 22.4025 20.9386V8.71728L15.6492 1.68262Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.2988 1.66699V5.54566C15.2988 7.43899 16.8308 8.97499 18.7228 8.97899C20.4788 8.98299 22.2748 8.98433 22.3962 8.97633"  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.0456 18.7438H7.84961" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.3238 12.1413H7.84912" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
      {
        name: "cabinetInternationalExpertVerifiedApplications",
        title: {
          ru: "Проверенные заявки",
          uz: "Tasdiqlangan ilovalar",
          en: "Verified applications",
        },
        icon: `<svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.71429 3.88889H3.85714C3.09938 3.88889 2.37266 4.19325 1.83684 4.73502C1.30102 5.2768 1 6.0116 1 6.77778V24.1111C1 24.8773 1.30102 25.6121 1.83684 26.1539C2.37266 26.6956 3.09938 27 3.85714 27H18.1429C18.9006 27 19.6273 26.6956 20.1632 26.1539C20.699 25.6121 21 24.8773 21 24.1111V6.77778C21 6.0116 20.699 5.2768 20.1632 4.73502C19.6273 4.19325 18.9006 3.88889 18.1429 3.88889H15.2857M6.71429 3.88889C6.71429 4.65507 7.01531 5.38987 7.55112 5.93164C8.08694 6.47341 8.81367 6.77778 9.57143 6.77778H12.4286C13.1863 6.77778 13.9131 6.47341 14.4489 5.93164C14.9847 5.38987 15.2857 4.65507 15.2857 3.88889M6.71429 3.88889C6.71429 3.12271 7.01531 2.38791 7.55112 1.84614C8.08694 1.30436 8.81367 1 9.57143 1H12.4286C13.1863 1 13.9131 1.30436 14.4489 1.84614C14.9847 2.38791 15.2857 3.12271 15.2857 3.88889M11 14H15.2857M11 19.7778H15.2857M6.71429 14H6.72857M6.71429 19.7778H6.72857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>`,
      },
    ],
  },
};
