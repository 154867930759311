<template>
  <div class="popup">
    <div
      class="popup-main popup-main__comment"
      :class="
        commentInfo &&
        commentInfo.content_object &&
        commentInfo.content_object.type
      "
    >
      <div class="popup-header">
        <h3
          class="popup-title"
          :class="
            getClass(
              commentInfo &&
                commentInfo.content_object &&
                commentInfo.content_object.type
            )
          "
        >
          {{ getStatus(commentInfo && commentInfo.application_status) }}
        </h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div
          class="popup-body__comment"
          v-if="
            commentInfo &&
            commentInfo.content_object &&
            commentInfo.content_object.comment
          "
        >
          <span>{{ $t("TableMessageText") }}</span>
          <p>
            {{
              commentInfo &&
              commentInfo.content_object &&
              commentInfo.content_object.comment
            }}
          </p>
        </div>
        <div
          class="popup-body__comment"
          v-if="
            commentInfo &&
            commentInfo.content_object &&
            commentInfo.content_object.attachment
          "
        >
          <span>{{ $t("AttachedDocument") }}</span>
          <div class="row">
            <div class="col-md-6">
              <fileView
                :name="
                  (commentInfo &&
                    commentInfo.content_object &&
                    commentInfo.content_object.attachment &&
                    commentInfo.content_object.attachment.name) ||
                  ''
                "
                :extension="
                  (commentInfo &&
                    commentInfo.content_object &&
                    commentInfo.content_object.attachment &&
                    commentInfo.content_object.attachment.extension) ||
                  ''
                "
                :file_url="
                  (commentInfo &&
                    commentInfo.content_object &&
                    commentInfo.content_object.attachment &&
                    commentInfo.content_object.attachment.url) ||
                  ''
                "
                :size="
                  (commentInfo &&
                    commentInfo.content_object &&
                    commentInfo.content_object.attachment &&
                    commentInfo.content_object.attachment.size) ||
                  ''
                "
              />
            </div>
          </div>
        </div>
        <div class="popup-body__comment__footer">
          <span
            >{{ $t("time") }}:
            {{
              commentInfo &&
              commentInfo.content_object &&
              commentInfo.content_object.created_at | dateTime
            }}
            /
            {{
              commentInfo &&
              commentInfo.content_object &&
              commentInfo.content_object.created_at | dateDay
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    commentInfo: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  mounted() {
    console.log(this.commentInfo);
  },
  data() {
    return {};
  },
  methods: {
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    getStatusType(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.type;
    },
    getClass(val) {
      if (val.includes("approved")) {
        return "approved";
      } else if (val.includes("rejected")) {
        return "rejected";
      } else if (val.includes("returned")) {
        return "returned";
      }
    },
  },
};
</script>

<style>
</style>