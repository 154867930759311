<template>
  <div class="help">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <b-row>
      <b-col xl="8">
        <div class="help-list" v-if="loading">
          <b-card
            no-body
            v-for="(item, index) in helpList"
            :key="item.id"
            class="help-list-item"
          >
            <b-card-header header-tag="div" role="tab">
              <div
                class="help-list-item-header"
                block
                v-b-toggle="'accordion-' + index + 1"
                variant="info"
              >
                <h3>
                  {{ item.title || "" }}
                </h3>
                <i>
                  <span></span>
                  <span></span>
                </i>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + index + 1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="help-list-item-text">
                  <p>{{ item.content || "" }}</p>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
      <b-col xl="4">
        <div class="help-info" v-if="loading">
          <h3>{{ $t("HelpSystemTitle") }}</h3>
          <div v-html="about[$i18n.locale]"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: true,
      count: 0,
      limit: 10,
      page: 1,
      isLoading: true,
      helpList: [
        // {
        //   id: 1,
        //   title: "Как оставить заявки",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
        // {
        //   id: 2,
        //   title: "Что делать, чтобы перейти ко второму этапу",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
        // {
        //   id: 3,
        //   title: "Как сменить пароль",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
        // {
        //   id: 4,
        //   title: "Что мне делать, когда моя слабость отменяется",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
        // {
        //   id: 5,
        //   title: "Предлагаемые преимущества являются преимуществами",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
        // {
        //   id: 6,
        //   title: "Информация о 5 программах",
        //   text: "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужно делать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
        // },
      ],
      about: {
        ru: `<p>28 октября 2020 года Совет директоров Всемирного банка одобрил проект «Модернизация национальной инновационной системы Узбекистана» с предоставлением финансовых средств в размере 50 млн. долларов США. Исполнительным органом данного проекта является Министерство Инновационного развития (МИР) Республики Узбекистан. 17 мая 2021 года подписано Соглашение о займе заключенного между Республикой Узбекистан и Всемирным банком. </p>
        <p>Проект «Модернизация национальной инновационной системы Узбекистана» нацелен на внедрение новых инструментов для стимулирования прикладных НИОКР и коммерциализации разработок в Узбекистане. Также планируется направить проектное финансирование на стимулирование инноваций на уровне предприятий в виде грантов компаниям, предпринимателям, инновационным стартапам, востребованным на рынке. </p>
        <p>Проект состоит из трех основных компонентов, в рамках Компонента 1 и Компонента 2 будут разработаны, в соответствии с международными стандартами, и реализованы 5 грантовых программ.  Кроме того, в проекте есть Компонент 3 для комплексной технической помощи для всех секторов (правительство, университеты и бизнес) посредством консультирования, наращивания потенциала, продвижения консенсуса.</p>
        <p>Целью Компонента 1 является повышение потенциала государственных исследований по достижению международных стандартов качества исследований и коммерциализация их результатов. Компонент 2 нацелен на поддержку инноваций в частном секторе, а Компонент 3 направлен на поддержку разработки реформ и укрепление потенциала государственного сектора в области научно-технической и инновационной политики (НТИ).</p>
        <p>В рамках Проекта создан Международный экспертный совет (МЭС), в состав которого входят семь авторитетных иностранных ученых (в областях, имеющих стратегическое значение для Узбекистана), опытные предприниматели, а также руководители, определяющие политику в области НТИ. МЭС будет оценивать проектные предложения, принимать решения о финансировании конкурсных грантов в соответствии с международной практикой, а также проводить мониторинг хода реализации и достижений отобранных исследовательских проектов. Проектные предложения по всем 5-ти грантовым программам принимаются на английском языке.</p>`,
        en: `<p>On October 28, 2020, the World Bank Board of Directors approved the project "Modernizing Uzbekistan National Innovation System" with the provision of funds in the amount of 50 million US dollars. The executive body 
        of this project is the Ministry of Innovative Development (MID) of the Republic of Uzbekistan. On May 17, 2021, the Loan Agreement was signed between the Republic of Uzbekistan and the World Bank.</p>
        <p>The project "Modernizing Uzbekistan National Innovation System" 
        is aimed at introducing new tools to stimulate applied R&D and commercialization of developments in Uzbekistan. It is also planned to direct project financing to stimulate innovation at the enterprise level in the form 
        of grants to companies, entrepreneurs, innovative start-ups that are in demand 
        in the market.</p>
        <p>The project consists of three main components, within the framework 
        of Component 1 and Component 2 will be developed, in accordance with international standards, and 5 grant programs will be implemented. In addition, the project has Component 3 for comprehensive technical assistance for all sectors (government, universities and business) through consultation, capacity building, consensus promotion.</p>
        <p>The objective of Component 1 is to increase the capacity of public research to achieve international research quality standards and commercialize its results. Component 2 aims to support innovation in the private sector, while Component 3 aims to support reform design and strengthen the capacity of the public sector in science, technology and innovation (STI) policy.</p>
        <p>Within the framework of the Project, the International Expert Board (IEB) was created, which includes seven reputable foreign scientists (in areas 
        of strategic importance for Uzbekistan), experienced entrepreneurs, as well as leaders who determine policy in the field of STI. (IEB) will evaluate project proposals, make decisions on the financing of competitive grants in accordance with international practice, and monitor the progress and achievements 
        of selected research projects. Project proposals for all 5 grant programs 
        are accepted in English.</p>`,
        uz: `<p>2020-йил 28-октябр куни жаҳон банкининг раҳбарлар кенгаши томонидан 50 миллион Ақш доллари миқдоридаги "Ўзбекистон Миллий инновацион тизимини модернизация қилиш" лойиҳаси маъқулланди. Ушбу лойиҳага маъсул ташкилот Ўзбекистон Республикаси Иннавацион вазирлиги этиб белгиланган. 2021-йил 17-май санасида Ўзбекистон Республикаси ва Жаҳон банки ўртасида қарз битими имзоланди.</p>
        <p>"Ўзбекистон Миллий инновацион тизимини модернизация қилиш"лойиҳаси Ўзбекистонда янги воситаларни жорий етишда амалий қўлланмаларни рағбатлантириш ва ишланмаларни тижоратлаштириш учун қаратилган. Шунингдек, лойиҳа бозорда катта талабга эга бўлган инновацияларни ишлаб чиқаришга жалб этган корхоналарга, тадбиркорларга, инновацион стартапларга грант ажратишни режалаштирган</p>
        <p>Лойиҳа учта асосий компонентдан иборат, 1-компонент ва 
        2-компонент халқаро стандартларга мувофиқ ишлаб чиқилади ва бешта грант дастурларни ўз ичига олади. Бундан ташқари, лойиҳанинг 
        3-компоненти ҳам мавжуд бўлиб, бу барча тармоқлар (ҳукумат, университетлар ва бизнес) учун ҳар томонлама техник ёрдам беришга қаратилган.</p>
        <p>1-компонентнинг мақсади тадқиқотнинг ҳалқаро стандартларга эришиш, унинг натижаларини тижоратлаштириш ва тадқиқотлар салоҳиятини оширишга қаратилган. 2-компонент хусусий секторда инновацияларни қўллаб қувватлашга қаратилган бўлса, 3-компонентдан мақсад давлат секторларида илм-фан, технология ва инновацияларни қўллаб қувватлаш ва салоҳиятини янада мустаҳкамлашга қаратилган.</p>
        <p>Лойиҳа доирасида етти нафар нуфузли (Ўзбекистон учун стратегик аҳамиятга эга бўлган соҳалардан) хорижий олимлар, тажрибали тадбиркорлар ва сиёсий билимларга ега бўлган шахслардан ташкил топган Халқаро Экспертлар Кенгаши (ХЭК) ташкил этилди. (ХЭК) лойиҳа таклифларини баҳолайди, халқаро амалиётга мувофиқ танлов гратларини молиялаштириш бўйича қарорлар қабул қилади ва танланган лойиҳаларни ривожини ва амалга ошишини кузатиб боради. Барча 5 грант дастурлари учун лойиҳа таклифлари инглиз тилида қабул қилинади.</p>`,
        oz: `<p>2020-йил 28-октябр куни жаҳон банкининг раҳбарлар кенгаши томонидан 50 миллион Ақш доллари миқдоридаги "Ўзбекистон Миллий инновацион тизимини модернизация қилиш" лойиҳаси маъқулланди. Ушбу лойиҳага маъсул ташкилот Ўзбекистон Республикаси Иннавацион вазирлиги этиб белгиланган. 2021-йил 17-май санасида Ўзбекистон Республикаси ва Жаҳон банки ўртасида қарз битими имзоланди.</p>
        <p>"Ўзбекистон Миллий инновацион тизимини модернизация қилиш"лойиҳаси Ўзбекистонда янги воситаларни жорий етишда амалий қўлланмаларни рағбатлантириш ва ишланмаларни тижоратлаштириш учун қаратилган. Шунингдек, лойиҳа бозорда катта талабга эга бўлган инновацияларни ишлаб чиқаришга жалб этган корхоналарга, тадбиркорларга, инновацион стартапларга грант ажратишни режалаштирган</p>
        <p>Лойиҳа учта асосий компонентдан иборат, 1-компонент ва 
        2-компонент халқаро стандартларга мувофиқ ишлаб чиқилади ва бешта грант дастурларни ўз ичига олади. Бундан ташқари, лойиҳанинг 
        3-компоненти ҳам мавжуд бўлиб, бу барча тармоқлар (ҳукумат, университетлар ва бизнес) учун ҳар томонлама техник ёрдам беришга қаратилган.</p>
        <p>1-компонентнинг мақсади тадқиқотнинг ҳалқаро стандартларга эришиш, унинг натижаларини тижоратлаштириш ва тадқиқотлар салоҳиятини оширишга қаратилган. 2-компонент хусусий секторда инновацияларни қўллаб қувватлашга қаратилган бўлса, 3-компонентдан мақсад давлат секторларида илм-фан, технология ва инновацияларни қўллаб қувватлаш ва салоҳиятини янада мустаҳкамлашга қаратилган.</p>
        <p>Лойиҳа доирасида етти нафар нуфузли (Ўзбекистон учун стратегик аҳамиятга эга бўлган соҳалардан) хорижий олимлар, тажрибали тадбиркорлар ва сиёсий билимларга ега бўлган шахслардан ташкил топган Халқаро Экспертлар Кенгаши (ХЭК) ташкил этилди. (ХЭК) лойиҳа таклифларини баҳолайди, халқаро амалиётга мувофиқ танлов гратларини молиялаштириш бўйича қарорлар қабул қилади ва танланган лойиҳаларни ривожини ва амалга ошишини кузатиб боради. Барча 5 грант дастурлари учун лойиҳа таклифлари инглиз тилида қабул қилинади.</p>`,
      },
    };
  },
  computed: {
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
      };
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
  watch: {
    page() {
      this.fetch();
    },
  },
  methods: {
    ...mapActions("userHelp", ["fetchList"]),
    async fetch() {
      this.loading = false;
      try {
        this.isLoading = true;
        let res = await this.fetchList({
          params: this.params,
        });
        // console.log(res)
        this.count = res && res.count;
        this.helpList = res && res.results;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
      this.loading = true;
    },
    paginate(arg) {
      this.page = arg;
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>


<style scoped>
</style>