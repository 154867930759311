<template>
  <div>
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="profile">
      <userAccountLegalForm
        :detail="form"
        :allOrgTypes="allOrgTypes"
        v-if="$store.state.auth.roleText == 'applicant_legal' && form.id"
        @submit="onSave"
      />
      <userAccountForm
        :detail="form"
        :allRegions="allRegions"
        v-else-if="form.id"
        @submit="onSave"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      form: {
        person_id: "",
        inn: "",
        email: "",
        passport: "",
        sess_id: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        phone: "",
        phone_additional: "",
        birthday: "",
        per_address: "",
        tem_address: "",
        birth_place: "",
        birth_country: "",
        nationality: "",
        citizenship: "",
        pport_issue_place: "",
        pport_issue_date: "",
        pport_expr_date: "",
        legal_name: "",
        legal_inn: "",
        region: "",
        district: "",
        postcode: "",
        telegram_account: "",
        director_full_name: "",
        organization_phone: "",
        organization_email: "",
        organization_website: "",
        legal_address: "",
        organization_type: null,
        organization_activity: "",
      },
      username: "",
      allRegions: [],
      allOrgTypes: [],
    };
  },
  async created() {
    try {
      const userDetatil = await this.$store.dispatch(
        "userAccount/fetchUserDetail"
      );
      if (userDetatil && Object.keys(userDetatil).length) {
        userDetatil.phone =
          userDetatil && userDetatil.phone && userDetatil.phone.slice(3);
        userDetatil.organization_phone =
          userDetatil &&
          userDetatil.organization_phone &&
          userDetatil.organization_phone.slice(3);
      }
      this.form = userDetatil;
      this.allRegions = await this.$store.dispatch("app/fetchAllRegions");
      this.allOrgTypes = await this.$store.dispatch("app/fetchAllOrgTypes");
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }

    // console.log(this.form);
  },
  mounted() {
    function parseJwt(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    }
    const access = localStorage.getItem("access");
    console.log(parseJwt(access));
    this.username = access.username;
  },
  methods: {
    async onSave(arg) {
      try {
        this.isLoading = true;
        let form = {};
        let cloneForm = { ...arg };
        Object.keys(cloneForm).forEach((key) => {
          cloneForm[key] && (form[key] = cloneForm[key]);
        });
        form.phone = "998" + cloneForm.phone;
        form.organization_phone = cloneForm.organization_phone
          ? "998" + cloneForm.organization_phone
          : "";
        let res = await this.$axios.patch("/site/user/me/update/", form);
        //console.log(res)
        if (res && res.response && res.response.status == 400) {
          Object.keys(res.response.data).forEach((i) => {
            throw new Error(res.response.data[i][0]);
          });
        }
        this.$toast.success(this.$t("successSaved"));
        // this.$router.go()
      } catch (e) {
        this.$toast.error(e.message, {
          position: "top",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>