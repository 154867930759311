<template>
  <div class="employees">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>
          {{ user && user.last_name }} {{ user && user.first_name }}
          {{ user && user.middle_name }}
        </h3>
      </div>
      <div class="pages-info">
        <ul>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.LegalName") }}</strong>
            <span>{{ user && user.legal_name }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.LegalType") }}</strong>
            <span>{{ user && user.organization_type }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.FormActivity") }}</strong>
            <span>{{ user && user.organization_activity }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("FIODirector") }}</strong>
            <span>{{ user && user.director_full_name }}</span>
          </li>

          <li>
            <strong>{{ $t("User.Firstname") }}</strong>
            <span>{{ user && user.first_name }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Lastname") }}</strong>
            <span>{{ user && user.last_name }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Middlename") }}</strong>
            <span>{{ user && user.middle_name }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Passport") }}</strong>
            <span>{{ user && user.passport }}</span>
          </li>
          <li>
            <strong>{{ $t("User.PersonId") }}</strong>
            <span>{{ user && user.person_id }}</span>
          </li>
          <li>
            <strong>{{ $t("User.INN") }}</strong>
            <span>{{ user && user.inn }}</span>
          </li>
          <li>
            <strong>{{ $t("User.BirthDay") }}</strong>
            <span>{{ user && user.birthday }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Region") }}</strong>
            <span>{{ user && user.region }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.TemAddress") }}</strong>
            <span>{{ user && user.legal_address }}</span>
          </li>
          <li v-else>
            <strong>{{ $t("User.PerAddress") }}</strong>
            <span>{{ user && user.per_address }}</span>
          </li>

          <li>
            <strong>{{ $t("User.Postcode") }}</strong>
            <span>{{ user && user.postcode }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Email") }}</strong>
            <span>{{ user && user.email }}</span>
          </li>
          <li>
            <strong>{{ $t("User.Phone") }}</strong>
            <span>{{ user && user.phone }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.ContactPhone") }}</strong>
            <span>{{ user && user.organization_phone }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("FormAccTelegram") }}</strong>
            <span>{{ user && user.telegram_account }}</span>
          </li>
          <li v-else>
            <strong>{{ $t("User.Telegram") }}</strong>
            <span>{{ user && user.telegram_account }}</span>
          </li>
          <li v-if="user && user.role == 'applicant_legal'">
            <strong>{{ $t("User.WebSiteOrganization") }}</strong>
            <span>{{ user && user.organization_website }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      user: {},
    };
  },
  async mounted() {
    this.isLoading = true;
    this.user = await this.$store.dispatch(
      "adminUsers/fetchUsersDetail",
      this.$route.params.id
    );
    this.isLoading = false;
  },
  methods: {},
};
</script>

<style scoped>
</style>