<template>
  <div class="pagination">
    <div class="pagination-controls">
      <div class="pagination-limit">
        <span>{{ $t("numberRows") }}</span>
        <b-dropdown :text="currentLimit">
          <b-dropdown-item
            v-for="(item, index) in limits"
            :key="index"
            @click="changeLimit(item)"
            >{{ item }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="pagination-items" v-if="count">
        <button
          :disabled="!currentPage || currentPage == 1"
          @click="currentPage--"
          class="pagination-item"
        >
          &lt;
        </button>
        <template v-for="item in allPages">
          <button
            :key="item"
            @click="currentPage = item"
            :disabled="currentPage == item"
            class="pagination-item"
            :class="{ active: currentPage == item }"
            v-if="
              item == 1 ||
              item == allPages ||
              (currentPage - 2 <= item && currentPage + 2 >= item)
            "
          >
            {{ item }}
          </button>
          <button
            :key="item"
            class="pagination-item"
            disabled="ture"
            v-else-if="
              (currentPage + 2 < item && currentPage + 3 >= item) ||
              (currentPage - 2 > item && currentPage - 3 <= item)
            "
          >
            ...
          </button>
        </template>
        <button
          :disabled="!currentPage || currentPage >= allPages"
          @click="currentPage++"
          class="pagination-item"
        >
          &gt;
        </button>
      </div>
    </div>
    <div class="pagination-info">
      <span>{{ $t("Total") }}: {{ count }}</span>
    </div>
    <!-- <button :disabled="!currentPage || (currentPage == 1)" @click="currentPage--"> prev </button>
        <input type="number" min="1" v-model="currentPage">
        <button :disabled="!currentPage || currentPage >= allPages" @click="currentPage++"> next </button>
        <p>All pages: {{ allPages }}</p> -->
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    limit: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    page: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
  },
  data() {
    return {
      currentPage: 1,
      limits: ["5", "10", "20", "30", "40", "50"],
      currentLimit: "10",
    };
  },
  computed: {
    allPages() {
      return Math.ceil(this.count / this.limit);
    },
  },
  mounted() {
    this.currentPage = this.page;
  },
  watch: {
    currentPage(newVal) {
      newVal <= this.allPages && this.$emit("paginate", newVal);

      // if (newVal > this.allPages) setTimeout(() => {
      //                                 this.currentPage = 1
      //                             }, 1000)

      // !newVal && setTimeout(() => {
      //     this.currentPage =  1
      // }, 1000)
    },
  },
  methods: {
    changeLimit(val) {
      this.currentLimit = val;
      this.currentPage = 1;
      this.$emit("onChangeLimit", this.currentLimit);
    },
  },
};
</script>

<style>
</style>