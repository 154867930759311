<template>
  <div class="file_uploader_class" v-if="loader">
    <div v-for="(el, index) in uploadersAndFiles" :key="index" class="details">
      <div v-if="el && el.type == 'file'" class="uploaded_files">
        <div class="uploaded_file">
          <div class="file_icon">
            <img :src="fileType(el && el.file && el.file.name)" alt="" />
            <p>{{ el.file.name }}</p>
          </div>
          <div
            @click="
              deleteFile(
                el.uid || (el.file && el.file.id),
                (el.file && el.file.id && 'file') || ''
              )
            "
            class="delete_file"
          >
            <img src="@/assets/images/icons/delete.svg" alt="" />
          </div>
        </div>
      </div>
      <div v-else class="custom_file_uploader">
        <input
          type="file"
          :id="'file_uploader' + name"
          @input="uploadFile($event, el.uid)"
        />
        <label :for="'file_uploader' + name" id="custom_file_uploader">
          <div id="file_uploader_deco">
            <img src="@/assets/images/icons/upload.svg" alt="" />
          </div>
          <p>{{ $t("UploadFile") }}</p>
        </label>
        <button
          class="remove-btn"
          v-if="uploadersAndFiles.length > 1"
          @click="removeUploader(el.uid)"
        >
          {{ $t("Delete") }}
        </button>
      </div>
    </div>
    <button
      v-if="multiple && uploadersAndFiles < 2"
      @click="addUploader"
      id="add_file"
    >
      + {{ $t("AddFile") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "FileUploader",
  props: {
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    deleteapi: {
      type: String,
      default() {
        return "";
      },
    },
    name: {
      type: String,
      default: "",
    },
    files: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      loader: true,
      uploadersAndFiles: [
        {
          type: "uploader",
        },
      ],
      fileTypes: {
        pdf: require("@/assets/images/icons/pdf.svg"),
        word: require("@/assets/images/icons/word.svg"),
        excel: require("@/assets/images/icons/excel.svg"),
        default: require("@/assets/images/icons/file.svg"),
      },
    };
  },
  mounted() {
    console.log(this.name, this.files);
    const otherFiles =
      this.files && this.files.length && Array.isArray(this.files)
        ? this.files.map((i) => ({
            type: "file",
            file: { ...i.attachment, id: i.id || null },
          }))
        : [{ type: "uploader" }];
    console.log("other files", otherFiles, this.files);
    this.files && Array.isArray(this.files)
      ? (this.uploadersAndFiles = [...otherFiles])
      : (this.uploadersAndFiles = [{ type: "file", file: this.files }]);
    console.log("upload", this.uploadersAndFiles);
  },
  methods: {
    fileType(value) {
      let type = value.split(".").reverse()[0];
      // console.log(type)
      return type == "pdf"
        ? this.fileTypes.pdf
        : type == "doc" || type == "docx"
        ? this.fileTypes.word
        : type == "xls" || type == "xlsx"
        ? this.fileTypes.excel
        : this.fileTypes.default;
    },
    uploadFile(e, uid) {
      this.loader = false;
      console.log("name", this.name, uid);

      let IND = this.uploadersAndFiles.findIndex((el) => el.uid == uid);
      //console.log(uploadedFile, this.uploadersAndFiles, IND, uid)
      // let uploadedFile = e.target.files[0]
      this.uploadersAndFiles[IND] = {
        ...this.uploadersAndFiles[IND],
        type: "file",
        file: e.target.files[0],
      };
      console.log(this.uploadersAndFiles);
      this.loader = true;

      let sendFiles = this.multiple
        ? this.uploadersAndFiles.filter((el) => el.type == "file")
        : this.uploadersAndFiles[0];
      this.$emit("getFiles", sendFiles);
    },
    async deleteFile(uid, type) {
      // this.files = this.files.filter(el => el.uid != uid)

      // console.log(uid, this.uploadersAndFiles)

      try {
        if (type == "file") {
          await this.$axios.delete(`${this.deleteapi}/${uid}/`);
        }

        this.uploadersAndFiles =
          this.uploadersAndFiles.length > 1
            ? this.uploadersAndFiles.filter(
                (el) =>
                  (el.uid || null) != uid &&
                  ((el.file && el.file.id) || null) != uid
              )
            : [{ type: "uploader", uid: new Date().getTime() }];

        let sendFiles = this.multiple
          ? this.uploadersAndFiles.filter((el) => el.type == "file")
          : null;
        this.$emit("getFiles", sendFiles);
      } catch (err) {
        console.log(err);
      }
    },
    addUploader() {
      this.uploadersAndFiles.push({
        uid: new Date().getTime(),
        type: "uploader",
      });
    },
    removeUploader(uid) {
      this.uploadersAndFiles = this.uploadersAndFiles.filter(
        (el) => el.uid != uid
      );
    },
  },
};
</script>

<style>
</style>
