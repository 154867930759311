const en = {
  errorPhone: "Enter your number",
  errorPassword: "Enter password",
  errorUsername: "Enter username",
  Authorization: "Authorization",
  NaturalPerson: "Individuals",
  JuridicalPerson: "Legal entity",
  TypePerson: "User type",
  Username: "Username",
  Telephone: "Telephone",
  Password: "Password",
  Login: "Login",
  Developer: "Developer",
  PhoneExists: "Phone number already exists",
  ForgotTitle: "Password recovery",
  UserNotFound: "User is not found",
  UsernameExists: "Username already exists",
  PasswordExists: "The entered password is too short. It must contain at least 8 characters and must not contain only numbers",
  PasswordInfo: "The password must contain at least 8 characters and must not contain only numbers!",

  User: {
    Username: "Username",
    Password: "Password",
    Firstname: "First name",
    Lastname: "Last name",
    Middlename: "Middle name",
    Passport: "Passport series and number",
    PersonId: "Person ID",
    INN: "TIN",
    BirthDay: "Birth date",
    PerAddress: "Registration address",
    Region: "Region",
    District: "District/City",
    Postcode: "Postcode",
    Email: "E-Mail",
    Phone: "Phone number",
    Telegram: "Telegram profile",
    PhoneAdditional: "",
    TemAddress: "Juridical address",
    FormActivity: "Form of activity",
    Gender: "",
    BirthPlace: "",
    BirthCountry: "",
    Nationality: "",
    Citizenship: "",
    PportIssuePlace: "",
    PportIssueDate: "",
    PportExprDate: "",
    LegalName: "Company / organization name",
    LegalType: "Organization type",
    LegalInn: "INN",
    ContactPhone: "Contact phone number of the organization",
    ContactPhone2: "Representative phone number",
    Organization: "organizations",
    Representative: "representative",
    WebSiteOrganization: "Organization website",
    EditPass: "Edit password",
    EditPass2: "Change password",
    SelectRegion: "Select region",
  },

  Close: "Close",
  Save: "Save",
  Create: "Create",
  Admin: "Admin",
  Add: "Add",
  Stage: "stage",
  Other: "Other",
  Exit: "Exit",
  Delete: "Delete",
  AddFile: "Add file",
  UploadFile: "Upload the file",

  Theme: "Theme",
  Continue: "Continue",
  AddDocument: "Add document",
  PersonalData: "Personal data",
  SidebarLogo: "Modernization of the national innovation system of Uzbekistan",
  SidebarMenu1: "Personal data",
  SidebarMenu2: "Applications",
  SidebarMenu3: "Feedback",
  SidebarMenu4: "Отчеты",
  SidebarMenu5: "Reports",
  TableMessageText: "Message text",
  TableData: "Data",
  TableTitle: "Name",
  TableDocument: "Document",
  TableStatus: "Status",
  TableNewApp: "New application",
  TableStartDate: "Start date",
  TableEndDate: "Expiration date",
  FormConfirmPassword: "Password confirmation",
  FormRegister: "Registration",
  FormRegister2: "Registration",
  FormRegisterLegal: "Register as a legal entity",
  FormOrganizationName: "Company / organization name",
  FormCodeFromSMS: "SMS code",
  FormGetCode: "Get code in SMS",
  FormSendCodeAfter: "Send the code again after 10 seconds.",
  FormFullName: "Full Name",
  FormPassportSerial: "Passport series and number",
  FormPINFL: "PINFL",
  FormINN: "INN",
  FormBirthday: "Date of Birth",
  FormAddress: "Registration address",
  FormCountry: "Country/city",
  FormEmailIndex: "Postcode",
  FormEmail: "E-mail",
  FormTelNumber: "Phone number",
  FormAccTelegram: "Telegram Account",
  FeedbackList: "List of appeals",
  HelpSystemTitle: "About the system",
  HelpSystemText:
    "For the labels on this drink. In order for the average person to do this, it is necessary to get rid of the pain in the immune system. Don't do it in your backyard. A large-scale bed with real estate, an ugly mass of cars. And no, the fans will be cured, and the cheerfulness is only flattering. Let it be one layer, sheets, onion pillow. Sometimes orcs lion beaks are forced, my problems need wise men. Tomorrow, when the gods wish to head for diesel. Even fear of the Internet is okay. And the mass element is cartoon, but there is no fun. My dear, it's a shame that tomorrow there won't be a tormentor. Nibh cursus sit quis duis In life, but economic and at. No bills, no funding, and no homework.",
  ReportsTitle: "on project implementation",
  RequestData: "Application data",
  RequestEditApp: "Edit application",
  RequestPassportProject: "Project passport",
  RequestInfoProject: "Information the project",
  RequestOther: "Other",
  RequestStatusApp: "Application status",
  RequestProgramm: "Program",
  RequestDownload: "Download template",
  RequestApply: "Submit your application",
  RequestMyApp: "My application",
  RequestCreateAppeal: "Create an appeal",
  RequestStep: "Stage",
  Date: "Date",
  RequestName: "Name",
  RequestInfoStep2:
    "Your application has been reviewed and submitted to stage 2, <br /> enter the required documents",
  RequestStep1: "Stage 1 request status",
  RequestStep2: "Application status 2 stages",

  menu: {
    Account: "Account",
    Users: "Users",
    Employees: "Employees",
  },
  enterName: "Enter your name",
  enterLastName: "Enter your last name",
  enterPasport: "Enter the passport series and number",
  EnterPinfl: "Enter the PINFL",
  enterInn: "Enter the INN",
  enterBirthday: "Enter your date of birth",
  enterPerAddress: "enter your registered address",
  chooseRegion: "Select a region",
  enterPostcode: "Enter the zip code",
  enterPhone: "Enter your phone number",
  enterLegalName: "Enter the name of the company/organization",
  selectTypeOrganization: "Select the type of organization",
  enterFullNameDirector: "Enter the full name of the director",
  enterLegaladdress: "Enter the legal address",
  enterProjectName: "Enter the project name",
  selectProgramm: "Select a program",
  uploadTheFile: "Upload the file",
  successText: "Successfully sent",
  errorText: "Error",
  enterTheme: "Enter a subject",
  enterMessageText: "Enter the message text",

  errorLogin: "Username or password is incorrect",
  forgot: "Forgot your password?",
  successSaved: "Successfully saved",
  successDelete: "Successfully deleted",

  download: "Download",
  listBoards: "Список досок",
  FIO: "Full name",
  FIODirector: "Full name of the director",
  Rejected: "Rejected",
  Approved: "Approved",
  TitleProject: "Project name",
  toBack: "Back",
  Confirmation: "Confirmation",
  EmployeeType: "Employee type",
  Speciality: "Speciality",
  emptyNotificationsList: "The notification list is empty!",
  Reject: "Reject",
  Approve: "Approve",
  yes: "yes",
  no: "no",
  confirmRequest: "Do you really want to confirm this request?",
  InformationResponse: "Information about the response",
  time: "Time",
  phone: "Phone",
  PopupStaffReviewTitle: "Attached experts",
  PopupStaffReviewTitle1: "Who approved and when",
  PopupStaffReviewTitle2: "Main experts",
  StaffReviewDetailButton: "Applicant's details",
  toAnswer: "Add an answer",
  Send: "Send",
  applicationsStatistics: "Application statistics",
  allApplications: "All applications",
  UnderConsideration: "Under consideration",
  NotAccepted: "Not accepted",
  announcedTheWinner: "Announced the winner",
  TotalNumber: "Total Number",
  ViewTheAnswer: "View the answer",
  SendRevision: "Send for revision",
  ReturnedRevision: "Returned for revision",
  ListApplications: "List of applications",
  Apply: "Apply",
  Clear: "Clear",
  SendForTechnicalExpertise: "Send for technical expertise",
  SendForAdditionalTechnicalExpertise:
    "Send for additional technical expertise",
  Complete: "Complete",
  CompleteStage1: "Complete Stage 1",
  CompleteStage2: "Complete Stage 2",
  SearchByID: "Search by ID",
  ApplicationRejected: "Application rejected",
  CompletionDate: "Completion date",
  FinishTheRevision: "Send application",
  Total: "Total",
  MessageText: "Message text",
  theme: "Theme",
  Time: "Time",
  AboutSystem: "About the system",
  Notifications: "Notifications",
  Components: "Components",
  ListOfComponents: "List of components",
  Nationalities: "Nationalities",
  Countries: "Countries",
  Districts: "Districts",
  Specialization: "Specialization",
  All: "All",
  EmployeePiu: "Employee of PIU",
  TechnicalExpert: "Technical expert",
  InternationalExpert: "International expert",
  LeadingInternationalExpert: "Leading international expert",
  Director: "Coordinator",
  ContentManager: "Content Manager",
  Role: "Role",
  List: "List",
  ListOfPrograms: "List of programs",
  ApplicationForm2: "Application form (Stage 2)",
  ShowAll: "Show all",
  SMSCode: "SMS code",
  SendTheCodeAgainIn: "Send the code again in {timer} sec.",
  SendTheCodeAgain: "Send the code again",
  WrongSmsCode: "Wrong SMS code Please enter correct SMS code",
  AttachedDocument: "Attached document",
  AttachDocument: "Attach document",
  Component: "Component",
  PeriodAdmission1Stage: "Period of admission of the 1st stage",
  PeriodAdmission2Stage: "Period of admission of the 2nd stage",
  Active: "Active",
  Catalog: "Catalog",
  Title: "Title",
  Order: "Order",
  Employee: "Employee",
  PleaseSelect: "Please select",
  EnterText: "Enter text",
  Content: "Content",
  Prefix: "Prefix",
  InfoFile: "Info file",
  ProgramIcon: "Program icon",
  CompleteStep: "Complete {step}-th stage",
  SubmittingForRevision: "Submitting for revision",
  dateOfExpiration: "Date of expiration",
  mainExpert: "{count}-Main Expert",
  SelectKeyExperts: "Select key experts",
  ReserveExpert: "Reserve expert",
  ChooseReserveExpert: "Choose a reserve expert",
  PasswordInclude8:
    "Password must include numbers, letters and at least 8 characters",
  numberRows: "number of rows",
  modifiedFile: "Modified file",
};

export default en;
