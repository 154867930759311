var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"requests"},[_c('transition',{attrs:{"name":"loading-fade"}},[(_vm.isLoading)?_c('appLoader'):_vm._e()],1),(!_vm.loading)?_c('div',{staticClass:"pages-main"},[_c('div',{}),_c('div',{staticClass:"request-form"},[_c('b-row',[_c('b-col',{attrs:{"xl":"6"}},[_c('div',{staticClass:"form-col"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("TitleProject"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-input',{class:{
                  invalid: _vm.$v.form.title.$dirty && !_vm.$v.form.title.required,
                },attrs:{"id":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.$v.form.title.$dirty && !_vm.$v.form.title.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("enterProjectName")))]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("RequestProgramm"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('b-form-select',{class:{
                  invalid:
                    _vm.$v.form.program.$dirty && !_vm.$v.form.program.required,
                },attrs:{"options":_vm.programs},on:{"change":_vm.changeProgram},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.$t("selectProgramm")))])]},proxy:true}],null,false,84722357),model:{value:(_vm.form.program),callback:function ($$v) {_vm.$set(_vm.form, "program", $$v)},expression:"form.program"}}),(_vm.$v.form.program.$dirty && !_vm.$v.form.program.required)?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("selectProgramm")))]):_vm._e()],1)])]),_c('b-col',{attrs:{"xl":"6"}},[_c('div',{staticClass:"form-col"},[(
                _vm.form.status == 'returned_in_consideration' ||
                _vm.form.status == 'revisioned_application'
              )?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("modifiedFile"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('div',{staticClass:"form-example"},[_c('fileUploader',{key:"1",attrs:{"name":"project","files":_vm.revisioned_information_file},on:{"getFiles":_vm.setModifiedFile}}),_c('a',{staticClass:"form-example__item",attrs:{"href":_vm.infoTemplateFile,"download":"download","target":"_blank"}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1","stroke":"#313131","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("RequestDownload")))])])],1),(
                  _vm.$v.form.information_file.$dirty &&
                  !_vm.$v.form.information_file.required
                )?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("uploadTheFile")))]):_vm._e()]):_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("RequestInfoProject"))+" "),_c('span',{staticClass:"red"},[_vm._v("*")])]),_c('div',{staticClass:"form-example"},[_c('fileUploader',{key:"1",attrs:{"name":"project","files":_vm.information_file},on:{"getFiles":_vm.setProjectFile}}),_c('a',{staticClass:"form-example__item",attrs:{"href":_vm.infoTemplateFile,"download":"download","target":"_blank"}},[_c('svg',{attrs:{"width":"18","height":"18","viewBox":"0 0 18 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 13V14C1 14.7956 1.31607 15.5587 1.87868 16.1213C2.44129 16.6839 3.20435 17 4 17H14C14.7956 17 15.5587 16.6839 16.1213 16.1213C16.6839 15.5587 17 14.7956 17 14V13M13 9L9 13M9 13L5 9M9 13V1","stroke":"#313131","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("RequestDownload")))])])],1),(
                  _vm.$v.form.information_file.$dirty &&
                  !_vm.$v.form.information_file.required
                )?_c('small',{staticClass:"error-text"},[_vm._v(_vm._s(_vm.$t("uploadTheFile")))]):_vm._e()]),_c('div',{staticClass:"form-group",staticStyle:{"display":"none"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("Other")))]),_c('fileUploader',{key:"2",attrs:{"multiple":"","name":"other","files":_vm.files,"deleteapi":"/site/application/attachment/delete"},on:{"getFiles":_vm.setOtherFiles}})],1)])])],1),_c('div',{staticClass:"form-col"},[(_vm.isView)?_c('div',{staticClass:"form-btns"},[_c('b-button',{staticClass:"mybtn mybtn-green",on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("Save")))]),(
              _vm.form &&
              _vm.form.status &&
              (_vm.form.status == 'draft' ||
                _vm.form.status == 'returned_in_consideration')
            )?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrect || _vm.submitStatus == 'PENDING'},on:{"click":_vm.sendRequest}},[_vm._v(_vm._s(_vm.$t("RequestApply")))]):_vm._e()],1):_vm._e()])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }