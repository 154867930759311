<template>
  <div class="reports">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("ListApplications") }}</h3>
        <div class="pages-title__filter d-flex justify-contend-end">
          <div class="form-group">
            <b-input
              v-model="reg_number"
              :placeholder="$t('SearchByID')"
              class="staff-search"
            ></b-input>
          </div>
          <div class="form-group">
            <b-form-select
              class="staff-select"
              v-model="program"
              :options="programs"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("selectProgramm")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <b-button
            class="mybtn mybtn-blue"
            v-if="!isFilter"
            @click="getApplicationListWithFilter"
            >{{ $t("Apply") }}</b-button
          >
          <b-button class="mybtn mybtn-gray" v-else @click="clearFilter">{{
            $t("Clear")
          }}</b-button>
        </div>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="1%">ID</th>
              <th width="100px">{{ $t("RequestName") }}</th>
              <th width="200px">{{ $t("RequestProgramm") }}</th>
              <th width="1%">{{ $t("Date") }}</th>
              <th width="1%">{{ $t("RequestStep") }}</th>
              <th width="1%">{{ $t("TableStatus") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              tag="tr"
              :to="`/cabinet/international-expert/verified-applications/${item.id}`"
              class="link"
              v-for="item of applicationList"
              :key="item.id"
            >
              <td>{{ item.id }}</td>
              <td>
                <span class="nowrap">{{ item.reg_number }}</span>
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.program || "" }}</td>
              <td>{{ item.created_at | dateDay }}</td>
              <td>
                <span class="nowrap">
                  {{
                    item.application_consideration &&
                    item.application_consideration.second_step &&
                    item.application_consideration.second_step.type
                      ? 2
                      : 1
                  }}
                  - {{ $t("Stage") }}
                </span>
              </td>
              <td>
                <span
                  class="status-text"
                  :class="
                    getClass(
                      (item.application_consideration &&
                        item.application_consideration.second_step &&
                        item.application_consideration.second_step.type) ||
                        (item.application_consideration &&
                          item.application_consideration.first_step &&
                          item.application_consideration.first_step.type)
                    )
                  "
                  >{{
                    getNewStatus(
                      (item.application_consideration &&
                        item.application_consideration.second_step &&
                        item.application_consideration.second_step.type) ||
                        (item.application_consideration &&
                          item.application_consideration.first_step &&
                          item.application_consideration.first_step.type)
                    )
                  }}</span
                >
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
    <utilsPagination
      :limit="limit"
      :page="page"
      :count="count"
      @paginate="paginate"
      @onChangeLimit="onChangeLimit"
    />
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      filterTab: null,
      statusList: [],
      applicationList: [],
      count: 0,
      limit: 10,
      page: 1,
      reg_number: null,
      program: null,
      programs: [],
      isFilter: false,
    };
  },
  computed: {
    params() {
      let params = {
        reg_number: this.reg_number,
        program: this.program,
        limit: this.limit,
        offset: this.offset,
      };
      this.filterTab
        ? (params.status = this.filterTab)
        : (params.status = [
            "rejected_in_consideration",
            "returned_in_consideration",
            "approved_in_consideration",
          ]);
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
  watch: {
    filterTab() {
      this.page = 1;
      this.getApplicationList();
    },
    page() {
      this.getApplicationList();
    },
    reg_number() {
      this.isFilter = false;
    },
    program() {
      this.isFilter = false;
    },
  },

  async mounted() {
    this.isLoading = true;
    this.statusList = statusList;
    await this.getProgramsList();
    this.filterTab = this.$route.query && this.$route.query.status;
    await this.getApplicationList();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("international", [
      "fetchPrograms",
      "fetchApplicationConsdiredList",
    ]),
    async getApplicationList() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationConsdiredList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async getProgramsList() {
      try {
        let res = await this.fetchPrograms();
        this.programs = res;
      } catch (err) {
        console.error(err);
      }
    },
    async getApplicationListWithFilter() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchApplicationConsdiredList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.applicationList = res && res.results;
        this.isFilter = true;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    getStep(val) {
      let status = statusList.find((el) => el.key == val);
      // console.log(status)
      return status && status.value > 12 ? 2 : 1;
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);
      return status && status.title[this.$i18n.locale];
    },
    paginate(arg) {
      this.page = arg;
    },
    activeFilter(arg) {
      this.filterTab = arg;
      this.$router.push({ query: { status: arg } });
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.getApplicationList();
    },
    getNewStatus(val) {
      if (val.includes("approved")) {
        return this.$t("Approved");
      } else if (val.includes("rejected")) {
        return this.$t("Rejected");
      }
    },
    getClass(val) {
      if (val.includes("approved")) {
        return "approved";
      } else if (val.includes("rejected")) {
        return "rejected";
      }
    },
    clearFilter() {
      this.reg_number = null;
      this.program = null;
      this.getApplicationList();
      this.isFilter = false;
    },
    onChange(val) {
      console.log(val);
      this.isFilter = true;
    },
  },
};
</script>

<style></style>
