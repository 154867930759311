import uz from "./messages/uz";
import ru from "./messages/ru";
import en from "./messages/en";

const messages = {
  uz,
  ru,
  en,
};

export default messages;
