const ru = {
  errorPhone: "Введите ваш номер",
  errorPassword: "Введите пароль",
  errorUsername: "Введите логин",
  Authorization: "Авторизация",
  NaturalPerson: "Физическое лицо",
  JuridicalPerson: "Юридическое лицо",
  TypePerson: "Тип пользователя",
  Username: "Логин",
  Telephone: "Телефон",
  Password: "Пароль",
  Passport: "Паспорт",
  Login: "Войти",
  Developer: "Разработка",
  PhoneExists: "Номер телефона уже существует",
  ForgotTitle: "Восстановление пароля",
  UserNotFound: "Пользователь не найден",
  UsernameExists: "Имя пользователя уже занято",
  PasswordExists: "Введённый пароль слишком короткий. Он должен содержать как минимум 8 символов и не должен состоять только из цифр",
  PasswordInfo: "Пароль должен содержать как минимум 8 символов и не должен состоять только из цифр!",

  User: {
    Username: "Логин",
    Password: "Пароль",
    Firstname: "Имя",
    Lastname: "Фамилия",
    Middlename: "Отчество",
    Passport: "Серия и номер паспорта",
    PersonId: "ПИНФЛ",
    INN: "ИНН",
    BirthDay: "Дата рождения",
    PerAddress: "Адрес по прописке",
    Region: "Область",
    District: "Район/Город",
    Postcode: "Почтовый индекс",
    Email: "Э-Почта",
    Phone: "Номер телефона",
    Telegram: "Аккаунт Телеграмм",
    PhoneAdditional: "",
    TemAddress: "Юридический адрес",
    FormActivity: "Форма деятельности",
    Gender: "",
    BirthPlace: "",
    BirthCountry: "",
    Nationality: "",
    Citizenship: "",
    PportIssuePlace: "",
    PportIssueDate: "",
    PportExprDate: "",
    LegalName: "Наименование компании/организации",
    LegalType: "Тип организации",
    LegalInn: "ИНН",
    ContactPhone: "Контактный телефон организации",
    ContactPhone2: "Номер телефона представителя",
    Organization: "организации",
    Representative: "представителя",
    WebSiteOrganization: "Web-сайт организации",
    EditPass: "Редактировать пароль",
    EditPass2: "Сменить пароль",
    SelectRegion: "Выберите город",
  },
  Close: "Закрыть",
  Save: "Сохранить",
  Create: "Создать",
  Admin: "Администратор",
  Add: "Добавить",
  Stage: "этап",
  Other: "Прочее",
  Exit: "Выход",
  Delete: "Удалять",
  AddFile: "Добавить файл",
  UploadFile: "Загрузите файл",
  Theme: "Тема",
  Continue: "Продолжить",
  AddDocument: "Добавить документ",
  PersonalData: "Личные данные",
  SidebarLogo: "Модернизация национальной инновационной системы Узбекистана",
  SidebarMenu1: "Личные данные",
  SidebarMenu2: "Заявки",
  SidebarMenu3: "Обратная связь",
  SidebarMenu4: "Отчеты",
  SidebarMenu5: "Помощь",
  TableMessageText: "Текст ответа",
  TableData: "Дата",
  TableTitle: "Название",
  TableDocument: "Документ",
  TableStatus: "Статус",
  TableNewApp: "Новая заявка",
  TableStartDate: "Дата начала",
  TableEndDate: "Дата окончания",
  FormConfirmPassword: "Подтверждение пароля",
  FormRegister: "Регистрация",
  FormRegister2: "Зарегистрироватся",
  FormRegisterLegal: "Зарегистрироваться как юридическое лицо",
  FormOrganizationName: "Наименование компании/организации",
  FormCodeFromSMS: "Код из SMS",
  FormGetCode: "Получить код в SMS",
  FormSendCodeAfter: "Отправить код ещё раз через 10сек.",
  FormFullName: "Фамилия имя отчество",
  FormPassportSerial: "Серия и номер паспорта",
  FormPINFL: "ПИНФЛ",
  FormINN: "ИНН",
  FormBirthday: "Дата рождения",
  FormAddress: "Адрес по прописке",
  FormCountry: "Страна/город",
  FormEmailIndex: "Почтовый индекс",
  FormEmail: "E-mail",
  FormTelNumber: "Номер телефона",
  FormAccTelegram: "Аккаунт Телеграмм",
  FeedbackList: "Список обращений",
  HelpSystemTitle: "О системе",
  HelpSystemText:
    "Для этикеток на этом напитке. Для того, чтобы обычный человек сделал это, необходимо избавиться от боли в иммунной системе. Не нужноделать это на заднем дворе. Масштабная кровать с недвижимостью, уродливая масса автомобилей. И нет, болельщики вылечатся, а жизнерадостность только льстит. Пусть будет один слой, простыни, подушка лука. Иногда орки львиные клювы вынуждены, мои проблемы нужны мудрецы. Завтра, когда боги пожелают взять курс на дизель. Даже страх перед Интернетом - это нормально. А массовый элемент - это мультфильм, а веселья нет. Милая моя, позорно, что завтра мучителя не будет. Nibh cursus sit quis duis В жизни но хозяйственный и у. Нет счетов, нет финансирования и нет домашних заданий.",
  ReportsTitle: "Отчеты о реализации проекта",
  RequestData: "Данные заявки",
  RequestEditApp: "Редактировать заявку",
  RequestPassportProject: "Паспорт проекта",
  RequestInfoProject: "Информация о проекте",
  RequestOther: "Прочее",
  RequestStatusApp: "Статус заявки",
  RequestProgramm: "Программа",
  RequestDownload: "Скачать шаблон",
  RequestApply: "Подать заявку",
  RequestMyApp: "Мой заявка",
  RequestCreateAppeal: "Создать обращение",
  RequestStep: "Этап",
  Date: "Дата",
  RequestName: "Название",
  RequestInfoStep2:
    "Ваша заявка рассмотрена и передана на 2 этап, <br />введите необходимые документы",
  RequestStep1: "Статус заявки 1 этапа",
  RequestStep2: "Статус заявки 2 этапа",
  menu: {
    Account: "Профиль",
    Users: "Пользователи",
    Employees: "Сотрудники",
  },
  enterName: "Введите ваше имя",
  enterLastName: "Введите вашу фамилию",
  enterPasport: "Введите серию и номер паспорта",
  EnterPinfl: "Введите ПИНФЛ",
  enterInn: "Введите ИНН",
  enterBirthday: "Введите дату рождения",
  enterPerAddress: "введите ваш адрес по прописке",
  chooseRegion: "Выберите регион",
  enterPostcode: "Введите почтовый индекс",
  enterPhone: "Введите номер телефона",
  enterLegalName: "Введите наименование компании/организации",
  selectTypeOrganization: "Выберите тип организации",
  enterFullNameDirector: "Введите полное имя директора",
  enterLegaladdress: "Введите юридический адрес",
  enterProjectName: "Введите название проекта",
  selectProgramm: "Выберите программу",
  uploadTheFile: "Загрузите файл",
  successText: "Успешно отправлено!",
  errorText: "Ошибка",
  enterTheme: "Введите тему",
  enterMessageText: "Введите текст обращения",

  errorLogin: "Логин или пароль неверны",
  forgot: "Забыли пароль?",
  successSaved: "Успешно сохранено",
  successDelete: "Успешно удалено",

  download: "Скачать",
  listBoards: "Список досок",
  FIO: "Ф.И.О",
  FIODirector: "Ф.И.О директора",
  Rejected: "Отклонена",
  Approved: "Одобрена",
  TitleProject: "Название проекта",
  toBack: "Назад",
  Confirmation: "Подтверждение",
  EmployeeType: "Тип сотрудника",
  Speciality: "Специальность",
  emptyNotificationsList: "Список уведомлений пуст!",
  Reject: "Отклонить",
  Approve: "Одобрить",
  yes: "да",
  no: "нет",
  confirmRequest: "Вы действительно хотите подтвердить этот запрос?",
  InformationResponse: "Информация об ответе",
  time: "Время",
  phone: "Телефон",
  PopupStaffReviewTitle: "Прикрепленные эксперты",
  PopupStaffReviewTitle1: "Кто одобрил и когда",
  PopupStaffReviewTitle2: "Основные эксперты",
  StaffReviewDetailButton: "Данные заявителя",
  toAnswer: "Добавить ответ",
  Send: "Отправить",
  applicationsStatistics: "Статистика заявок",
  allApplications: "Все заявки",
  UnderConsideration: "На рассмотрении",
  NotAccepted: "Отказано",
  announcedTheWinner: "объявлен победителем",
  TotalNumber: "Общее количество ",
  ViewTheAnswer: "Посмотреть ответ",
  SendRevision: "Отправить на доработку",
  ReturnedRevision: "Возвращена на доработку",
  ListApplications: "Список заявок",
  Apply: "Применить",
  Clear: "Очистить",
  SendForTechnicalExpertise: "Отправить на техническую экспертизу",
  SendForAdditionalTechnicalExpertise:
    "Отправить на дополнительную техническую экспертизу",
  Complete: "Завершить",
  CompleteStage1: "Завершить 1-ый этап",
  CompleteStage2: "Завершить 2-ой этап",
  SearchByID: "Поиск по ID",
  ApplicationRejected: "Заявка отклонена",
  CompletionDate: "Дата окончания доработки",
  FinishTheRevision: "Отправить заявку",
  Total: "Всего",
  MessageText: "Текст сообщения",
  theme: "Тема",
  Time: "Время",
  AboutSystem: "О системе",
  Notifications: "Уведомления",
  Components: "Компоненты",
  ListOfComponents: "Список компонентов",
  Nationalities: "Национальности",
  Countries: "Страны",
  Districts: "Районы",
  Specialization: "Cпециальность",
  All: "Все",
  EmployeePiu: "Сотрудник ГРП",
  TechnicalExpert: "Технический эксперт",
  InternationalExpert: "Международный эксперт",
  LeadingInternationalExpert: "Ведущий международный эксперт",
  Director: "Координатор",
  ContentManager: "Контент менеджер",
  Role: "Роль",
  List: "Список",
  ListOfPrograms: "Список программ",
  ApplicationForm2: "Форма подачи заявки (2 этап)",
  ShowAll: "Показать все",
  SMSCode: "Код из SMS",
  SendTheCodeAgainIn: "Отправить код ещё раз через {timer} сек.",
  SendTheCodeAgain: "Отправить код ещё раз через",
  WrongSmsCode: "СМС-код неправильный Пожалуйста, введите правильный смс-код",
  AttachedDocument: "Прикрепленный документ",
  AttachDocument: "Прикрепить документ",
  Component: "Компонент",
  PeriodAdmission1Stage: "Период приёма 1-го этапа",
  PeriodAdmission2Stage: "Период приёма 2-го этапа",
  Active: "Активный",
  Catalog: "Каталог",
  Title: "Заголовок",
  Order: "порядок",
  Employee: "Сотрудник",
  PleaseSelect: "Выберите",
  EnterText: "Введите текст",
  Content: "Контент",
  Prefix: "Префикс",
  InfoFile: "Информационный файл",
  ProgramIcon: "Значок программы",
  CompleteStep: "Завершить {step}-го этапа",
  SubmittingForRevision: "Отправка на доработку",
  dateOfExpiration: "Дата истечения срока действия",
  mainExpert: "{count}-Основной эксперт",
  SelectKeyExperts: "Выбрать основные эксперты",
  ReserveExpert: "Запасной эксперт",
  ChooseReserveExpert: "Выбрать запасной эксперт",
  PasswordInclude8:
    "Пароль должен включать в себя цифры, буквы и минимум 8 символов",
  numberRows: "количество рядов",
  modifiedFile: "Доработанный файл",
};
export default ru;
