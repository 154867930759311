export const actions = {
    async fetchFeedbackList() {
        const {
            data
        } = await this.$axios.get('/site/feedback/list/');
        return data
    },
    async createFeedback(_, payload) {
        try {
            await this.$axios.post('/site/feedback/create/', payload)
        } catch (e) {
            console.log(e);
        }
    },
    async fetchFeedbackDetail(_, id) {
        const {
            data
        } = await this.$axios.get(`/site/feedback/detail/${id}/`)
        return data
    }
}