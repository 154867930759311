export const state = () => ({
  currentModal: "",
  expertList: [],
});

export const getters = {
  currentModal(state) {
    return state.currentModal;
  },
  expertList(state) {
    return state.expertList;
  },
};

export const mutations = {
  openModal(state, payload) {
    state.currentModal = payload;
  },
  closeModal(state) {
    state.currentModal = "";
  },
  setExpertList(state, payload) {
    state.expertList = payload;
  },
};

export const actions = {
  async action({ commit }, payload) {
    try {
      let res = await this.$axios.post(
        "/admin/application/consideration/create/",
        payload
      );
      commit("closeModal");
      return res;
    } catch (err) {
      return err;
    }
  },
  async send({ commit }, { form }) {
    try {
      let res = await this.$axios.post(
        "/admin/application/consideration/create/",
        { ...form, type: "rejected" }
      );
      commit("closeModal");
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async reject({ commit }, { form }) {
    try {
      let res = await this.$axios.post(
        "/admin/application/consideration/create/",
        { ...form, type: "rejected" }
      );
      commit("closeModal");
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async approve({ commit }, { form }) {
    try {
      let res = await this.$axios.post(
        "/admin/application/consideration/create/",
        { ...form, type: "approved" }
      );
      commit("closeModal");
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
  async fetchExpertList({ commit }, params) {
    try {
      let res = await this.$axios.get("/admin/user/list/", {
        params: {
          ...params,
          role: "technical_expert",
        },
      });
      let list = (res && res.data) || [];
      list = list.map((el) => {
        return {
          ...el,
          value: (el && el.id) || "-",
          text: (el && el.username) || "-",
          label:
            (el && el.username) +
            (el && el.speciality ? ` (${el.speciality})` : ""),
        };
      });
      commit("setExpertList", list);
      return res && res.data;
    } catch (err) {
      return err;
    }
  },
};
