<template>
  <div class="feedback-all">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <popupsFeedback
      v-if="isCreate"
      @close="isCreate = false"
      @save="onCreate"
      :submitStatus="submitStatus"
    />
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("FeedbackList") }}</h3>
      </div>

      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="100px">{{ $t("Theme") }}</th>
              <th width="200px">{{ $t("TableMessageText") }}</th>
              <th width="1%">{{ $t("TableData") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              v-for="(item, index) of feedbackList"
              :key="item.id"
              :to="'/cabinet/staff-reviewer/feedback/' + item.id"
              tag="tr"
              class="link"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.theme }}</td>
              <td>{{ item.message }}</td>
              <td>{{ item && item.created_at | dateFilter }}</td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
    <utilsPagination
      :limit="limit"
      :page="page"
      :count="count"
      @paginate="paginate"
      @onChangeLimit="onChangeLimit"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      submitStatus: "Ok",
      isCreate: false,
      feedbackList: [],
      isLoading: true,
      limit: 10,
      page: 1,
      count: 0,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      await this.getFeedbackList();
      console.log("feedbacks", this.feedbackList);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    offset() {
      return (this.page - 1) * this.limit;
    },
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
      };
      // this.filterTab
      //   ? (params.status = this.filterTab)
      //   : (params.status = [
      //       "rejected_in_consideration",
      //       "returned_in_consideration",
      //       "approved_in_consideration",
      //     ]);
      return params;
    },
  },
  methods: {
    ...mapActions("reviewerFeedback", ["fetchFeedbackList"]),
    paginate(arg) {
      this.page = arg;
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.getFeedbackList();
    },
    async getFeedbackList() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchFeedbackList({
          params: this.params,
        });
        console.log(res);
        this.count = res && res.count;
        this.feedbackList = res && res.results;
        this.isLoading = false;
        console.log(this.feedbackList, "feedback");
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
  },
  watch: {
    page() {
      this.getFeedbackList();
    },
  },
};
</script>

<style></style>
