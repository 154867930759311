export const actions = {
    async createProgram(_, payload) {
        try {
            let res = await this.$axios.post('/admin/program/create/', payload)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async updateProgram(_, { id, form }) {
        try {
            let res = await this.$axios.patch(`/admin/program/${id}/update/`, form)
            if (res && res.data) {
                return true
            } else {
                false
            }
        } catch (err) {
            console.log("error", err)
        }
    },
    async fetchList(_, { params }) {
        try {
            let res = await this.$axios.get('/admin/program/list/', { params })
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async fetchDetail(_, { id }) {
        try {
            let res = await this.$axios.get(`/admin/program/${id}/update`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
    async deleteProgram(_, payload) {
        try {
            let res = await this.$axios.delete(`/admin/program/${payload}/delete/`)
            console.log(res)
        } catch (err) {
            return err
        }
    },
}