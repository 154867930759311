<template>
  <div class="requests">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-title">
      <h3>{{ $t("RequestProgramm") }}</h3>
    </div>
    <div class="requests-programs">
      <b-row>
        <b-col lg="4" md="6" v-for="program in programsList" :key="program.id">
          <router-link
            :to="{
              name: 'cabinetUserApplicationsForm',
              query: { program: program.id },
            }"
            class="program-item"
            v-if="program.has_active_board && !program.has_user_application"
          >
            <div class="program-item__icon">
              <img :src="program.icon" alt="" />
            </div>
            <div class="program-item__title">
              <span>{{ program.title }}</span>
            </div>
          </router-link>
          <div class="program-item disabled" v-else>
            <div class="program-item__icon">
              <img :src="program.icon" alt="" />
            </div>
            <div class="program-item__title">
              <span>{{ program.title }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("RequestMyApp") }}</h3>
      </div>

      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">№</th>
              <th width="1%">ID</th>
              <th width="100px">{{ $t("RequestName") }}</th>
              <th width="200px">{{ $t("RequestProgramm") }}</th>
              <th width="1%">{{ $t("Date") }}</th>
              <th width="1%">{{ $t("RequestStep") }}</th>
              <th width="1%">{{ $t("RequestStatusApp") }}</th>
            </tr>
          </thead>
          <tbody>
            <router-link
              tag="tr"
              :to="`/cabinet/user/applications/${item.id}`"
              class="link"
              v-for="item of applicationList"
              :key="item.id"
            >
              <td>{{ item.id }}</td>
              <td>
                <span class="nowrap">{{ item.reg_number }}</span>
              </td>
              <td>{{ item.title }}</td>

              <td>{{ (item.program && item.program.title) || "" }}</td>
              <td>{{ item.created_at | dateDay }}</td>
              <td>
                <span class="nowrap">
                  {{ getStep(item.status) }} - {{ $t("Stage") }}
                </span>
              </td>
              <td>
                <span class="status-text" :class="item.status">{{
                  getStatus(item.status)
                }}</span>
              </td>
            </router-link>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import statusList from "@/constants/request_status_list";
export default {
  data() {
    return {
      applicationList: [],
      programsList: [],
      params: {},
      isLoading: true,
    };
  },
  async created() {
    try {
      this.isLoading = true;
      this.programsList = await this.$store.dispatch("userPrograms/fetchList");
      this.applicationList = await this.$store.dispatch(
        "userRequest/fetchApplicationList"
      );
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }

    // console.log("Programs", this.programsList);
    // console.log("Aplications", this.applicationList);
  },
  methods: {
    getStep(val) {
      let status = statusList.find((el) => el.key == val);
      console.log(status);
      return status.value > 11 ? 2 : 1;
    },
    getStatus(val) {
      let status = statusList.find((el) => el.key == val);

      return status.title[this.$i18n.locale];
    },
  },
};
</script>

<style></style>
