<template>
  <div class="boards">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ $t("ListOfComponents") }}</h3>
        <button @click="addBoard" class="btn mybtn mybtn-blue">
          {{ $t("Add") }}
        </button>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">ID</th>
              <th width="300px">{{ $t("TableTitle") }}</th>
              <th width="150px">{{ $t("TableStartDate") }}</th>
              <th width="150px">{{ $t("TableEndDate") }}</th>
              <th width="1%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of boardsList" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.program["title_" + $i18n.locale] }}</td>
              <td>{{ item.start_date | dateFilter }}</td>
              <td>{{ item.end_date | dateFilter }}</td>
              <td>
                <div class="table-btns">
                  <button class="btn btn-primary" @click="updateBoard(item.id)">
                    <b-icon icon="pencil"></b-icon>
                  </button>
                  <button class="btn btn-danger" @click="removeBoard(item.id)">
                    <b-icon icon="trash"></b-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <utilsPagination
      :limit="limit"
      :page="page"
      :count="count"
      @paginate="paginate"
      @onChangeLimit="onChangeLimit"
    />
    <popupsBoard :detail="form" v-if="showModal" :close="close" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      showModal: false,
      count: 0,
      limit: 10,
      page: 1,
      boardsList: [],
      form: {},
    };
  },
  computed: {
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
      };
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
  watch: {
    page() {
      this.fetch();
    },
  },
  methods: {
    ...mapActions("adminBoards", ["fetchList", "fetchDetail", "deleteBoard"]),
    addBoard() {
      this.showModal = true;
    },
    close() {
      this.form = {};
      this.showModal = false;
      this.fetch();
    },
    async fetch() {
      try {
        this.isLoading = true;
        let res = await this.fetchList({
          params: this.params,
        });
        // console.log(res)
        this.count = res && res.count;
        this.boardsList = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    paginate(arg) {
      this.page = arg;
    },
    async updateBoard(id) {
      try {
        let res = await this.fetchDetail({ id });
        // console.log(res)
        this.form = res;
        this.showModal = true;
      } catch (err) {
        console.error(err);
      }
    },
    async removeBoard(id) {
      try {
        this.isLoading = true;
        await this.deleteBoard(id);
        // this.$router.go()
        this.fetch();
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.fetch();
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetch();
    this.isLoading = false;
  },
};
</script>

<style scoped>
</style>