var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"requests"},[_c('transition',{attrs:{"name":"loading-fade"}},[(_vm.isLoading)?_c('appLoader'):_vm._e()],1),_c('div',{staticClass:"request-view"},[_c('b-row',[_c('b-col',{attrs:{"xl":"9"}},[_c('div',{staticClass:"request-view-info"},[_c('div',{staticClass:"request-view-info-logo"},[_c('img',{attrs:{"src":(_vm.request && _vm.request.program && _vm.request.program.icon) ||
                require('@/assets/images/programs/medal.svg'),"alt":"not found"}})]),_c('div',{staticClass:"request-view-info-text"},[_c('div',{staticClass:"staff-view-info-header"},[_c('div',{staticClass:"staff-view-header"},[_c('span',{staticClass:"status-text",class:(_vm.status && _vm.status.key) || ''},[_vm._v(_vm._s((_vm.status && _vm.status.title && _vm.status.title[_vm.$i18n.locale]) || ""))]),_c('p',[_c('span',[_vm._v("ID:")]),_vm._v(_vm._s(_vm.request && _vm.request.reg_number))]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("TableData"))+":")]),_vm._v(_vm._s(_vm._f("dateDay")((_vm.request && _vm.request.created_at) || ""))+" ")])]),_c('div',{staticClass:"request-button"})]),_c('div',{staticClass:"request-view-info-body"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("RequestProgramm"))+":")]),_vm._v(" "+_vm._s((_vm.request && _vm.request.program && _vm.request.program.title) || "")+" ")]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("RequestName"))+":")]),_vm._v(" "+_vm._s((_vm.request && _vm.request.title) || "")+" ")])])])]),_c('div',{staticClass:"request-view-wrapper"},[_c('div',{staticClass:"pages-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("RequestData")))]),(
                _vm.status &&
                (_vm.status.key == 'returned_in_consideration' ||
                  _vm.status.key == 'returned_in_consideration_at_second_step')
              )?_c('span',{staticClass:"pages-title__revision"},[_vm._v(_vm._s(_vm.$t("CompletionDate"))+": "),_c('strong',[_vm._v(_vm._s(_vm.request && _vm.request.revision && _vm.request.revision.revision_expire_date .split("-") .reverse() .join(".")))])]):_vm._e()]),_c('div',{staticClass:"request-view-body"},[_c('div',{staticClass:"request-view-body-wrapper"},[_c('div',{staticClass:"request-view-body-wrapper-border",staticStyle:{"height":"calc(100% - (364px / 2))"}}),_c('div',{staticClass:"request-view-body-item active"},[_c('span',{staticClass:"request-view-stage"},[_vm._v("1 "+_vm._s(_vm.$t("RequestStep")))]),_c('span',{staticClass:"request-view-circle"}),_c('div',{staticClass:"request-view-row"},[(_vm.request && _vm.request.information_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestInfoProject")))]),_c('fileView',{attrs:{"name":_vm.request &&
                        _vm.request.information_file &&
                        _vm.request.information_file.name,"extension":_vm.request &&
                        _vm.request.information_file &&
                        _vm.request.information_file.extension,"file_url":_vm.request &&
                        _vm.request.information_file &&
                        _vm.request.information_file.url,"size":_vm.request &&
                        _vm.request.information_file &&
                        _vm.request.information_file.size}})],1):_vm._e(),(_vm.request && _vm.request.revisioned_information_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("modifiedFile")))]),_c('fileView',{attrs:{"name":_vm.request &&
                        _vm.request.revisioned_information_file &&
                        _vm.request.revisioned_information_file.name,"extension":_vm.request &&
                        _vm.request.revisioned_information_file &&
                        _vm.request.revisioned_information_file.extension,"file_url":_vm.request &&
                        _vm.request.revisioned_information_file &&
                        _vm.request.revisioned_information_file.url,"size":_vm.request &&
                        _vm.request.revisioned_information_file &&
                        _vm.request.revisioned_information_file.size}})],1):_vm._e(),_vm._l((_vm.request && _vm.request.files),function(file,index){return _c('div',{key:index,staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestOther"))+" "+_vm._s(index + 1))]),_c('fileView',{attrs:{"name":file && file.attachment && file.attachment.name,"extension":file && file.attachment && file.attachment.extension,"file_url":file && file.attachment && file.attachment.url,"size":file && file.attachment && file.attachment.size}})],1)})],2),(_vm.request.is_editable)?_c('div',{staticClass:"request-btns"},[(
                      (_vm.status &&
                        (_vm.status.key == 'draft' ||
                          _vm.status.key == 'received_application') &&
                        _vm.request.program &&
                        _vm.request.program.is_active) ||
                      _vm.status.key == 'returned_in_consideration' ||
                      _vm.request.status == 'revisioned_application'
                    )?_c('b-button',{staticClass:"mybtn mybtn-blue",on:{"click":_vm.onEdit}},[_vm._v(_vm._s(_vm.$t("RequestEditApp")))]):_vm._e(),(
                      _vm.status &&
                      _vm.status.key == 'draft' &&
                      _vm.request.program &&
                      _vm.request.program.is_active
                    )?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrect},on:{"click":_vm.sendRequest}},[_vm._v(_vm._s(_vm.$t("RequestApply")))]):_vm._e(),(_vm.status && _vm.status.key == 'returned_in_consideration')?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrect},on:{"click":_vm.sendRevisionedRequest}},[_vm._v(_vm._s(_vm.$t("FinishTheRevision")))]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"request-view-body-item",class:{ active: _vm.status && _vm.status.value > 11 }},[_c('span',{staticClass:"request-view-stage"},[_vm._v("2 "+_vm._s(_vm.$t("RequestStep")))]),_c('span',{staticClass:"request-view-circle"}),(
                    _vm.request &&
                    (_vm.request.second_step_information_file ||
                      (_vm.request.second_step_files &&
                        _vm.request.second_step_files.length))
                  )?_c('div',{staticClass:"request-view-row"},[(_vm.request && _vm.request.second_step_information_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestInfoProject")))]),_c('fileView',{attrs:{"name":_vm.request &&
                        _vm.request.second_step_information_file &&
                        _vm.request.second_step_information_file.name,"extension":_vm.request &&
                        _vm.request.second_step_information_file &&
                        _vm.request.second_step_information_file.extension,"file_url":_vm.request &&
                        _vm.request.second_step_information_file &&
                        _vm.request.second_step_information_file.url,"size":_vm.request &&
                        _vm.request.second_step_information_file &&
                        _vm.request.second_step_information_file.size}})],1):_vm._e(),(
                      _vm.request &&
                      _vm.request.revisioned_second_step_information_file
                    )?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("modifiedFile")))]),_c('fileView',{attrs:{"name":_vm.request &&
                        _vm.request.revisioned_second_step_information_file &&
                        _vm.request.revisioned_second_step_information_file.name,"extension":_vm.request &&
                        _vm.request.revisioned_second_step_information_file &&
                        _vm.request.revisioned_second_step_information_file
                          .extension,"file_url":_vm.request &&
                        _vm.request.revisioned_second_step_information_file &&
                        _vm.request.revisioned_second_step_information_file.url,"size":_vm.request &&
                        _vm.request.revisioned_second_step_information_file &&
                        _vm.request.revisioned_second_step_information_file.size}})],1):_vm._e(),_vm._l((_vm.request &&
                    _vm.request.second_step_files),function(file,index){return _c('div',{key:index,staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestOther"))+" "+_vm._s(index + 1))]),_c('fileView',{attrs:{"name":file && file.attachment && file.attachment.name,"extension":file && file.attachment && file.attachment.extension,"file_url":file && file.attachment && file.attachment.url,"size":file && file.attachment && file.attachment.size}})],1)})],2):_c('div',{staticClass:"request-view-body-item-info"},[_c('h3',[_vm._v("2 "+_vm._s(_vm.$t("RequestStep")))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('RequestInfoStep2'))}}),(_vm.status && _vm.status.key == 'first_step_completed')?_c('router-link',{attrs:{"to":("/cabinet/user/applications/form2/?id=" + (_vm.request && _vm.request.id)),"tag":"button"}},[_vm._v(_vm._s(_vm.$t("AddDocument")))]):_c('button',[_vm._v(_vm._s(_vm.$t("AddDocument")))])],1),(
                    _vm.request.is_editable &&
                    _vm.request &&
                    (_vm.request.second_step_information_file ||
                      (_vm.request.second_step_files &&
                        _vm.request.second_step_files.length))
                  )?_c('div',{staticClass:"request-btns"},[(
                      _vm.status &&
                      (_vm.status.key == 'first_step_completed' ||
                        _vm.status.key == 'received_application_at_second_step' ||
                        _vm.status.key ==
                          'returned_in_consideration_at_second_step' ||
                        _vm.status.key == 'revisioned_application_at_second_step')
                    )?_c('b-button',{staticClass:"mybtn mybtn-blue",on:{"click":_vm.onEditSecond}},[_vm._v(_vm._s(_vm.$t("RequestEditApp")))]):_vm._e(),(_vm.status && _vm.status.key == 'first_step_completed')?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrectSecond},on:{"click":_vm.sendRequestSecond}},[_vm._v(_vm._s(_vm.$t("RequestApply")))]):_vm._e(),(
                      _vm.status &&
                      _vm.status.key == 'returned_in_consideration_at_second_step'
                    )?_c('b-button',{staticClass:"mybtn mybtn-blue",attrs:{"disabled":!_vm.isCorrectSecond},on:{"click":_vm.sendRevisionedRequestSecond}},[_vm._v(_vm._s(_vm.$t("FinishTheRevision")))]):_vm._e()],1):_vm._e()])])])])]),_c('b-col',{attrs:{"xl":"3"}},[_c('div',{staticClass:"request-view__side"},[_c('div',{staticClass:"history-dots"},[_c('span',{staticClass:"history-dot",class:{ active: _vm.activeHistoryStep == 1 },on:{"click":function($event){_vm.activeHistoryStep = 1}}}),_c('span',{staticClass:"history-dot",class:{ active: _vm.activeHistoryStep == 2 },on:{"click":function($event){_vm.activeHistoryStep = 2}}})]),_c('div',{staticClass:"pages-title"},[_c('h3',[_vm._v(" "+_vm._s(_vm.activeHistoryStep == 1 ? _vm.$t("RequestStep1") : _vm.$t("RequestStep2"))+" ")])]),(_vm.activeHistoryStep == 1)?_c('ul',_vm._l((_vm.request &&
              _vm.request.status_history &&
              _vm.request.status_history.first_step),function(item){return _c('li',{key:item.id,staticClass:"status-item",class:_vm.getStatusType(item.application_status)},[_c('i',{class:'icon-' + _vm.getStatusType(item.application_status)}),_c('div',{staticClass:"request-view__side-right"},[_c('small',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("dateDay")(item.created_at))+" | "+_vm._s(_vm._f("dateTime")(item.created_at)))]),_c('p',[_vm._v(_vm._s(_vm.getStatus(item.application_status)))]),(
                    item.content_object &&
                    item.content_object.id &&
                    (item.content_object.comment ||
                      item.content_object.attachment) &&
                    !(
                      item.content_object &&
                      item.content_object.author &&
                      item.content_object.author.role == 'technical_expert'
                    )
                  )?_c('span',{on:{"click":function($event){return _vm.openShowComment(item || {})}}},[_vm._v(_vm._s(_vm.getConsiderationType( item.content_object && item.content_object.type ))+" "),_c('i',{staticClass:"icon-long_right"})]):_vm._e()])])}),0):_vm._e(),(_vm.activeHistoryStep == 2)?_c('ul',_vm._l((_vm.request &&
              _vm.request.status_history &&
              _vm.request.status_history.second_step),function(item){return _c('li',{key:item.id,staticClass:"status-item",class:_vm.getStatusType(item.application_status)},[_c('i',{class:'icon-' + _vm.getStatusType(item.application_status)}),_c('div',{staticClass:"request-view__side-right"},[_c('small',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("dateDay")(item.created_at))+" | "+_vm._s(_vm._f("dateTime")(item.created_at)))]),_c('p',[_vm._v(_vm._s(_vm.getStatus(item.application_status)))]),(
                    item.content_object &&
                    item.content_object.id &&
                    (item.content_object.comment ||
                      item.content_object.attachment) &&
                    !(
                      item.content_object &&
                      item.content_object.author &&
                      item.content_object.author.role == 'technical_expert'
                    )
                  )?_c('span',{on:{"click":function($event){return _vm.openShowComment(item || {})}}},[_vm._v(_vm._s(_vm.getConsiderationType( item.content_object && item.content_object.type ))+" "),_c('i',{staticClass:"icon-long_right"})]):_vm._e()])])}),0):_vm._e()])])],1)],1),(_vm.showComment)?_c('popupsStatusComment',{attrs:{"commentInfo":_vm.commentInfo,"close":_vm.closeComment}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }