var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"requests"},[_c('transition',{attrs:{"name":"loading-fade"}},[(_vm.isLoading)?_c('appLoader'):_vm._e()],1),_c('div',{staticClass:"request-view"},[_c('div',{staticClass:"request-view-info"},[_c('div',{staticClass:"request-view-info-logo"},[_c('img',{attrs:{"src":(_vm.request && _vm.request.program && _vm.request.program.icon) ||
            require('@/assets/images/programs/medal.svg'),"alt":"not found"}})]),_c('div',{staticClass:"request-view-info-text"},[_c('div',{staticClass:"staff-view-info-header"},[_c('div',{staticClass:"staff-view-header"},[_c('span',{staticClass:"status-text",class:(_vm.status && _vm.status.key) || ''},[_vm._v(_vm._s((_vm.status && _vm.status.title && _vm.status.title[_vm.$i18n.locale]) || ""))]),_c('p',[_c('span',[_vm._v("ID:")]),_vm._v(_vm._s(_vm.request && _vm.request.reg_number))]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("TableData"))+":")]),_vm._v(_vm._s(_vm._f("dateDay")((_vm.request && _vm.request.created_at) || ""))+" ")])]),_c('div',{staticClass:"request-button"})]),_c('div',{staticClass:"request-view-info-body"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("RequestProgramm"))+":")]),_vm._v(" "+_vm._s((_vm.request && _vm.request.program && _vm.request.program.title) || "")+" ")]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("TableTitle"))+":")]),_vm._v(" "+_vm._s((_vm.request && _vm.request.title) || "")+" ")])])])]),_c('div',{staticClass:"request-view-wrapper"},[_c('div',{staticClass:"pages-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("RequestData")))])]),_c('div',{staticClass:"request-view-body"},[_c('div',{staticClass:"request-view-body-wrapper"},[_c('div',{staticClass:"request-view-body-wrapper-border",staticStyle:{"height":"calc(100% - (364px / 2))"}}),_c('div',{staticClass:"request-view-body-item active"},[_c('span',{staticClass:"request-view-stage"},[_vm._v("1 "+_vm._s(_vm.$t("RequestStep")))]),_c('span',{staticClass:"request-view-circle"}),_c('div',{staticClass:"request-view-body-buttons"},[(
                  _vm.request &&
                  _vm.request.application_consideration &&
                  _vm.request.application_consideration.first_step
                )?_c('button',{staticClass:"t-gray-btn",class:_vm.request &&
                  _vm.request.application_consideration &&
                  _vm.request.application_consideration.first_step &&
                  _vm.request.application_consideration.first_step.type.includes(
                    'approved'
                  )
                    ? 'approved'
                    : 'rejected',on:{"click":_vm.openFirstModal}},[_vm._v(" "+_vm._s(_vm.$t("ViewTheAnswer"))+" ")]):_vm._e()]),_c('div',{staticClass:"request-view-row"},[(_vm.request && _vm.request.pasport_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestPassportProject")))]),_c('fileView',{attrs:{"name":_vm.request &&
                    _vm.request.pasport_file &&
                    _vm.request.pasport_file.name,"extension":_vm.request &&
                    _vm.request.pasport_file &&
                    _vm.request.pasport_file.extension,"file_url":_vm.request &&
                    _vm.request.pasport_file &&
                    _vm.request.pasport_file.url,"size":_vm.request &&
                    _vm.request.pasport_file &&
                    _vm.request.pasport_file.size}})],1):_vm._e(),(_vm.request && _vm.request.information_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestInfoProject")))]),_c('fileView',{attrs:{"name":_vm.request &&
                    _vm.request.information_file &&
                    _vm.request.information_file.name,"extension":_vm.request &&
                    _vm.request.information_file &&
                    _vm.request.information_file.extension,"file_url":_vm.request &&
                    _vm.request.information_file &&
                    _vm.request.information_file.url,"size":_vm.request &&
                    _vm.request.information_file &&
                    _vm.request.information_file.size}})],1):_vm._e(),_vm._l((_vm.request && _vm.request.files),function(file,index){return _c('div',{key:index,staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestOther"))+" "+_vm._s(index + 1))]),_c('fileView',{attrs:{"name":file && file.attachment && file.attachment.name,"extension":file && file.attachment && file.attachment.extension,"file_url":file && file.attachment && file.attachment.url,"size":file && file.attachment && file.attachment.size}})],1)})],2)]),(
              _vm.getStatusStep(_vm.status && _vm.status.key) == 2 &&
              _vm.request &&
              _vm.request.application_consideration &&
              _vm.request.application_consideration.second_step
            )?_c('div',{staticClass:"request-view-body-item",class:{ active: _vm.getStatusStep(_vm.status && _vm.status.key) == 2 }},[_c('span',{staticClass:"request-view-stage"},[_vm._v("2 "+_vm._s(_vm.$t("RequestStep")))]),_c('span',{staticClass:"request-view-circle"}),_c('div',{staticClass:"request-view-body-buttons"},[(
                  _vm.request &&
                  _vm.request.application_consideration &&
                  _vm.request.application_consideration.second_step
                )?_c('button',{staticClass:"t-gray-btn",class:_vm.request &&
                  _vm.request.application_consideration &&
                  _vm.request.application_consideration.second_step &&
                  _vm.request.application_consideration.second_step.type.includes(
                    'approved'
                  )
                    ? 'approved'
                    : 'rejected',on:{"click":_vm.openSecondModal}},[_vm._v(" "+_vm._s(_vm.$t("ViewTheAnswer"))+" ")]):_vm._e()]),(_vm.request && _vm.request.second_step_information_file)?_c('div',{staticClass:"request-view-row"},[(_vm.request && _vm.request.second_step_information_file)?_c('div',{staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestInfoProject")))]),_c('fileView',{attrs:{"name":_vm.request &&
                    _vm.request.second_step_information_file &&
                    _vm.request.second_step_information_file.name,"extension":_vm.request &&
                    _vm.request.second_step_information_file &&
                    _vm.request.second_step_information_file.extension,"file_url":_vm.request &&
                    _vm.request.second_step_information_file &&
                    _vm.request.second_step_information_file.url,"size":_vm.request &&
                    _vm.request.second_step_information_file &&
                    _vm.request.second_step_information_file.size}})],1):_vm._e(),_vm._l((_vm.request && _vm.request.second_step_files),function(file,index){return _c('div',{key:index,staticClass:"request-view-col"},[_c('label',[_vm._v(_vm._s(_vm.$t("RequestOther"))+" "+_vm._s(index + 1))]),_c('fileView',{attrs:{"name":file && file.attachment && file.attachment.name,"extension":file && file.attachment && file.attachment.extension,"file_url":file && file.attachment && file.attachment.url,"size":file && file.attachment && file.attachment.size}})],1)})],2):_vm._e()]):_vm._e()])]),(
          _vm.request &&
          _vm.request.application_consideration &&
          _vm.request.application_consideration.type ==
            'rejected_by_leading_international_expert'
        )?_c('div',{staticClass:"application-decision rejected"},[_vm._m(0),_c('div',{staticClass:"application-decision__right"},[_c('b-button',{staticClass:"mybtn mybtn-red",on:{"click":function($event){return _vm.openModal('rejected')}}},[_vm._v(_vm._s(_vm.$t("InformationResponse")))])],1)]):_vm._e(),(
          _vm.request &&
          _vm.request.application_consideration &&
          _vm.request.application_consideration.type ==
            'approved_by_leading_international_expert'
        )?_c('div',{staticClass:"application-decision approved"},[_vm._m(1),_c('div',{staticClass:"application-decision__right"},[_c('b-button',{staticClass:"mybtn mybtn-green",on:{"click":function($event){return _vm.openModal('approved')}}},[_vm._v(_vm._s(_vm.$t("InformationResponse")))])],1)]):_vm._e()])]),(_vm.rejected || _vm.approved)?_c('popupsAnswerInformation',{attrs:{"title":_vm.title,"commentInfo":_vm.modalInfo,"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-decision__left"},[_c('div',{staticClass:"application-decision__icon"},[_c('i',{staticClass:"icon-close"})]),_c('div',{staticClass:"application-decision__title"},[_c('h2',[_vm._v("Заявка отклонена")]),_c('p',[_vm._v("Подождите, пока наш администратор свяжется с вами")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"application-decision__left"},[_c('div',{staticClass:"application-decision__icon"},[_c('i',{staticClass:"icon-check"})]),_c('div',{staticClass:"application-decision__title"},[_c('h2',[_vm._v("Заявка одобрена")]),_c('p',[_vm._v("Подождите, пока наш администратор свяжется с вами")])])])}]

export { render, staticRenderFns }