var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup"},[_c('div',{staticClass:"popup-main popup-main__comment",class:_vm.commentInfo &&
      _vm.commentInfo.content_object &&
      _vm.commentInfo.content_object.type},[_c('div',{staticClass:"popup-header"},[_c('h3',{staticClass:"popup-title",class:_vm.getClass(
            _vm.commentInfo &&
              _vm.commentInfo.content_object &&
              _vm.commentInfo.content_object.type
          )},[_vm._v(" "+_vm._s(_vm.getStatus(_vm.commentInfo && _vm.commentInfo.application_status))+" ")]),_c('i',{staticClass:"icon-close",on:{"click":_vm.close}})]),_c('div',{staticClass:"popup-body"},[(
          _vm.commentInfo &&
          _vm.commentInfo.content_object &&
          _vm.commentInfo.content_object.comment
        )?_c('div',{staticClass:"popup-body__comment"},[_c('span',[_vm._v(_vm._s(_vm.$t("TableMessageText")))]),_c('p',[_vm._v(" "+_vm._s(_vm.commentInfo && _vm.commentInfo.content_object && _vm.commentInfo.content_object.comment)+" ")])]):_vm._e(),(
          _vm.commentInfo &&
          _vm.commentInfo.content_object &&
          _vm.commentInfo.content_object.attachment
        )?_c('div',{staticClass:"popup-body__comment"},[_c('span',[_vm._v(_vm._s(_vm.$t("AttachedDocument")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('fileView',{attrs:{"name":(_vm.commentInfo &&
                  _vm.commentInfo.content_object &&
                  _vm.commentInfo.content_object.attachment &&
                  _vm.commentInfo.content_object.attachment.name) ||
                '',"extension":(_vm.commentInfo &&
                  _vm.commentInfo.content_object &&
                  _vm.commentInfo.content_object.attachment &&
                  _vm.commentInfo.content_object.attachment.extension) ||
                '',"file_url":(_vm.commentInfo &&
                  _vm.commentInfo.content_object &&
                  _vm.commentInfo.content_object.attachment &&
                  _vm.commentInfo.content_object.attachment.url) ||
                '',"size":(_vm.commentInfo &&
                  _vm.commentInfo.content_object &&
                  _vm.commentInfo.content_object.attachment &&
                  _vm.commentInfo.content_object.attachment.size) ||
                ''}})],1)])]):_vm._e(),_c('div',{staticClass:"popup-body__comment__footer"},[_c('span',[_vm._v(_vm._s(_vm.$t("time"))+": "+_vm._s(_vm._f("dateTime")(_vm.commentInfo && _vm.commentInfo.content_object && _vm.commentInfo.content_object.created_at))+" / "+_vm._s(_vm._f("dateDay")(_vm.commentInfo && _vm.commentInfo.content_object && _vm.commentInfo.content_object.created_at)))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }