<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("CompleteStep", { step }) }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form-group">
          <label
            >{{ $t("MessageText") }}<span style="color: red">*</span></label
          >
          <b-form-textarea
            v-model="form.message"
            id="textarea-rows"
            :placeholder="$t('EnterText')"
            rows="8"
          ></b-form-textarea>
        </div>
        <div class="form_footer_custom">
          <b-button @click="onSubmit" class="mybtn mybtn-blue">{{
            $t("Send")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        step: 1,
        message: "",
      },
    };
  },
  mounted() {
    if (this.step == 1) {
      this.form.step = 1;
    } else {
      this.form.step = 2;
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const data = this.form;
        const f = new FormData();
        Object.keys(data).forEach((k) => {
          f.append(k, data[k]);
        });
        const res = await this.$axios.post(
          "/admin/application/step/completion/create/",
          f
        );
        this.isLoading = false;
        if (res.status == 200 || res.status == 201) {
          this.$toast.success(this.$t("successText"));
          this.close();
          if (this.step == 1) {
            this.$router.push(
              "/cabinet/staff-reviewer/verified-applications?status=first_step_completed"
            );
          } else {
            this.$router.push(
              "/cabinet/staff-reviewer/verified-applications?status=winned_application"
            );
          }
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
