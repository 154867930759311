<template>
  <div class="employees">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="pages-head">
      <div class="pages-head__tabs">
        <a
          class="pages-head__tab"
          :class="{ active: role == 'applicant_physical' }"
          href="#"
          @click.prevent="role = 'applicant_physical'"
          >{{ $t("NaturalPerson") }}</a
        >
        <a
          class="pages-head__tab"
          :class="{ active: role == 'applicant_legal' }"
          href="#"
          @click.prevent="role = 'applicant_legal'"
          >{{ $t("JuridicalPerson") }}</a
        >
      </div>
      <div class="user-check">
        <b-form-group>
          <b-form-checkbox-group
            id="roleSelect"
            v-model="roleSelected"
            :options="rolesOptions"
            name="role_select"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <div class="pages-main">
      <div class="pages-title">
        <h3>{{ title }}</h3>
      </div>
      <div class="table">
        <table>
          <thead>
            <tr>
              <th width="1%">ID</th>
              <th width="240px">{{ $t("FIO") }}</th>
              <th width="150px">{{ $t("User.Role") }}</th>
              <th width="200px">{{ $t("Telephone") }}</th>
              <th width="200px">{{ $t("User.Email") }}</th>
              <th width="1%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) of users" :key="user.id">
              <td>{{ (page - 1) * limit + i + 1 }}</td>
              <td>
                {{ user.last_name }}
                {{ user.first_name }}
                {{ user.middle_name }}
              </td>
              <td>
                {{
                  user.role == "applicant_physical"
                    ? $t("NaturalPerson")
                    : $t("JuridicalPerson")
                }}
              </td>
              <td>{{ user.phone }}</td>
              <td>{{ user.email }}</td>
              <td>
                <div class="table-btns">
                  <router-link
                    :to="`/cabinet/admin/users/${user.id}/`"
                    class="btn btn-primary"
                  >
                    <b-icon icon="eye"></b-icon>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <utilsPagination
      :limit="limit"
      :page="page"
      :count="count"
      @paginate="paginate"
      @onChangeLimit="onChangeLimit"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      users: [],
      role: null,
      count: 0,
      limit: 10,
      page: 1,
      // roleSelected: ['applicant_physical', 'applicant_legal'], // Must be an array reference!
      roleSelected: [], // Must be an array reference!
      rolesOptions: [
        { text: this.$t("NaturalPerson"), value: "applicant_physical" },
        { text: this.$t("JuridicalPerson"), value: "applicant_legal" },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    await this.fetch();
    this.isLoading = false;
    this.getAuthForm();
  },
  methods: {
    ...mapActions("adminUsers", ["fetchUsers"]),
    async fetch() {
      try {
        this.isLoading = true;
        let res = await this.fetchUsers(this.params);
        // console.log(res);
        this.count = res && res.count;
        this.users = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.fetch();
    },
    paginate(arg) {
      this.page = arg;
    },
    async changePositions() {
      let data = {
        applicant_physical_field:
          this.roleSelected.includes("applicant_physical"),
        applicant_legal_field: this.roleSelected.includes("applicant_legal"),
      };
      await this.$axios.patch(`/admin/user/authform/`, data);
    },
    async getAuthForm() {
      let res = await this.$axios.get(`/admin/user/authform/`);
      let data = res && res.data;
      data.applicant_physical_field &&
        this.roleSelected.push("applicant_physical");
      data.applicant_legal_field && this.roleSelected.push("applicant_legal");
    },
  },
  watch: {
    async role() {
      this.page = 1;
      this.fetch();
    },
    async page() {
      this.fetch();
    },
    roleSelected() {
      this.changePositions();
    },
  },
  computed: {
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
        role: this.role,
      };
      return params;
    },
    title() {
      return this.role == "applicant_physical"
        ? this.$t("NaturalPerson")
        : this.role == "applicant_legal"
        ? this.$t("JuridicalPerson")
        : this.$t("All");
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
  },
};
</script>

<style scoped>
</style>