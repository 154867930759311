export const actions = {
  async fetchAllRegions() {
    let { data } = await this.$axios.get("/admin/common/region/list/");
    data = data.map((i) => ({ value: i.id, text: i.title }));
    return data;
  },
  async fetchAllOrgTypes() {
    let { data } = await this.$axios.get("/site/common/typeorganization/list/");
    data = data.map((i) => ({ value: i.id, text: i.title }));
    return data;
  },
  async fetchAllPrograms() {
    let { data } = await this.$axios.get("/admin/program/list/");
    data = data.map((i) => ({ value: i.id, text: i.title }));
    return data;
  },
  async fetchUserDetail() {
    const { data } = await this.$axios.get("/site/user/me/detail/");
    return data;
  },
  async fetchAdminDetail() {
    const { data } = await this.$axios.get("/site/user/me/detail/");
    return data;
  },
  async updateUserDetail(_, { form }) {
    try {
      const { data } = await this.$axios.patch("", form);
      return data;
    } catch (err) {
      return err;
    }
  },
  async fetchNotifications(_, params) {
    const { data } = await this.$axios.get("/site/notification/list/", {
      params,
    });
    return data;
  },
  async checkUser(_, params) {
    try {
      const { data } = await this.$axios.get("/general/check-username/", {
        params,
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};
