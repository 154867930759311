<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("Catalog") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="popup-reports-form">
          <utilsLangTab @changeLang="onChangeLang" />
          <div class="form-group">
            <label for="">{{ $t("Title") }}({{ currLang }})</label>
            <b-form-input
              id=""
              v-model="form['title_' + currLang]"
              placeholder=""
            ></b-form-input>
          </div>
          <div v-if="currTab == 'district'" class="form-group">
            <label for="">{{ $t("Region") }}</label>
            <b-form-select
              v-model="form.region"
              :options="regionList"
            ></b-form-select>
          </div>
          <div v-if="currTab == 'district'" class="form-group">
            <label for="">{{ $t("Order") }}</label>
            <b-form-input
              id=""
              v-model="form.order"
              type="number"
              placeholder=""
            ></b-form-input>
          </div>

          <div class="form-group">
            <b-button class="mybtn mybtn-green" @click="createDetail(form)">{{
              $t("Save")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    close: {
      type: Function,
      default() {
        return null;
      },
    },
    createDetail: {
      type: Function,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.currTab = this.$route.query && this.$route.query.tab;
    this.currTab == "district" && this.fetchRegionList();
  },
  data() {
    return {
      form: {
        title_en: "",
        title_oz: "",
        title_ru: "",
        title_uz: "",
        order: null,
      },
      currLang: this.$i18n.locale,
      currTab: "",
      regionList: [],
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("adminCatalog", ["fetchListByTabName"]),
    onChangeLang(e) {
      this.currLang = e;
    },
    async fetchRegionList() {
      try {
        this.isLoading = true;
        let res = await this.fetchListByTabName({
          tab: "region",
          params: {
            limit: 30,
            offset: 0,
          },
        });
        this.regionList =
          res &&
          res.results &&
          res.results.map((el) => {
            return {
              value: el.id,
              text: el.title,
            };
          });
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>