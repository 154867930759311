<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("ForgotTitle") }}</h2>
    </div>
    <form @keyup.enter="resPassword" class="auth-block__form">
      <div class="form-group">
        <label for="password"
          >{{ $t("User.Password") }} <span class="red">*</span></label
        >
        <!-- <b-form-input id="password" type="password" v-model="form.password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.password" :hasTitle="false" />
      </div>
      <div class="form-group">
        <label for="confirm_password"
          >{{ $t("FormConfirmPassword") }} <span class="red">*</span></label
        >
        <!-- <b-form-input type="password" id="confirm_password" v-model="form.confirm_password" placeholder="Введите пароль"></b-form-input> -->
        <utilsPasswordInput v-model="form.confirm_password" :hasTitle="false" />
      </div>
      <div class="form-btns">
        <b-button
          @click="resPassword"
          :disabled="!isPasswordConfirmed"
          variant="primary"
          >{{ $t("Confirmation") }}</b-button
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    extraFields: {
      type: Object,
      default() {
        return {};
      },
    },
    phone: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        password: null,
        confirm_password: null,
      },
    };
  },
  computed: {
    isPasswordConfirmed() {
      return (
        this.form.password &&
        this.form.confirm_password &&
        this.form.password == this.form.confirm_password
      );
    },
  },
  mounted() {
    console.log(this.extraFields, this.phone);
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),
    async resPassword() {
      try {
        this.isLoading = true;
        let params = {
          ...this.extraFields,
          password: this.form.password,
          phone: "998" + this.phone,
        };
        this.form.legal_name && (params.legal_name = this.form.legal_name);
        let res = await this.resetPassword(params);
        res == "username found"
          ? (this.username_error = true)
          : this.$router.go();
        this.isLoading = false;
        // this.smartRouter({
        //     access: res && res.access_token,
        //     refresh: res && res.refresh_token
        // })
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>