<template >
  <div class="header-notification">
    <b-dropdown
      id="dropdown-2"
      v-if="notifications.length"
      :text="((notifications && notifications.length) || 0).toString()"
    >
      <b-dropdown-item
        v-for="item of notifications"
        :to="getRoute(item)"
        :key="item.id"
        ><div v-html="item.text"></div
      ></b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item :to="getViewAllRoute()" class="dropdown-item__allview">
        {{ $t("ShowAll") }}
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown id="dropdown-3" v-else text="0">
      <b-dropdown-item disabled>{{
        $t("emptyNotificationsList")
      }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  props: {
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      this.notifications = await this.$store.dispatch(
        "app/fetchNotifications",
        {
          is_read: false,
        }
      );
    },
    getRoute(val) {
      if (this.role == "applicant_physical" || this.role == "applicant_legal") {
        if (
          val &&
          val.content_object &&
          val.content_object.class_name.includes("Application")
        ) {
          return `/cabinet/user/applications/${
            val.content_object &&
            val.content_object.application &&
            val.content_object.application.id
          }`;
        } else if (
          val &&
          val.content_object &&
          val.content_object.class_name == "FeedbackAnswer"
        ) {
          return `/cabinet/user/feedback/${
            val.content_object && val.content_object.feedback
          }`;
        }
      } else {
        if (
          val &&
          val.content_object &&
          val.content_object.status == "received_application"
        ) {
          return `/cabinet/staff-reviewer/new-applications/${val.content_object.id}`;
        } else if (
          val &&
          val.content_object &&
          val.content_object.class_name == "Feedback"
        ) {
          return `/cabinet/staff-reviewer/feedback/${val.content_object.id}`;
        } else {
          return `/cabinet/staff-reviewer/verified-applications/${val.content_object.id}`;
        }
      }
    },
    getViewAllRoute() {
      console.log("sss");
      if (this.role == "applicant_physical" || this.role == "applicant_legal") {
        return "/cabinet/user/notifications";
      } else {
        return "/cabinet/staff-reviewer/notifications";
      }
    },
  },
  watch: {
    $route() {
      this.getNotifications();
    },
  },
};
</script>
<style >
</style>