<template>
  <div class="popup">
    <div class="popup-main">
      <transition name="loading-fade">
        <appLoader v-if="isLoading" />
      </transition>
      <div class="popup-header">
        <h3 class="popup-title">{{ $t("Reject") }}</h3>
        <i @click="close" class="icon-close"></i>
      </div>
      <div class="popup-body">
        <div class="form-group">
          <label
            >{{ $t("TableMessageText")
            }}<span style="color: red">*</span></label
          >
          <b-form-textarea
            v-model="form.comment"
            id="textarea-rows"
            :placeholder="$t('EnterText')"
            rows="8"
          ></b-form-textarea>
        </div>
        <div class="form-group">
          <label for="">{{ $t("AttachDocument") }}</label>
          <fileUploader
            name="reviewer_new_application_send_form"
            @getFiles="getFiles"
          />
        </div>
        <div class="form_footer_custom">
          <b-button @click="rejectForm" class="mybtn mybtn-red">{{
            $t("Reject")
          }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    step: {
      type: Number,
      required: true,
      default: 1,
    },
    role: {
      type: String,
      required: true,
      default: "technical",
    },
  },
  data() {
    return {
      form: {
        comment: "",
        type: "",
        attachment: null,
      },
      isLoading: false,
    };
  },
  mounted() {
    if (this.role == "international") {
      if (this.step == 1) {
        this.form.type = "rejected_by_leading_international_expert";
      } else {
        this.form.type =
          "rejected_by_leading_international_expert_at_second_step";
      }
    } else {
      if (this.step == 1) {
        this.form.type = "rejected_by_technical_expert";
      } else {
        this.form.type = "rejected_by_technical_expert_at_second_step";
      }
    }
  },
  methods: {
    getFiles(arg) {
      // console.log('arg', arg)
      this.form.attachment = arg && arg.file;
    },
    async rejectForm() {
      try {
        this.isLoading = true;
        const data = { ...this.form, application: this.$route.params.id };
        const f = new FormData();
        Object.keys(data).forEach((k) => {
          k == "attachment" && data[k] && f.append(k, data[k], data[k].name);
          k != "attachment" && f.append(k, data[k]);
        });
        const res = await this.$axios.post(
          "/admin/application/consideration/create/",
          f
        );
        if (res.status == 200 || res.status == 201) {
          this.$toast.success(this.$t("successText"));
          this.close();
          if (this.role == "international") {
            this.$router.push(
              "/cabinet/international-expert/verified-applications"
            );
          } else {
            this.$router.push(
              "/cabinet/technical-expert/verified-applications"
            );
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.form_footer_custom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form_custom_textarea {
  height: 310px;
}
</style>
