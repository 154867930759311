<template>
  <div>
    <b-form-input
      v-if="componentData.name == 'input' && componentData.type != 'phone'"
      v-model="model"
      :type="componentData.type"
    />
    <div
      v-else-if="componentData.name == 'input' && componentData.type == 'phone'"
      class="input-phone"
    >
      <div class="group-phone__first">+998</div>
      <b-form-input
        id="phone"
        type="text"
        maxLength="9"
        v-model="model"
      ></b-form-input>
    </div>
    <!-- <b-input-group v-else-if="componentData.name == 'input' && componentData.type == 'date'"  class="mb-3">
            <b-form-input 
                id="example-input"
                v-model="model"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
                <b-form-datepicker
                v-model="model"
                button-only
                right
                locale="en-US"
                aria-controls="example-input"
                @context="onContext"
                ></b-form-datepicker>
            </b-input-group-append>
        </b-input-group>        -->
    <b-form-select
      v-else-if="componentData.name == 'select'"
      v-model="model"
      :options="componentData.options"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    componentData: {
      type: [Object, Array, String, Boolean, Number],
      required: true,
    },
  },
  data() {
    return {
      model: null,
    };
  },
  watch: {
    model(newVal) {
      console.log(this.componentData);
      if (this.componentData.type == "phone") {
        let numbers = newVal
          .split("")
          .filter((el) => el.charCodeAt(0) > 47 && el.charCodeAt(0) < 58);
        console.log(numbers, newVal.length);
        if (numbers.length == newVal.length) {
          this.$emit("change", newVal);
        } else {
          this.model = numbers.join("");
        }
      }
      console.log(this.model);
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>

<style>
</style>