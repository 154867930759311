<template>
  <div id="app">
    <router-view />
    <popupsReports v-if="getReportsPopup" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters("auth", ["getReportsPopup"]),
  },
  // mounted() {
  //   console.log(this.getReportsPopup, 'toxa');
  // },
};
</script>

<style>
#app {
}
</style>
