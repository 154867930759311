<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("Authorization") }}</h2>
    </div>
    <form @keyup.enter="submit" class="auth-block__form">
      <p v-if="user_not_found" style="color: red; text-align: center;" >{{ $t("errorLogin") }}</p>
      <div class="form-group">
        <label for="username"
          >{{ $t("Username") }} <span class="red">*</span></label
        >
        <b-form-input
        id="username"
        v-model="form.username"
        :class="{
            invalid: $v.form.username.$dirty && !$v.form.username.required,
        }"
        :placeholder="$t('errorUsername')"
        ></b-form-input>
        <small
          class="error-text"
          v-if="$v.form.username.$dirty && !$v.form.username.required"
          >{{ $t("errorUsername") }}</small
        >
      </div>
      <div class="form-group">
        <label for="password"
          >{{ $t("Password") }} <span class="red">*</span></label
        >
        <!-- <b-form-input
          id="password"
          type="password"
          v-model="form.password"
          :placeholder="$t('errorPassword')"
        ></b-form-input> -->
        <utilsPasswordInput v-model="form.password" :hasTitle="false"/>
        <small
          class="error-text"
          v-if="
            ($v.form.password.$dirty && !$v.form.password.required) ||
            ($v.form.password.$dirty && !$v.form.password.minLength)
          "
          >{{ $t("errorPassword") }}</small
        >
      </div>
      <div class="form-btns">
        <b-button variant="primary" @click.prevent="submit">{{
          $t("Login")
        }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isLoading: false,
      submitStatus: null,
      form: {
        username: null,
        password: null,
      },
      user_not_found: false
    };
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength,
      },
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      // console.dir(this);
      // console.log("submit!");
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return
      } 
        this.submitStatus = "PENDING";
        // do your submit logic here
        try {
          this.isLoading = true;
          let res = await this.login({username: this.form.username, password: this.form.password})
          this.user_not_found = res;
          this.isLoading = false;
        } catch(err) {
          this.user_not_found = true
          console.error(err);
          this.isLoading = false;
        }
      
    },
  },
};
</script>

<style scoped>
.error-text {
  color: red;
}
</style>