export const actions = {
    async fetchApplicationList() {
        const { data } = await this.$axios.get("/admin/application/list/");
        return data;
    },
    async fetchAplicationDetail(_, payload) {
        try {
            let res = await this.$axios.get(`/admin/application/detail/${payload}/`)
            return res && res.data
        } catch (err) {
            return err
        }
    },
};