<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("FormRegister") }}</h2>
    </div>
    <form @keyup.enter="verifySms" class="auth-block__form">
      <div class="form-group" v-if="userType == 'legal'">
        <label for="org_name"
          >{{ $t("User.LegalName") }} <span class="red">*</span></label
        >
        <b-form-input
          id="org_name"
          v-model="form.org_name"
          :placeholder="$t('errorPassword')"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="phone"
          >{{ $t("User.Phone") }} <span class="red">*</span></label
        >
        <div class="input-phone">
          <div class="input-phone__first">+998</div>
          <b-form-input id="phone" v-model="form.phone" disabled></b-form-input>
        </div>
      </div>
      <div class="form-group">
        <label for="confirm_code"
          >{{ $t("FormCodeFromSMS") }} <span class="red">*</span></label
        >
        <b-form-input
          id="confirm_code"
          v-model="form.confirm_code"
          :placeholder="$t('SMSCode')"
        ></b-form-input>
        <small v-if="timer > 0" class="form-text">{{
          $t("SendTheCodeAgainIn", { timer })
        }}</small>
        <small
          @click="resendSmsCode"
          v-else
          class="form-text"
          style="color: #2e7df6; cursor: pointer"
          >{{ $t("SendTheCodeAgain") }}</small
        >
        <p v-if="sms_code_error" style="color: red">
          {{ $t("WrongSmsCode") }}
        </p>
      </div>
      <div class="form-btns">
        <b-button @click="verifySms" variant="primary">{{
          $t("Continue")
        }}</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    phone: {
      type: [String, Number],
      default() {
        return null;
      },
    },
    token: {
      type: [String, Number],
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      userType: "normal",
      form: {
        org_name: "Online Service Group",
        phone: "",
        confirm_code: null,
        token: null,
      },
      timer: 60,
      sms_code_error: false,
    };
  },
  mounted() {
    this.form.phone = this.phone;
    this.form.token = this.token;
    let timer = setInterval(() => {
      this.timer--;
      this.timer == 0 && clearInterval(timer);
    }, 1000);
  },
  methods: {
    ...mapActions("auth", ["verifySmsCode", "registrationStep1"]),
    async verifySms() {
      try {
        this.isLoading = true;
        let res = await this.verifySmsCode({
          token: this.form.token,
          sms_code: this.form.confirm_code,
        });
        res && res.token
          ? this.$emit("smsVerified", {
              code: res && res.code,
              token: res && res.token,
            })
          : (this.sms_code_error = true);
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    async resendSmsCode() {
      try {
        this.isLoading = true;
        let res = await this.registrationStep1({ phone: "+998" + this.phone });
        // console.log(res)
        this.$emit("resendCode", res && res.token);
        this.timer = 60;
        this.setTimer();
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    setTimer() {
      let timer = setInterval(() => {
        this.timer--;
        this.timer == 0 && clearInterval(timer);
      }, 1000);
    },
  },
};
</script>

<style scoped>
</style>