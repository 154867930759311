
<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("Authorization") }}</h2>
    </div>
    <div v-if="!notTab" class="auth-block__nav">
      <a
        v-if="authForm.applicant_physical_field"
        href="#"
        class="auth-block__nav--item"
        :class="{ active: userType == 'natural' }"
        @click.prevent="userType = 'natural'"
        >{{ $t("NaturalPerson") }}</a
      >
      <a
        v-if="authForm.applicant_legal_field"
        href="#"
        class="auth-block__nav--item"
        :class="{ active: userType == 'legal' }"
        @click.prevent="userType = 'legal'"
        >{{ $t("JuridicalPerson") }}</a
      >
    </div>
    <div v-if="false" class="auth-block__via">
      <a href="#" class="auth-block__via--item">
        <img src="@/assets/images/imzo.png" alt="e-imzo" />
      </a>
      <a href="#" class="auth-block__via--item" v-if="userType == 'natural'">
        <img src="@/assets/images/one_id.png" alt="one-id" />
      </a>
    </div>
    <form @keyup.enter="submit" class="auth-block__form">
      <p v-if="user_not_found" style="color: red; text-align: center">
        {{ $t("errorLogin") }}
      </p>
      <div class="form-group">
        <label for="phone"
          >{{ $t("Username") }} <span class="red">*</span></label
        >
        <b-form-input
          id="username"
          v-model="form.username"
          :class="{
            invalid: $v.form.username.$dirty && !$v.form.username.required,
          }"
          :placeholder="$t('errorUsername')"
        ></b-form-input>
        <small
          class="error-text"
          v-if="$v.form.username.$dirty && !$v.form.username.required"
          >{{ $t("errorUsername") }}</small
        >
      </div>
      <div class="form-group">
        <label for="password" class="justify-content-between">
          <span>{{ $t("Password") }} <span class="red">*</span></span>
          <span class="url" @click.prevent="$emit('forgotPassword')">{{
            $t("forgot")
          }}</span>
        </label>
        <!-- <b-form-input
          id="password"
          type="password"
          v-model="form.password"
          :placeholder="$t('errorPassword')"
        ></b-form-input> -->
        <utilsPasswordInput v-model="form.password" :hasTitle="false" />
        <!-- <small
          class="error-text"
          v-if="
            ($v.form.password.$dirty && !$v.form.password.required) ||
            ($v.form.password.$dirty && !$v.form.password.minLength)
          "
          >{{ $t("errorPassword") }}</small
        > -->
      </div>
      <div class="form-btns">
        <b-button
          :disabled="!enoughData"
          variant="primary"
          @click.prevent="submit"
          >{{ $t("Login") }}</b-button
        >
      </div>
      <a href="#" class="form-url">
        <span
          v-if="userType == 'natural'"
          @click="$emit('toNaturalRegister')"
          >{{ $t("FormRegister2") }}</span
        >
        <span v-if="userType == 'legal'" @click="$emit('toLegalRegister')">{{
          $t("FormRegisterLegal")
        }}</span>
      </a>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isLoading: false,
      userType: "natural",
      submitStatus: null,
      form: {
        username: null,
        password: null,
      },
      user_not_found: false,
      authForm: {},
      notTab: false
    };
  },
  computed: {
    enoughData() {
      return this.form.username && this.form.password;
    },
  },
  watch: {
    authForm(newVal) {
       newVal.applicant_physical_field ? (this.userType = 'natural') : newVal.applicant_legal_field ? (this.userType = 'legal') : (this.notTab = true)
    }
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
        minLength,
      },
    },
  },
  mounted() {
    this.getAuthForm()
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return;
      }
      this.submitStatus = "PENDING";
      try {
        this.isLoading = true;
        let res = await this.login({
          username: this.form.username,
          password: this.form.password,
        });
        this.user_not_found = res;
        this.isLoading = false;
      } catch (err) {
        this.user_not_found = true;
        console.error(err);
        this.isLoading = false;
      }
    },
    async getAuthForm() {
      let res = await this.$axios.get(`/site/user/authform/`)
      let data = res && res.data
      this.authForm = data || {}
    }
  },
};
</script>

<style scoped>
.error-text {
  color: red;
}
.justify-content-between {
  display: flex;
  justify-content: space-between;
}
label .url {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2e7df6;
  text-decoration: none;
}
</style>