<template>
  <div class="employees">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="row">
      <div class="col-md-9">
        <div class="pages-main">
          <div class="pages-title">
            <h3>{{ title }}</h3>
            <div class="pages-head__btns">
              <button class="btn mybtn mybtn-blue" @click="addEmployee">
                {{ $t("Add") }}
              </button>
            </div>
          </div>

          <div class="table">
            <table>
              <thead>
                <tr>
                  <th width="1%">ID</th>
                  <th width="300px">{{ $t("FIO") }}</th>
                  <th width="150px">{{ $t("User.Username") }}</th>
                  <th width="150px">{{ $t("Role") }}</th>
                  <template v-if="filterTab == 'technical_expert'">
                    <th width="150px">{{ $t("Specialization") }}</th>
                  </template>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(employee, i) in employeeList"
                  :key="employee.id"
                  :class="{ notActive: !employee.is_active }"
                >
                  <td>{{ (page - 1) * limit + i + 1 }}</td>
                  <td>
                    {{ employee.last_name || "" }}
                    {{ employee.first_name || "" }}
                    {{ employee.middle_name || "" }}
                  </td>
                  <td>{{ employee.username }}</td>
                  <td>{{ employee.role }}</td>
                  <template v-if="filterTab == 'technical_expert'">
                    <th>{{ employee.speciality }}</th>
                  </template>
                  <td>
                    <div class="table-btns">
                      <button
                        @click="updateEmployee(employee.id)"
                        class="btn btn-primary"
                      >
                        <b-icon icon="pencil"></b-icon>
                      </button>
                      <button
                        v-if="employee.is_active && (employee.role != 'admin')"
                        class="btn btn-danger"
                        @click="removeUser(employee.id)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </button>
                      <button
                        v-if="!employee.is_active"
                        class="btn btn-success"
                        @click="recoverUser(employee.id)"
                      >
                        <b-icon icon="arrow-counterclockwise"></b-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <utilsPagination
          :limit="limit"
          :page="page"
          :count="count"
          @paginate="paginate"
          @onChangeLimit="onChangeLimit"
        />
      </div>
      <div class="col-md-3">
        <div class="catalog-side">
          <div class="catalog-side__tabs">
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'admin' }"
              @click="activeFilter('admin')"
            >
              {{ $t("Admin") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'staff_reviewer' }"
              @click="activeFilter('staff_reviewer')"
            >
              {{ $t("EmployeePiu") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'director' }"
              @click="activeFilter('director')"
            >
              {{ $t("Director") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'technical_expert' }"
              @click="activeFilter('technical_expert')"
            >
              {{ $t("TechnicalExpert") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'international_expert' }"
              @click="activeFilter('international_expert')"
            >
              {{ $t("InternationalExpert") }}
            </div>
            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'leading_international_expert' }"
              @click="activeFilter('leading_international_expert')"
            >
              {{ $t("LeadingInternationalExpert") }}
            </div>

            <div
              class="catalog-side__tab"
              :class="{ active: filterTab == 'content_manager' }"
              @click="activeFilter('content_manager')"
            >
              {{ $t("ContentManager") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <popupsEmployee :detail="form" v-if="showModal" :close="close" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: true,
      showModal: false,
      filterTab: null,
      count: 0,
      limit: 10,
      page: 1,
      employeeList: [],
      form: {},
    };
  },
  computed: {
    params() {
      let params = {
        limit: this.limit,
        offset: this.offset,
      };
      this.filterTab
        ? (params.role = this.filterTab)
        : (params.role = [
            "admin",
            "staff_reviewer",
            "technical_expert",
            "international_expert",
            "leading_international_expert",
            "director",
            "content_manager",
          ]);
      return params;
    },
    offset() {
      return (this.page - 1) * this.limit;
    },
    title() {
      return this.filterTab == "admin"
        ? this.$t("Admin")
        : this.filterTab == "staff_reviewer"
        ? this.$t("EmployeePiu")
        : this.filterTab == "technical_expert"
        ? this.$t("TechnicalExpert")
        : this.filterTab == "international_expert"
        ? this.$t("InternationalExpert")
        : this.filterTab == "leading_international_expert"
        ? this.$t("LeadingInternationalExpert")
        : this.filterTab == "director"
        ? this.$t("Director")
        : this.filterTab == "content_manager"
        ? this.$t("ContentManager")
        : this.$t("All");
    },
  },
  watch: {
    filterTab() {
      this.page = 1;
      this.fetch();
    },
    page() {
      this.fetch();
    },
  },
  methods: {
    ...mapActions("adminEmployees", ["fetchList", "fetchDetail"]),
    addEmployee() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
      this.form = {};
    },
    activeFilter(arg) {
      this.filterTab = arg;
      this.$router.push({ query: { role: arg } });
    },
    async fetch() {
      // console.log("params", this.params);
      try {
        this.isLoading = true;
        let res = await this.fetchList({
          params: this.params,
        });
        // console.log(res);
        this.count = res && res.count;
        this.employeeList = res && res.results;
        this.isLoading = false;
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },
    paginate(arg) {
      this.page = arg;
    },
    async updateEmployee(id) {
      try {
        let res = await this.fetchDetail({ id });
        // console.log(res);
        this.form = res;
        this.showModal = true;
      } catch (err) {
        console.error(err);
      }
    },
    onChangeLimit(arg) {
      this.limit = arg;
      this.page = 1;
      this.fetch();
    },
    async removeUser(id) {
      try {
        const res = await this.$axios.patch(`/admin/user/update/${id}/`, {
          is_active: false,
        });
        this.fetch();
        if (res.status == 200 || res.status == 201) {
          this.$toast.success(this.$t("successDelete"));
        }
      } catch (e) {
        console.log(e);
      }
    },
    async recoverUser(id) {
      try {
        const res = await this.$axios.patch(`/admin/user/update/${id}/`, {
          is_active: true,
        });
        this.fetch();
        if (res.status == 200 || res.status == 201) {
          this.$toast.success(this.$t("successDelete"));
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.filterTab = this.$route.query && this.$route.query.role;
    await this.fetch();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.pages-head__tabs > div {
  cursor: pointer;
  transition: 0.3s;
}
</style>