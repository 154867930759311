<template>
  <div class="auth-block">
    <transition name="loading-fade">
      <appLoader v-if="isLoading" />
    </transition>
    <div class="auth-block__title">
      <h2>{{ $t("ForgotTitle") }}</h2>
    </div>
    <form @keyup.enter="getSmsCode" class="auth-block__form">
      <div class="form-group">
        <label for="phone"
          >{{ $t("User.Phone") }} <span class="red">*</span></label
        >
        <p style="color: red" v-if="warn">{{ $t("UserNotFound") }}</p>
        <div class="input-phone">
          <div class="input-phone__first">+998</div>
          <b-form-input
            id="phone"
            maxLength="9"
            v-model="form.phone"
          ></b-form-input>
        </div>
      </div>
      <!-- <p v-if="phone_exicted" style="color: red;">Номер телефона уже существует</p> -->
      <div class="form-btns">
        <b-button @click="getSmsCode" variant="primary">{{
          $t("FormGetCode")
        }}</b-button>
      </div>
      <span @click="$emit('goBack')" style="color: #2e7df6; cursor: pointer">
        &lt;- {{ $t("toBack") }}</span
      >
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      form: {
        phone: null,
      },
      sms_token: null,
      phone_exicted: false,
      warn: false,
    };
  },
  methods: {
    ...mapActions("auth", ["registrationStep1"]),
    async getSmsCode() {
      try {
        this.isLoading = true;
        let res = await this.registrationStep1({
          phone: "998" + this.form.phone,
          reset: true,
        });
        res && res.token
          ? this.$emit("getSmsToken", {
              token: res.token,
              phone: this.form.phone,
            })
          : (this.warn = true);
        this.isLoading = false;
      } catch (Err) {
        console.error(Err);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>